import { OnInit, EventEmitter } from '@angular/core';
import { DataGroupCollectionType, DataGroup } from 'vis';
import { Waterway } from '../Models/Waterway';
import { OperationTypeHelper } from '../Util/OperationTypeHelper';
var WaterwayTimeline = /** @class */ (function () {
    function WaterwayTimeline() {
        this.WaterwaySelected = new EventEmitter();
    }
    WaterwayTimeline.prototype.ngOnInit = function () {
    };
    Object.defineProperty(WaterwayTimeline.prototype, "Waterways", {
        get: function () {
            return this.waterways;
        },
        set: function (value) {
            this.waterways = value;
            this.CreateFromWaterways(this.waterways);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayTimeline.prototype, "SelectedWaterway", {
        get: function () {
            return this.selectedWaterway;
        },
        set: function (value) {
            var _this = this;
            if (typeof value !== 'undefined' && value != null) {
                this.selectedWaterway = value;
                var groups = this.groups;
                this.selectedGroup = groups.find(function (g) { return g.id === _this.selectedWaterway.Id; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayTimeline.prototype, "SelectedGroup", {
        get: function () {
            return this.selectedGroup;
        },
        set: function (value) {
            var _this = this;
            this.selectedGroup = value;
            if (this.selectedGroup) {
                this.SelectedWaterway = this.waterways.find(function (ww) { return ww.Id == _this.selectedGroup.id; });
                this.WaterwaySelected.emit(this.SelectedWaterway);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayTimeline.prototype, "ShowPriorityDate", {
        get: function () {
            return this.showPriorityDate;
        },
        set: function (value) {
            this.showPriorityDate = value;
            this.CreateFromWaterways(this.waterways);
        },
        enumerable: true,
        configurable: true
    });
    WaterwayTimeline.prototype.GetGroupForWaterway = function (waterway) {
        var a = this.groups;
        var groups = a;
        return groups.find(function (g) { return g.id === waterway.Id; });
    };
    WaterwayTimeline.prototype.CreateFromWaterways = function (waterways) {
        if (!waterways) {
            return;
        }
        var now = new Date();
        // create groups
        var groups = waterways.map(function (ww, i) { return ({
            id: ww.Id,
            content: "\n                <div class='name'>" + ww.Name + "</div>\n                <div class='code'>" + ww.Code + "</div>\n                ",
            order: i,
            className: "ww" + ww.Id + " shadow-row",
            subgroupOrder: 'subgroupOrder'
        }); });
        if (groups.length === 0) {
            groups.push({
                id: -1,
                content: "\n                    <div class='name'>Geen waterways met te behalen deadlines</div>\n                    <div class='code'>Leeg</div>",
                order: 0,
                className: "ww" + -1 + " shadow-row",
                subgroupOrder: 'subgroupOrder'
            });
        }
        var isFirst = function (ms, ww) {
            var firstMilestone = ww.GetFirstMilestoneMeetingCondition(function (ms) { return ms.GetLastDateForOperation() > now; });
            return firstMilestone != null && ms.Id === firstMilestone.Id;
        };
        // create items
        var milestones = waterways.map(function (ww) {
            return ww.Milestones.map(function (ms) { return ({
                first: isFirst(ms, ww),
                ms: ms
            }); });
        }).reduce(function (ms1, ms2) { return ms1.concat(ms2); }, []);
        var operations = milestones.map(function (ms) {
            return ms.ms.Operations.map(function (o) { return ({
                withFirst: ms.first,
                o: o
            }); });
        }).reduce(function (o1, o2) { return o1.concat(o2); }, []);
        var timelineItems = [];
        timelineItems = timelineItems.concat(milestones.map(function (ms) {
            var obj = OperationTypeHelper.GetObjectForOperationType(ms.ms.OperationTypeCode);
            var baseColor = ms.first ? obj.ColorBaseClass : 'grey';
            return {
                id: 'ms' + ms.ms.Id,
                group: ms.ms.Waterway.Id,
                start: ms.ms.Datetime,
                type: 'point',
                className: "milestone " + ms.ms.OperationTypeCode + "  " + baseColor + "-bb-transparent",
                subgroup: 'milestones',
                subgroupOrder: 2,
                content: undefined,
                linkedIds: ms.ms.Operations.map(function (o) { return "o" + o.Id; })
            };
        }));
        if (this.showPriorityDate) {
            timelineItems = timelineItems.concat(this.waterways.filter(function (ww) { return !!ww.FirstInvalidPointSetDate; }).map(function (ww) {
                return {
                    id: 'flvps' + ww.Id,
                    group: ww.Id,
                    start: ww.FirstInvalidPointSetDate,
                    type: 'point',
                    className: "first-last-valid-point-set square red-bb-transparent",
                    subgroup: 'pointsets',
                    subgroupOrder: 2,
                    content: undefined
                };
            }));
        }
        var oCodeToSvg = function (url, baseColor) {
            return "<svg class=\"operation-icon " + baseColor + "-fill\"><use href=\"" + url + "\" /></svg>";
        };
        timelineItems = timelineItems.
            concat(operations.map(function (o) {
            var obj = OperationTypeHelper.GetObjectForOperationType(o.o.Milestone.OperationTypeCode);
            var baseColor = o.withFirst ? obj.ColorBaseClass : 'grey';
            var linkedIds = null;
            if (o.o.Milestone != null) {
                linkedIds = ["ms" + o.o.Milestone.Id];
            }
            return {
                id: 'o' + o.o.Id,
                group: o.o.Milestone.Waterway.Id,
                start: o.o.StartDatetime,
                end: o.o.EndDatetime || o.o.StartDatetime,
                className: "operation " + o.o.Milestone.OperationTypeCode + " " + baseColor + "-bb-transparent",
                content: oCodeToSvg(obj.SvgUrl, baseColor),
                subgroup: 'operations',
                subgroupOrder: 1,
                linkedIds: linkedIds
            };
        })
            .filter(function (o) { return !!o.start; }));
        this.groups = groups;
        this.items = timelineItems;
    };
    return WaterwayTimeline;
}());
export { WaterwayTimeline };
