﻿import { PredictionResult } from "./PredictionResult";
import { PointSet } from "./PointSet";
import { IPointSetHolder } from "./IPointSetHolder";

export class PredictionResultTimestep implements IPointSetHolder {
    public PointSet: PointSet;
    public Type: string = "prediction";

    constructor(public Prediction: PredictionResult ) {
    }
}