﻿import { Component } from '@angular/core';

@Component({
    selector: 'no-authorization',
    templateUrl: 'NoAuthorizationPage.html',
    styleUrls: ['NoAuthorizationPage.scss']
})
export class NoAuthorizationPage {

    constructor() {
    }
}
