<div class="left">
    <div class="back">
        <svg class="back_icon" routerLink="/" routerLinkActive="active"><use [attr.xlink:href]="'assets/icons.svg#icn-arrow_back'" /></svg>
    </div>
    <div class="top">
        <div class="name">{{Waterway.Name}}</div>
        <div class="code">{{Waterway.Code}}</div>
    </div>
    <div class="list" id="style-1">
        <div *ngFor="let contractualArea of ContractualAreas"
             [ngClass]="{'contractual-area-listitem-selected': SelectedContractualArea === contractualArea,
                'contractual-area-listitem': SelectedContractualArea !== contractualArea}"
             (click)="OnSelectedContractualAreaChanged(contractualArea)">
            <div class="caCode">{{contractualArea.Code}}</div>
            <svg [ngClass]="{'btn-icn-selected': SelectedContractualArea === contractualArea,
                 'btn-icn': SelectedContractualArea !== contractualArea}"><use [attr.xlink:href]="arrow" /></svg>
            <div class="urgency-indicator">
                <span *ngFor="let urgency of [0,1,2,3,4,5]; let last = last" class='urgency-step' [ngClass]="{'active': contractualArea.Urgency <= urgency, 'separator-right': !last}" >
                </span>
            </div>
        </div>
    </div>
</div>
<div class="right">
    <div *ngIf="SelectedContractualArea" class="contractual-area-info">
        <div class="header">Voorspelde Baggerprioriteit</div>
        <div *ngIf="SelectedContractualArea.MaxDredgingDate"
             [ngClass]="{'ca_alert': SelectedContractualArea.MaxDredgingDate < GetCurrentDate()}">
            {{ SelectedContractualArea.MaxDredgingDate | date: 'dd-MM-yyyy' }}
        </div>
        <div *ngIf="!SelectedContractualArea.MaxDredgingDate">-</div>
        <br />
        <div class="header">Min. contractdiepte</div>
        <div *ngIf="SelectedContractualArea && currentRequirement">{{( currentRequirement.MinimumDepth | number : '.2-2')}} m</div>
        <div *ngIf="!(SelectedContractualArea && currentRequirement)">-</div>
        <div class="header">Max. contractdiepte</div>
        <div *ngIf="SelectedContractualArea && currentRequirement">{{(currentRequirement.MaximumDepth | number : '.2-2')}} m</div>
        <div *ngIf="!(SelectedContractualArea && currentRequirement)">-</div>
        <br />
        <div class="header" style="display:none;">Min conforme oppervlakte</div>
        <div *ngIf="SelectedContractualArea.MinConformArea" style="display:none;">{{ SelectedContractualArea.MinConformArea | number : '.0-0' }} m<sup>2</sup></div>
        <div *ngIf="!SelectedContractualArea.MinConformArea" style="display:none;">-</div>
        <div class="header" style="display:none;">conforme oppervlakte</div>
        <div *ngIf="SelectedContractualAreaPointLevelSet?.ConformArea && SelectedContractualArea.MinConformArea" style="display:none;"
             [ngClass]="{'ca_alert': SelectedContractualAreaPointLevelSet.ConformArea < SelectedContractualArea.MinConformArea}">
            {{ SelectedContractualAreaPointLevelSet.ConformArea | number : '.0-0'}} m<sup>2</sup>
        </div>
        <div *ngIf="!(SelectedContractualAreaPointLevelSet?.ConformArea && SelectedContractualArea.MinConformArea)" style="display:none;">-</div>
        <br />
        <div class="header">% Min. conforme oppervlakte</div>
        <div *ngIf="SelectedContractualArea.MinConformArea && SelectedContractualArea.TotalArea">{{SelectedContractualArea.MinConformArea / SelectedContractualArea.TotalArea | percent : '.0-0'}}</div>
        <div *ngIf="!(SelectedContractualArea.MinConformArea && SelectedContractualArea.TotalArea)">-</div>
        <div class="header">% Conforme oppervlakte</div>
        <div *ngIf="SelectedContractualAreaPointLevelSet?.ConformArea && SelectedContractualArea.MinConformArea"
             [ngClass]="{'ca_alert': SelectedContractualAreaPointLevelSet.ConformArea < SelectedContractualArea.MinConformArea}">
            {{SelectedContractualAreaPointLevelSet.ConformArea / SelectedContractualArea.TotalArea | percent : '.1-1'}}
        </div>
        <div *ngIf="!(SelectedContractualAreaPointLevelSet?.ConformArea && SelectedContractualArea.MinConformArea)">-</div>
        <br />
        <div class="header">Maximale onderschrijding min. diepte</div>
        <div *ngIf="SelectedContractualArea.MinLevelMargin">{{SelectedContractualArea.MinLevelMargin | number : '.2-2'}} m</div>
        <div *ngIf="!SelectedContractualArea.MinLevelMargin">-</div>
        <div class="header">Onderschrijding min. diepte</div>
        <div *ngIf="SelectedPointSetType != 'prediction' && SelectedContractualAreaPointLevelSet?.BottomLevelExceedance"
             [ngClass]="{'ca_alert': SelectedContractualAreaPointLevelSet.BottomLevelExceedance > SelectedContractualArea.MinLevelMargin}">
            {{ SelectedContractualAreaPointLevelSet.BottomLevelExceedance | number : '.2-2'}} m
        </div>
        <div *ngIf="!(SelectedPointSetType != 'prediction' && SelectedContractualAreaPointLevelSet?.BottomLevelExceedance)">-</div>
        <br />
        <div class="header">Volume boven min. diepte</div>
        <div *ngIf="SelectedContractualAreaPointLevelSet?.VolumeAboveBottomLevel">{{ SelectedContractualAreaPointLevelSet.VolumeAboveBottomLevel | number : '.0-0' }} m<sup>3</sup></div>
        <div *ngIf="!SelectedContractualAreaPointLevelSet?.VolumeAboveBottomLevel">-</div>
        <div class="header" style="display:none;">volume boven max. diepte</div>
        <div style="display:none;">-</div>
        <div class="header">Oppervlakte boven min. diepte</div>
        <div *ngIf="SelectedContractualAreaPointLevelSet?.AreaAboveBottomLevel">{{SelectedContractualAreaPointLevelSet.AreaAboveBottomLevel | number : '.0-0'}} m<sup>2</sup></div>
        <div *ngIf="!SelectedContractualAreaPointLevelSet?.AreaAboveBottomLevel">-</div>
        <div class="header" style="display:none;">Oppervlakte boven max. diepte</div>
        <div style="display:none;">-</div>
        <div class="header" style="display:none;">Gemiddelde laagdikte boven min. diepte</div>
        <div style="display:none;">-</div>
        <div class="header" style="display:none;">Gemiddelde laagdikte boven max. diepte</div>
        <div style="display:none;">-</div>

        <br />
        <div *ngIf="currentRequirement">
          <div class="header">Ingang contract-eis</div>
          {{currentRequirmentStartDate}}
          <div class="header">Einde contract-eis</div>
          {{currentRequirmentEndDate}}
          <div class="header">VTW-nr</div>
          {{currentRequirement.VtwNr}}
        </div>
    </div>
</div>
