import { Component } from '@angular/core';
import {PermissionManager} from './Services/PermissionManager';

@Component({
  selector: 'app',
  styleUrls: ['app.component.scss'],
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  constructor(private permissionManager: PermissionManager) {}
}
