import { EventEmitter } from '@angular/core';
import { SurveyService } from '../Services/SurveyService';
import { of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
var SurveyView = /** @class */ (function () {
    function SurveyView(surveyService) {
        this.surveyService = surveyService;
        this.onDelete = new EventEmitter();
        this.surveyObservable = of(null);
    }
    Object.defineProperty(SurveyView.prototype, "SurveyId", {
        set: function (value) {
            var _this = this;
            if (this.surveyId !== value) {
                this.surveyObservable = this.surveyObservable.pipe(flatMap(function () { return _this.surveyService.GetSurveyById(value); }));
                this.surveyObservable.subscribe(function (s) { return _this.survey = s; }, function (err) { return console.error(err); });
                this.surveyId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    SurveyView.prototype.DeleteSurvey = function () {
        var _this = this;
        this.isDeleting = true;
        var surveyId = this.survey.Id;
        this.surveyService.DeleteSurvey(surveyId)
            .subscribe(function () {
            _this.onDelete.emit(surveyId);
            _this.survey = null;
        }, function (err) { return console.error('SurveyDelete: Delete failed', err); }, function () { return _this.isDeleting = false; });
    };
    return SurveyView;
}());
export { SurveyView };
