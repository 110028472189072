import { Injectable } from '@angular/core';
import { SoilInvestigationCampaign } from '../Models/SoilInvestigationCampaign';
import { ISoilInvestigationCampaign} from '../DTO/ISoilInvestigationCampaign';


@Injectable()
export class SoilInvestigationCampaignService {

    public ConvertJsonToSoilInvestigationCampaign(iSoilInvestigationCampaign: ISoilInvestigationCampaign[])
    : SoilInvestigationCampaign[] {
        return iSoilInvestigationCampaign.map(isic => {
            return new SoilInvestigationCampaign(isic.id,
                isic.contractor);
        });
    }

    public ConvertSoilIInvestigationCampaignToISoilIInvestigationCampaign(soilInvestigationCampaigns: SoilInvestigationCampaign[])
    : ISoilInvestigationCampaign[] {
        return soilInvestigationCampaigns.map(sic => {
            const isic: ISoilInvestigationCampaign = {
                id: sic.Id, contractor: sic.Contractor};
            return isic; });
    }
}
