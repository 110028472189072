﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Waterway } from '../Models/Waterway';
import { Milestone } from '../Models/Milestone';
import * as moment from 'moment';
import { OperationTypeHelper } from '../Util/OperationTypeHelper';
import { SelectedFilterService } from '../Services/SelectedFilterService';

@Component({
    selector: 'operation-type-filter',
    templateUrl: 'OperationTypeFilter.html',
    styleUrls: ['OperationTypeFilter.scss']
})
export class OperationTypeFilter{
    private waterways: Waterway[];
    private showPredict: boolean;
    private selectedFilter: string;
    
    @Output()
    waterwaysFiltered = new EventEmitter<Waterway[]>();

    public OperationTypeSvgUrlPairs : { OperationType: string, SvgUrl: string }[]

    constructor() {
        this.OperationTypeSvgUrlPairs = [{
            OperationType: 'all',
            SvgUrl: 'assets/icons.svg#icn-filter_all'
        }].concat(OperationTypeHelper.GetOperationTypeSvgUrlPairs());
    }

    get Waterways(): Waterway[] {
        return this.waterways;
    }
    @Input()
    set Waterways(value: Waterway[]) {
        this.waterways = value;
        this.SelectFilterWaterways(this.selectedFilter);
    }

    @Output() SelectedFilterChange = new EventEmitter();

    get SelectedFilter(): string {
        return this.selectedFilter;
    }

    @Input()
    set SelectedFilter(value: string) {
        this.selectedFilter = value;
        this.SelectFilterWaterways(this.selectedFilter);
        this.SelectedFilterChange.emit(this.SelectedFilter);
    }

    get ShowPredict(): boolean {
        return this.showPredict;
    }
    @Input()
    set ShowPredict(value: boolean) {
        if (this.showPredict != value)
        {
            if (value)
            {
                this.OperationTypeSvgUrlPairs.splice(1, 0,
                    {
                        OperationType: 'predict-dredge',
                        SvgUrl: 'assets/icons.svg#icn-predictDredge'
                    });
            }
            else
            {
                this.OperationTypeSvgUrlPairs.splice(1, 1);
            }
        }
        this.showPredict = value;
    }

    private FilterWaterways(filter: string): Waterway[]
    {
        let waterwaysCopy = this.waterways.map(ww => ww.Clone());
        let now = moment().toDate();

        let milestonePredicate = (ms: Milestone) => ms.GetLastDateForOperation() > now;

        let compare = (ww1: Waterway, ww2: Waterway) => {
            let ms1 = ww1.GetFirstMilestoneMeetingCondition(milestonePredicate);
            let ms2 = ww2.GetFirstMilestoneMeetingCondition(milestonePredicate);
            if (ms1 != null && ms2 != null) {
                return ms1.Datetime.valueOf() - ms2.Datetime.valueOf();
            }
            else {
                let result = (ms1 != null ? -1 : 0) - (ms2 != null ? -1 : 0);
                if (result === 0)
                    result = ww1.Code.localeCompare(ww2.Code);
                return result;
            }
        };
        let waterwaysUnfiltered = waterwaysCopy.map(ww => { ww.Milestones = ww.Milestones.filter(ms => ms.OperationTypeCode === filter); return ww; }).sort(compare);
        let waterwaysFiltered = waterwaysUnfiltered.filter(ww => ww.Milestones.length > 0);
        return waterwaysFiltered;
    }

    private FilterWaterwaysOnPredictionDate(): Waterway[] {
        let waterwaysCopy = this.waterways.map(ww => ww.Clone());
        let now = moment().toDate();
        let compare = (ww1: Waterway, ww2: Waterway) => {
            if (ww1.FirstInvalidPointSetDate && ww2.FirstInvalidPointSetDate) {
                return ww1.FirstInvalidPointSetDate.valueOf() - ww2.FirstInvalidPointSetDate.valueOf();
            }
            else {
                let result = (ww1.FirstInvalidPointSetDate != null ? -1 : 0) - (ww2.FirstInvalidPointSetDate != null ? -1 : 0);
                if (result === 0)
                    return ww2.Milestones.length - ww1.Milestones.length;
                return result;
            }
        };
        let waterwaysUnfiltered = waterwaysCopy.map(ww => { ww.Milestones = ww.Milestones.filter(ms => ms.OperationTypeCode === "baggeren"); return ww; }).sort(compare);
        let waterwaysFiltered = waterwaysUnfiltered.filter(ww => ww.FirstInvalidPointSetDate);
        return waterwaysFiltered;
    }

    private SelectFilterWaterways(filter: string) {
        if (!filter || !this.waterways)
            this.waterwaysFiltered.emit([]);
        else if (filter === 'all') {
            this.waterwaysFiltered.emit(this.waterways);
        }
        else if (filter === 'predict-dredge')
        {
            this.waterwaysFiltered.emit(this.FilterWaterwaysOnPredictionDate())
        }
        else {
            this.waterwaysFiltered.emit(this.FilterWaterways(filter));
        }
    }

    public ContainsOperationType(operationType: string): boolean {
        if (this.selectedFilter === "all")
            return true;
        else if (this.selectedFilter === "predict-dredge")
            return operationType === "baggeren";
        else
            return this.selectedFilter === operationType;
    }

  navigateToMilieuPage() {
    window.open('https://sharepoint-deme-group.com/WNLZ/_layouts/15/WopiFrame.aspx?sourcedoc=/WNLZ/Gedeelde%20%20documenten/A.3%20Beschrijving%20kwaliteit%20Areaal/A%203.1%20-%20Toestand%20areaal/A3.1.1%20bodemonderzoeken/Overzichtstabel%20KBC/5264-WNZ-TAB-001D-BLJO%20ACTUEEL%20OVERZICHT%20KBC%20-%20do%20no%20(re)move.xlsx&action=default');
  }
}
