﻿import { Component } from '@angular/core';
import { AdalService } from '../Services/AdalService';

@Component({
    selector: 'login',
    templateUrl: 'Login.html',
    styleUrls: ['Login.scss']
})
export class Login {

    constructor(private adalService: AdalService) { }

    ngOnInit() {
    }

    login() {
        this.adalService.login();
    }

    logout() {
        this.adalService.logout();
    }

    public get isLoggedIn() {
        return this.adalService.isAuthenticated;
    }
}
