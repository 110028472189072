import { Injectable } from '@angular/core';

import { ISurveyCampaign } from '../DTO/ISurveyCampaign';
import { SurveyCampaign } from '../Models/SurveyCampaign';
import { IHangfireJob } from '../DTO/IHangfireJob';
import { HangfireJob } from '../Models/HangfireJob';

@Injectable()
export class SurveyCampaignService {

    public ConvertJsonToSurveyCampaigns(iSurveyCampaigns: ISurveyCampaign[]):
    SurveyCampaign[] {
            return iSurveyCampaigns.map(isc => {
                return new SurveyCampaign(
                    isc.id,
                    isc.contractor,
                    isc.surveyIds,
                    isc.hangfireJobs.map(hj => this.ConvertJsonToHangfireJob(hj))
                );
        });
    }

    public ConvertSurveyCampaignsToISurveyCampaigns(surveyCampaigns: SurveyCampaign[]): ISurveyCampaign[] {
        return surveyCampaigns.map( sc => {
            const isc: ISurveyCampaign = {
                id: sc.Id,
                contractor: sc.Contractor,
                surveyIds: sc.SurveyIds.map(x => x),
                hangfireJobs: sc.HangfireJobs.map(hj => this.ConvertHangfireJobToIHangfireJob(hj))
            };
            return isc;
        });
    }

    public ConvertJsonToHangfireJob(hangfireJob: IHangfireJob): HangfireJob {
        return new HangfireJob(hangfireJob.hangfireJobId, hangfireJob.state);
    }

    public ConvertHangfireJobToIHangfireJob(hangfireJob: HangfireJob): IHangfireJob {
        return {
            hangfireJobId: hangfireJob.HangfireJobId,
            state: hangfireJob.State
        };
    }
}
