/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./NoAuthorizationPage.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./NoAuthorizationPage";
var styles_NoAuthorizationPage = [i0.styles];
var RenderType_NoAuthorizationPage = i1.ɵcrt({ encapsulation: 0, styles: styles_NoAuthorizationPage, data: {} });
export { RenderType_NoAuthorizationPage as RenderType_NoAuthorizationPage };
export function View_NoAuthorizationPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Onvoldoende rechten om pagina te bezoeken"])), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["Ga terug naar Dashboard"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, ""); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_NoAuthorizationPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "no-authorization", [], null, null, null, View_NoAuthorizationPage_0, RenderType_NoAuthorizationPage)), i1.ɵdid(1, 49152, null, 0, i4.NoAuthorizationPage, [], null, null)], null, null); }
var NoAuthorizationPageNgFactory = i1.ɵccf("no-authorization", i4.NoAuthorizationPage, View_NoAuthorizationPage_Host_0, {}, {}, []);
export { NoAuthorizationPageNgFactory as NoAuthorizationPageNgFactory };
