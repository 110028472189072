<div class="bar">
  <svg *ngFor="let pair of OperationTypeSvgUrlPairs"
       [class.selected]="SelectedFilter === pair.OperationType"
       class="btn-icn"
       (click)="SelectedFilter = pair.OperationType">
    <use [attr.xlink:href]="pair.SvgUrl"/>
  </svg>
  <span title="Milieu Kwaliteit Bodem">
    <svg class="btn-icn" (click)="navigateToMilieuPage()">
      <use href='assets/icons.svg#tableIcon'></use>
    </svg>
  </span>
</div>
