import { HangfireJob } from './HangfireJob';

export class SurveyCampaign {
    
    constructor(public Id: number,
                public Contractor: string,
                public SurveyIds: number[],
                public HangfireJobs: HangfireJob[]) {
    }

    public get RunningHangfireJobs(): HangfireJob[]
    {
        return this.HangfireJobs.filter(hj => hj.IsRunning);
    }
}