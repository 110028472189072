﻿import {TimeScale} from '../Models/TimeScale';
import * as moment from 'moment';
import {Duration, duration, Moment} from 'moment';

export class TimeScaleHelper {
  static TimeScaleToDuration(timeScale: TimeScale): Duration {
    let result: Duration = null;
    switch (timeScale) {
      case TimeScale.Week:
        result = duration(1, 'week');
        break;
      case TimeScale.Month:
        result = duration(1, 'month');
        break;
      case TimeScale.Quarter:
        result = duration(1, 'quarter');
        break;
      case TimeScale.Year:
        result = duration(1, 'year');
        break;
      case TimeScale.FiveYear:
        result = duration(5, 'year');
        break;
      default:
        throw new Error(`Unknown TimeScale ${timeScale}`);
    }
    return result;
  }

  static TimeScaleAroundMoment(scaleDuration: Duration, center: Moment = moment(), offsetFactor: number = 0.15): { start: Moment, end: Moment } {
    if (!isFinite(offsetFactor)) {
      offsetFactor = 0.5;
    }
    const prestage = duration(scaleDuration.asDays() * offsetFactor, 'days');
    const start = center.clone().subtract(prestage);
    const end = start.clone().add(scaleDuration);

    return {start: start, end: end};
  }
}
