import { FileUploader } from 'ng2-file-upload/ng2-file-upload';
import * as moment from 'moment';
import { ImportService } from '../Services/ImportService';
import { SurveyCampaign } from '../Models/SurveyCampaign';
import { AdalService } from '../Services/AdalService';
import { from } from 'rxjs';
import { catchError, finalize, flatMap, map, tap } from 'rxjs/operators';
var SurveyImport = /** @class */ (function () {
    function SurveyImport(importService, authenticationService) {
        var _this = this;
        this.importService = importService;
        this.authenticationService = authenticationService;
        this.isImporting = false;
        this.uploader = new FileUploader({
            isHTML5: true,
            url: '/api/fileupload',
            queueLimit: 1,
            autoUpload: false,
            headers: [{ name: 'Authorization', value: "Bearer " + this.authenticationService.accessToken }]
        });
        this.uploader.onAfterAddingFile = function (fileItem) {
            if (fileItem.file.name.endsWith('.pts')) {
                fileItem.upload();
                _this.fileDatetime = _this.GetDateFromFileName(fileItem.file.name).toDate();
                _this.surveyFile = fileItem;
                _this.surveyFileObservable = _this.CreateObservableFromFileItem(fileItem).pipe(map(function (resp) {
                    return JSON.parse(resp.response);
                }));
            }
            else {
                _this.uploader.removeFromQueue(fileItem);
            }
        };
    }
    Object.defineProperty(SurveyImport.prototype, "SurveyCampaign", {
        set: function (value) {
            this.surveyCampaign = value;
        },
        enumerable: true,
        configurable: true
    });
    SurveyImport.prototype.GetDateFromFileName = function (filename) {
        var parts = filename.split('-');
        for (var _i = 0, parts_1 = parts; _i < parts_1.length; _i++) {
            var p = parts_1[_i];
            var result = moment(p, 'YYMMDD');
            if (result.isValid()) {
                return result;
            }
        }
        return null;
    };
    SurveyImport.prototype.CreateObservableFromFileItem = function (fi) {
        var prom = new Promise(function (resolve, reject) {
            fi.onSuccess = function (response, status, headers) {
                var result = {
                    response: response,
                    status: status,
                    headers: headers
                };
                resolve(result);
            };
            fi.onError = function (response, status, headers) {
                var result = {
                    response: response,
                    status: status,
                    headers: headers
                };
                reject(result);
            };
        });
        return from(prom);
    };
    Object.defineProperty(SurveyImport.prototype, "datetime", {
        get: function () {
            return !!this.userDatetime ? this.userDatetime : (this.fileDatetime ? this.fileDatetime : null);
        },
        enumerable: true,
        configurable: true
    });
    SurveyImport.prototype.Import = function () {
        var _this = this;
        this.isImporting = true;
        var surveyCampaign = this.surveyCampaign;
        this.surveyFileObservable.pipe(flatMap(function (fu) {
            var projectSurveyImport = {
                timestamp: _this.datetime.toISOString(),
                surveyCampaignId: surveyCampaign.Id,
                survey: fu,
                metadata: null
            };
            return _this.importService.ImportProjectSurvey(projectSurveyImport);
        }), tap(function (hangFireJob) {
            surveyCampaign.HangfireJobs.push(hangFireJob);
        }), catchError(function (err) {
            console.error("err", err);
            throw err;
        }), finalize(function () { return _this.isImporting = false; }))
            .subscribe();
    };
    SurveyImport.prototype.CanImport = function () {
        return !!this.surveyFile && !this.isImporting && this.surveyFile.isUploaded;
    };
    return SurveyImport;
}());
export { SurveyImport };
