﻿import { ConfigService } from './ConfigService';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import User = adal.User;


@Injectable()
export class AdalService {

  private context: MsAdalAngular6Service;
  private currentUserSubject: ReplaySubject<User> = new ReplaySubject<User>(1);

  constructor(private configService: ConfigService) {
    this.context = new MsAdalAngular6Service(configService.AdalConfig);
    this.currentUserSubject.next(this.userInfo);
  }


  login() {
    this.context.login();
  }

  logout() {
    this.context.logout();
    this.currentUserSubject.next(null);
  }

  handleCallback() {
    this.context.handleCallback();
    this.currentUserSubject.next(this.userInfo);
  }

  public get userInfoObservable(): Observable<User> {
    return this.currentUserSubject;
  }

  public get userInfo() {
    return this.context.userInfo;
  }

  public get accessToken() {
    return this.context.getToken(this.configService.AdalConfig.clientId);
  }

  public get isAuthenticated() {
    return this.userInfo && this.accessToken;
  }
}
