<h2>Werkzaamheid</h2>
<div class="wrapper">
    <ng-container *ngxPermissionsOnly="['editOperation']; else elseBlock; then thenBlock">main</ng-container>
    <ng-template #elseBlock>
        Start: <span>{{ Operation.StartDatetime | date:'dd-MM-yy'}}</span>
        Eind: <span>{{ Operation.EndDatetime | date:'dd-MM-yy'}}</span>
    </ng-template>
    <ng-template #thenBlock>
        Start:<p-calendar [(ngModel)]="Operation.StartDatetime" dateFormat="dd-mm-yy"></p-calendar>
        Eind:<p-calendar [ngModel]="Operation.EndDatetime" (ngModelChange)="OnEndDateChange($event)" dateFormat="dd-mm-yy"></p-calendar>
    </ng-template>
</div>
