<h1>Planning</h1>
<div class="operation-type-selector">
  <div>
        <span *ngFor="let pair of OperationTypeSvgUrlPairs"
              class="operation-type-icn"
              [ngClass]="SelectedOperationType === pair.OperationType ? pair.ColorBaseClass+'-bb-transparent' : 'lightGrey'+'-bb-transparent'">
            <svg
              [ngClass]="SelectedOperationType === pair.OperationType ? pair.ColorBaseClass+'-fill' : 'lightGrey-fill'">
                <use [attr.xlink:href]="pair.SvgUrl"/>
            </svg>
        </span>
  </div>
  <span class="operation-type-name">{{SelectedOperationType}}</span>
</div>
<div class="errors" *ngIf="errors.length > 0">
  <div class="error" *ngFor="let error of errors">
    {{error.message}}
  </div>
</div>
<milestone-view *ngIf="SelectedMilestone != null" [Milestone]="SelectedMilestone"></milestone-view>
<ng-container *ngIf="SelectedOperation != null">
  <operation-view [Operation]="SelectedOperation"></operation-view>
  <ng-container *ngFor="let sc of selectedOperation.SurveyCampaigns">
    <div class="survey-view" *ngIf="sc.Id != null">
      <h2>Peiling</h2>
      <div class="in-process" *ngIf="sc.RunningHangfireJobs.length > 0">
        Import wordt verwerkt
      </div>
      <ng-container *ngxPermissionsOnly="['importSurvey']">
        <survey-import *ngIf="sc.RunningHangfireJobs.length === 0 && sc.SurveyIds.length === 0"
                       [SurveyCampaign]="sc">
        </survey-import>
      </ng-container>
      <ng-container *ngxPermissionsOnly="['deleteSurvey']; else elseBlock; then thenBlock"></ng-container>
      <ng-template #elseBlock>
        <survey-view *ngFor="let sId of sc.SurveyIds"
                     [SurveyId]="sId"
                     [AllowDelete]="false">
        </survey-view>
      </ng-template>
      <ng-template #thenBlock>
        <survey-view *ngFor="let sId of sc.SurveyIds"
                     [SurveyId]="sId"
                     (onDelete)="sc.SurveyIds.splice(sc.SurveyIds.indexOf($event), 1)"
                     [AllowDelete]="true">
        </survey-view>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<div class="external-item" *ngxPermissionsOnly="['makeExternal']">
  <input type="checkbox" [(ngModel)]="ExternalItem"/> <label>Extern</label>
</div>
<div class="wrapper">
  <button class="btn" (click)="Save()" [disabled]="isSaving">Opslaan</button>
</div>
