/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./OperationTypeTimeline.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./OBPTimeline.ngfactory";
import * as i3 from "./OBPTimeline";
import * as i4 from "../Services/SelectedTimeService";
import * as i5 from "ngx-logger";
import * as i6 from "./OperationTypeTimeline";
var styles_OperationTypeTimeline = [i0.styles];
var RenderType_OperationTypeTimeline = i1.ɵcrt({ encapsulation: 0, styles: styles_OperationTypeTimeline, data: {} });
export { RenderType_OperationTypeTimeline as RenderType_OperationTypeTimeline };
export function View_OperationTypeTimeline_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "obp-timeline", [], null, [[null, "SelectedItemChange"], [null, "ItemDoubleClick"], [null, "GroupDoubleClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("SelectedItemChange" === en)) {
        var pd_0 = (_co.OnItemSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("ItemDoubleClick" === en)) {
        var pd_1 = (_co.OnItemDoubleClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("GroupDoubleClick" === en)) {
        var pd_2 = (_co.OnGroupDoubleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_OBPTimeline_0, i2.RenderType_OBPTimeline)), i1.ɵdid(1, 1163264, null, 1, i3.OBPTimeline, [i1.ElementRef, i1.Renderer2, i4.SelectedTimeService, i5.NGXLogger], { Items: [0, "Items"], Groups: [1, "Groups"] }, { SelectedItemChange: "SelectedItemChange", ItemDoubleClick: "ItemDoubleClick", GroupDoubleClick: "GroupDoubleClick" }), i1.ɵqud(603979776, 1, { contentChildren: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co.groups; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_OperationTypeTimeline_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "operation-type-timeline", [], null, null, null, View_OperationTypeTimeline_0, RenderType_OperationTypeTimeline)), i1.ɵdid(1, 114688, null, 0, i6.OperationTypeTimeline, [i5.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OperationTypeTimelineNgFactory = i1.ɵccf("operation-type-timeline", i6.OperationTypeTimeline, View_OperationTypeTimeline_Host_0, { Milestones: "Milestones", Operations: "Operations", PredictionTimesteps: "PredictionTimesteps", Surveys: "Surveys" }, { SelectedTimestepChange: "SelectedTimestepChange", SelectedSurveyChange: "SelectedSurveyChange", SelectedEmptyPointSetChange: "SelectedEmptyPointSetChange", MilestoneDoubleClick: "MilestoneDoubleClick", OperationDoubleClick: "OperationDoubleClick", OperationTypeDoubleClick: "OperationTypeDoubleClick" }, []);
export { OperationTypeTimelineNgFactory as OperationTypeTimelineNgFactory };
