import { EventEmitter, Injectable, Output } from '@angular/core';
import { Moment } from "moment";
import { NGXLogger } from "ngx-logger";

@Injectable({
  providedIn: 'root'
})
export class SelectedTimeService {

  constructor(private logger: NGXLogger) { }

  @Output()
  public SelectedTimeChanged = new EventEmitter<Moment>();

  public TimeChanged(newTime: Moment){
    this.logger.debug(`time changed to ${newTime}`);
    this.SelectedTimeChanged.emit(newTime);
  }

}
