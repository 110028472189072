var SurveyCampaign = /** @class */ (function () {
    function SurveyCampaign(Id, Contractor, SurveyIds, HangfireJobs) {
        this.Id = Id;
        this.Contractor = Contractor;
        this.SurveyIds = SurveyIds;
        this.HangfireJobs = HangfireJobs;
    }
    Object.defineProperty(SurveyCampaign.prototype, "RunningHangfireJobs", {
        get: function () {
            return this.HangfireJobs.filter(function (hj) { return hj.IsRunning; });
        },
        enumerable: true,
        configurable: true
    });
    return SurveyCampaign;
}());
export { SurveyCampaign };
