<div class="mid">
  <waterway-info [@wiAnimation]="state" [Waterway]="Waterway" [ContractualAreas]="contractualAreas"
                 [(SelectedContractualArea)]="SelectedContractualArea"
                 [SelectedContractualAreaPointLevelSet]="SelectedContractualAreaPointLevelSet"
                 [SelectedPointSetType]="SelectedPointSetType"></waterway-info>
  <map-view
    [@mvAnimation]="state"
    (@mvAnimation.done)="UpdateMap()"
    [SelectedWaterway]="Waterway"
    (fullscreen)="ToggleFullscreen()"
    [SelectedContractualArea]="SelectedContractualArea"
    [SelectedDate]="selectedPointSetHolder?.PointSet?.Datetime"
    [RasterFactor]="selectedPointSetHolder?.PointSet?.Factor">
  </map-view>
</div>
<div class="bottom">
  <operation-type-timeline
    [@obptAnimation]="state"
    [Milestones]="selectedMilestones"
    [Operations]="selectedOperations"
    [PredictionTimesteps]="timesteps"
    [Surveys]="surveys"
    (SelectedSurveyChange)="OnSurveySelected($event)"
    (SelectedTimestepChange)="OnTimestepSelected($event)"
    (SelectedEmptyPointSetChange)="OnEmptyPointSetSelected($event)"
    (MilestoneDoubleClick)="OnMilestoneDoubleClick($event)"
    (OperationDoubleClick)="OnOperationDoubleClick($event)"
    (OperationTypeDoubleClick)="OnOperationTypeDoubleClick($event)">
  </operation-type-timeline>
</div>
