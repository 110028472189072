﻿
class OperationTypeHelperClass {
    private operationTypeSvgMap = {
        'baggeren': {
            SvgUrl: 'assets/icons.svg#icn-dredge',
            ColorBaseClass: 'orange'
        },
        'peilen': {
            SvgUrl: 'assets/icons.svg#icn-survey',
            ColorBaseClass: 'blue'
        },
        'grondonderzoek': {
            SvgUrl: 'assets/icons.svg#icn-investigation',
            ColorBaseClass: 'green'
        },
        'storten': {
            SvgUrl: 'assets/icons.svg#icn-dump',
            ColorBaseClass: 'purple'
        },
        'bergen': {
            SvgUrl: 'assets/icons.svg#icn-salvage',
            ColorBaseClass: 'yellow'
        }
    };
    constructor() {
    }

    GetObjectForOperationType(operationType: string): { SvgUrl: string, ColorBaseClass: string } {
        return this.operationTypeSvgMap[operationType];
    }

    GetOperationTypeSvgUrlPairs(): { OperationType: string, SvgUrl: string, ColorBaseClass: string }[] {
        return Object.keys(this.operationTypeSvgMap).map(k => ({ OperationType: k, SvgUrl: this.operationTypeSvgMap[k].SvgUrl, ColorBaseClass: this.operationTypeSvgMap[k].ColorBaseClass }));
    }
}

export const OperationTypeHelper = new OperationTypeHelperClass();
