﻿import { Injectable } from '@angular/core';
import { OperationService } from './OperationService';
import { IMilestoneAndOperation } from '../DTO/IMilestoneAndOperation';
import { IMilestoneFull } from '../DTO/IMilestoneFull';
import { IMilestone } from '../DTO/IMilestone';
import { Waterway } from '../Models/Waterway';
import { Milestone } from '../Models/Milestone';
import { Operation } from '../Models/Operation';
import { HttpAuthService } from './HttpAuthService';
import * as moment from 'moment';
import { ModelChange, ModelChangeType } from '../Util/ModelChange';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class MilestoneService {
  private modelChangeSubject: Subject<ModelChange<Milestone>>;
  private baseUrl = '/api/milestone/';

  constructor(private http: HttpAuthService, private operationService: OperationService, private logger: NGXLogger) {
    this.modelChangeSubject = new Subject<ModelChange<Milestone>>();
    this.logger.debug('started MileStoneService');
  }

  public CreateNew(waterway: Waterway, operationTypeCode: string): Milestone {
    this.logger.debug(`Creating new Milestone on ww ${waterway} and typecode: ${operationTypeCode}`);
    const milestone = new Milestone(null, null, waterway, operationTypeCode, []);
    milestone.Operations = [];
    return milestone;
  }

  // half solution: this is a workaround for observable and datastore not being present throughout the application
  public GetChangeObservable(): Observable<ModelChange<Milestone>> {
    return this.modelChangeSubject.asObservable();
  }

  public NotifyChange(change: ModelChange<Milestone>) {
    this.modelChangeSubject.next(change);
  }

  Save(milestone: Milestone): Observable<Milestone> {
    if (milestone.Id != null) {
      return this.SaveExisting(milestone);
    } else {
      return this.SaveNew(milestone);
    }
  }

  SaveNew(milestone: Milestone): Observable<Milestone> {
    this.logger.debug(`Saveing new milestone: ${milestone}`);
    const milestoneDto: IMilestone = this.ConvertMilestoneToIMilestone(milestone);
    return this.http.post(this.baseUrl, milestoneDto)
      .pipe(
        map(data => {
          const jsonData: IMilestone = data.json();
          milestone.Id = jsonData.id;

          this.NotifyChange({ChangeType: ModelChangeType.Create, Value: milestone});

          return milestone;
        })
      );
  }

  SaveExisting(milestone: Milestone): Observable<Milestone> {
    this.logger.debug(`Saving existing milestone: ${milestone}`);
    const milestoneDto: IMilestone = this.ConvertMilestoneToIMilestone(milestone);
    return this.http.put(`${this.baseUrl}${milestone.Id}`, milestoneDto).pipe(
      map(data => {
        this.NotifyChange({ChangeType: ModelChangeType.Update, Value: milestone});

        return milestone;
      }));
  }

  Delete(milestone: Milestone): Observable<Milestone> {
    this.logger.debug(`Deleting milestone: ${milestone}`);
    return this.http.delete(`${this.baseUrl}${milestone.Id}`).pipe(
      map(data => {
        this.NotifyChange({ChangeType: ModelChangeType.Delete, Value: milestone});

        return milestone;
      }));
  }

  public ConvertJsonToMileStone(jsonMilestone: IMilestone, waterway: Waterway): Milestone {
    const milestone = new Milestone(jsonMilestone.id, new Date(jsonMilestone.datetime), waterway, jsonMilestone.operationTypeCode, jsonMilestone.labelCodes);

    return milestone;
  }

  public ConvertJsonFullToMileStone(jsonMilestone: IMilestoneFull, waterway: Waterway): Milestone {
    const milestone = this.ConvertJsonToMileStone(jsonMilestone, waterway);
    milestone.Operations = jsonMilestone.operations.map(jsonOperation => this.operationService.ConvertJsonToOperation(jsonOperation, milestone, waterway));

    return milestone;
  }

  public ConvertMilestoneToIMilestone(milestone: Milestone): IMilestone {
    return {
      id: milestone.Id,
      datetime: milestone.Datetime && moment(milestone.Datetime).format(),
      operationTypeCode: milestone.OperationTypeCode,
      waterwayId: milestone.Waterway && milestone.Waterway.Id,
      labelCodes: milestone.labelCodes
    };
  }

  public ConvertMilestoneToIMilestoneWithOperation(milestone: Milestone, operation: Operation, waterway: Waterway): IMilestoneAndOperation {
    return {
      milestone: milestone && this.ConvertMilestoneToIMilestone(milestone),
      operation: operation && this.operationService.ConvertOperationToIOperation(operation),
      waterwayId: waterway.Id
    };
  }
}
