/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./PlanningView.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./MilestoneView.ngfactory";
import * as i4 from "./MilestoneView";
import * as i5 from "./SurveyImport.ngfactory";
import * as i6 from "../Services/ImportService";
import * as i7 from "../Services/HttpAuthService";
import * as i8 from "../Services/SurveyCampaignService";
import * as i9 from "ngx-logger";
import * as i10 from "./SurveyImport";
import * as i11 from "../Services/AdalService";
import * as i12 from "./SurveyView.ngfactory";
import * as i13 from "../Services/PointSetService";
import * as i14 from "../Services/SurveyService";
import * as i15 from "ngx-permissions";
import * as i16 from "./SurveyView";
import * as i17 from "./OperationView.ngfactory";
import * as i18 from "./OperationView";
import * as i19 from "@angular/forms";
import * as i20 from "./PlanningView";
import * as i21 from "../Services/OperationService";
import * as i22 from "../Services/MilestoneService";
var styles_PlanningView = [i0.styles];
var RenderType_PlanningView = i1.ɵcrt({ encapsulation: 0, styles: styles_PlanningView, data: {} });
export { RenderType_PlanningView as RenderType_PlanningView };
function View_PlanningView_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "operation-type-icn"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, ":svg:svg", [], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "operation-type-icn"; var currVal_1 = ((_co.SelectedOperationType === _v.context.$implicit.OperationType) ? (_v.context.$implicit.ColorBaseClass + "-bb-transparent") : ("lightGrey" + "-bb-transparent")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.SelectedOperationType === _v.context.$implicit.OperationType) ? (_v.context.$implicit.ColorBaseClass + "-fill") : "lightGrey-fill"); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.SvgUrl; _ck(_v, 4, 0, currVal_3); }); }
function View_PlanningView_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.message; _ck(_v, 1, 0, currVal_0); }); }
function View_PlanningView_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "errors"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PlanningView_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "milestone-view", [], null, null, null, i3.View_MilestoneView_0, i3.RenderType_MilestoneView)), i1.ɵdid(1, 114688, null, 0, i4.MilestoneView, [], { Milestone: [0, "Milestone"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SelectedMilestone; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PlanningView_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "in-process"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Import wordt verwerkt "]))], null, null); }
function View_PlanningView_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "survey-import", [], null, null, null, i5.View_SurveyImport_0, i5.RenderType_SurveyImport)), i1.ɵprd(512, null, i6.ImportService, i6.ImportService, [i7.HttpAuthService, i8.SurveyCampaignService, i9.NGXLogger]), i1.ɵdid(2, 49152, null, 0, i10.SurveyImport, [i6.ImportService, i11.AdalService], { SurveyCampaign: [0, "SurveyCampaign"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PlanningView_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit.RunningHangfireJobs.length === 0) && (_v.parent.parent.context.$implicit.SurveyIds.length === 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PlanningView_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_PlanningView_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "survey-view", [], null, null, null, i12.View_SurveyView_0, i12.RenderType_SurveyView)), i1.ɵprd(512, null, i13.PointSetService, i13.PointSetService, []), i1.ɵprd(512, null, i14.SurveyService, i14.SurveyService, [i7.HttpAuthService, i13.PointSetService, i15.NgxRolesService, i9.NGXLogger]), i1.ɵdid(3, 49152, null, 0, i16.SurveyView, [i14.SurveyService], { AllowDelete: [0, "AllowDelete"], SurveyId: [1, "SurveyId"] }, null)], function (_ck, _v) { var currVal_0 = false; var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_PlanningView_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_13)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.SurveyIds; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PlanningView_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "survey-view", [], null, [[null, "onDelete"]], function (_v, en, $event) { var ad = true; if (("onDelete" === en)) {
        var pd_0 = (_v.parent.parent.parent.context.$implicit.SurveyIds.splice(_v.parent.parent.parent.context.$implicit.SurveyIds.indexOf($event), 1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_SurveyView_0, i12.RenderType_SurveyView)), i1.ɵprd(512, null, i13.PointSetService, i13.PointSetService, []), i1.ɵprd(512, null, i14.SurveyService, i14.SurveyService, [i7.HttpAuthService, i13.PointSetService, i15.NgxRolesService, i9.NGXLogger]), i1.ɵdid(3, 49152, null, 0, i16.SurveyView, [i14.SurveyService], { AllowDelete: [0, "AllowDelete"], SurveyId: [1, "SurveyId"] }, { onDelete: "onDelete" })], function (_ck, _v) { var currVal_0 = true; var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_PlanningView_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_15)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.SurveyIds; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PlanningView_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "survey-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Peiling"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PlanningView_9)), i1.ɵdid(6, 737280, null, 0, i15.NgxPermissionsDirective, [i15.NgxPermissionsService, i15.NgxPermissionsConfigurationService, i15.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PlanningView_11)), i1.ɵdid(9, 737280, null, 0, i15.NgxPermissionsDirective, [i15.NgxPermissionsService, i15.NgxPermissionsConfigurationService, i15.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"], ngxPermissionsOnlyThen: [1, "ngxPermissionsOnlyThen"], ngxPermissionsOnlyElse: [2, "ngxPermissionsOnlyElse"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵand(0, [["elseBlock", 2]], null, 0, null, View_PlanningView_12)), (_l()(), i1.ɵand(0, [["thenBlock", 2]], null, 0, null, View_PlanningView_14))], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.RunningHangfireJobs.length > 0); _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, "importSurvey"); _ck(_v, 6, 0, currVal_1); var currVal_2 = _ck(_v, 10, 0, "deleteSurvey"); var currVal_3 = i1.ɵnov(_v, 12); var currVal_4 = i1.ɵnov(_v, 11); _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4); }, null); }
function View_PlanningView_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.Id != null); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PlanningView_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "operation-view", [], null, null, null, i17.View_OperationView_0, i17.RenderType_OperationView)), i1.ɵdid(2, 114688, null, 0, i18.OperationView, [], { Operation: [0, "Operation"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_6)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SelectedOperation; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.selectedOperation.SurveyCampaigns; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PlanningView_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "external-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.ExternalItem = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i19.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i19.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i19.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i19.NgModel, [[8, null], [8, null], [8, null], [6, i19.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i19.NgControl, null, [i19.NgModel]), i1.ɵdid(6, 16384, null, 0, i19.NgControlStatus, [[4, i19.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Extern"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.ExternalItem; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PlanningView_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Planning"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "operation-type-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "operation-type-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningView_5)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PlanningView_16)), i1.ɵdid(15, 737280, null, 0, i15.NgxPermissionsDirective, [i15.NgxPermissionsService, i15.NgxPermissionsConfigurationService, i15.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(16, 1), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Opslaan"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.OperationTypeSvgUrlPairs; _ck(_v, 5, 0, currVal_0); var currVal_2 = (_co.errors.length > 0); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.SelectedMilestone != null); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.SelectedOperation != null); _ck(_v, 13, 0, currVal_4); var currVal_5 = _ck(_v, 16, 0, "makeExternal"); _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.SelectedOperationType; _ck(_v, 7, 0, currVal_1); var currVal_6 = _co.isSaving; _ck(_v, 18, 0, currVal_6); }); }
export function View_PlanningView_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "planning-view", [], null, null, null, View_PlanningView_0, RenderType_PlanningView)), i1.ɵdid(1, 114688, null, 0, i20.PlanningView, [i21.OperationService, i22.MilestoneService, i9.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlanningViewNgFactory = i1.ɵccf("planning-view", i20.PlanningView, View_PlanningView_Host_0, { Waterway: "Waterway", SelectedOperationType: "SelectedOperationType", SelectedMilestone: "SelectedMilestone", SelectedOperation: "SelectedOperation", ExternalItem: "ExternalItem" }, { SavingSuccessful: "SavingSuccessful" }, []);
export { PlanningViewNgFactory as PlanningViewNgFactory };
