﻿import { Component } from '@angular/core';
import { Waterway } from '../Models/Waterway';
import { Milestone } from '../Models/Milestone';
import { Operation } from '../Models/Operation';
import { NGXLogger } from 'ngx-logger';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';

export class PlanningModalContext {
  constructor(public waterway: Waterway, public operationType: string, public milestone: Milestone, public operation: Operation) {
  }
}

@Component({
  selector: 'planning-modal',
  templateUrl: 'PlanningModal.html',
  styleUrls: ['PlanningModal.scss'],
  providers: [DialogService]
})
export class PlanningModal {

  constructor(private logger: NGXLogger, private ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    logger.debug('creating PlanningModal');
  }

  public SaveAndClose(e: any) {
    this.ref.close();
  }
}
