﻿import { Injectable } from '@angular/core';
import { AdalService } from './AdalService';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { tap } from 'rxjs/internal/operators/tap';
import { NGXLogger } from 'ngx-logger';


const READ_ROLE = 'A.R-dotProWNZ_R';
const READWRITE_ROLE = 'A.R-dotProWNZ_RW';
const writePermissions = ['editMilestone', 'editOperation', 'importSurvey', 'deleteSurvey', 'addOperation', 'makeExternal'];

@Injectable()
export class PermissionManager {
  public ROLES: Map<string, string[]> = new Map<string, string[]>();

  constructor(private adalService: AdalService, private permissionsService: NgxPermissionsService, private roleService: NgxRolesService, private logger: NGXLogger) {

    this.ROLES.set('READ', []);
    this.ROLES.set('WRITE', writePermissions);
    this.logger.debug('Setting up permissions');

    this.adalService.userInfoObservable.pipe(
      tap(user => {
        this.permissionsService.flushPermissions();
        const roles: string[] = user && user.profile.roles;

        if (roles && roles.includes(READWRITE_ROLE)) {
          this.permissionsService.loadPermissions(writePermissions);
          this.AddRole('WRITE');
          this.AddRole('READ');
          this.logger.debug('user has read+write rights');
        }

        if (roles && roles.includes(READ_ROLE)) {
          this.AddRole('READ');
          this.logger.debug('user has read rights');
        }
      })).subscribe();
  }

  private AddRole(role: string) {
    this.roleService.addRole(role, this.ROLES.get(role));
  }
}
