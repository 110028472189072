﻿
class MapBarHelperClass {
    private mapBarSvgMap = {
        'herladen': 'assets/icons.svg#icn-recenter',
        'meten': 'assets/icons.svg#icn-measure',
        'hydro': 'assets/icons.svg#icn-hydromap',
        'pijltje': 'assets/icons.svg#icn-fullscreen',
        'hokje': 'assets/icons.svg#icn-fullscreen',
        'zwemmen': 'assets/icons.svg#icn-fullscreen'
    };
    constructor() {
    }

    GetSvgUrlForOperationType(mapBar: string): string {
        return this.mapBarSvgMap[mapBar];
    }

    GetMapBarSvgUrlPairs(): { MapBar: string, SvgUrl: string }[] {
        return Object.keys(this.mapBarSvgMap).map(k => ({ MapBar: k, SvgUrl: this.mapBarSvgMap[k] }));
    }
}

export const MapBarHelper = new MapBarHelperClass();
