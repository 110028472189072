import { ConfigService } from './ConfigService';
import { ReplaySubject } from 'rxjs';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
var AdalService = /** @class */ (function () {
    function AdalService(configService) {
        this.configService = configService;
        this.currentUserSubject = new ReplaySubject(1);
        this.context = new MsAdalAngular6Service(configService.AdalConfig);
        this.currentUserSubject.next(this.userInfo);
    }
    AdalService.prototype.login = function () {
        this.context.login();
    };
    AdalService.prototype.logout = function () {
        this.context.logout();
        this.currentUserSubject.next(null);
    };
    AdalService.prototype.handleCallback = function () {
        this.context.handleCallback();
        this.currentUserSubject.next(this.userInfo);
    };
    Object.defineProperty(AdalService.prototype, "userInfoObservable", {
        get: function () {
            return this.currentUserSubject;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdalService.prototype, "userInfo", {
        get: function () {
            return this.context.userInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdalService.prototype, "accessToken", {
        get: function () {
            return this.context.getToken(this.configService.AdalConfig.clientId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdalService.prototype, "isAuthenticated", {
        get: function () {
            return this.userInfo && this.accessToken;
        },
        enumerable: true,
        configurable: true
    });
    return AdalService;
}());
export { AdalService };
