﻿import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { DummyComponent } from '../Components/DummyComponent';
import { NoAuthorizationPage } from '../Components/NoAuthorizationPage';
import { Dashboard } from '../Components/Dashboard';
import { WaterwayDetail } from '../Components/WaterwayDetail';
import { SurveyImport } from '../Components/SurveyImport';
import { WaterwayDetailResolve } from './WaterwayDetailResolve';
import { WaterwayService } from '../Services/WaterwayService';
import { MilestoneService } from '../Services/MilestoneService';
import { OperationService } from '../Services/OperationService';
import { SoilInvestigationCampaignService } from '../Services/SoilInvestigationCampaignService';
import { DredgingOperationService } from '../Services/DredgingOperationService';
import { SurveyCampaignService } from '../Services/SurveyCampaignService';
import { AuthenticationGuard } from '../Services/AuthenticationGuard';
import { OAuthCallbackHandler } from '../Services/OAuthCallbackHandler';
import { Login } from '../Components/Login';
import { AutomaticLoginGuard } from '../Services/AutomaticLoginGuard';


const appRoutes: Routes = [
    { path: 'login/aad/callback', component: DummyComponent, canActivate: [OAuthCallbackHandler] },
    { path: 'login', component: Login, canActivate: [AutomaticLoginGuard] },
    { path: 'no-authorization', component: NoAuthorizationPage, canActivate: [AuthenticationGuard] },
    {
        path: 'waterway/:id',
        component: WaterwayDetail,
        resolve: {
            waterway: WaterwayDetailResolve
        },
        canActivate: [AuthenticationGuard, NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['READ', 'WRITE'],
                redirectTo: 'no-authorization'
            }
        }
    },
    {
        path: 'import',
        component: SurveyImport,
        canActivate: [AuthenticationGuard, NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['WRITE'],
                redirectTo: 'no-authorization'
            }
        }
    },
    {
        path: '',
        component: Dashboard,
        canActivate: [AuthenticationGuard, NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['READ', 'WRITE'],
                redirectTo: 'no-authorization'
            }
        }
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        WaterwayDetailResolve,
        WaterwayService,
        MilestoneService,
        OperationService,
        SurveyCampaignService,
        DredgingOperationService,
        SoilInvestigationCampaignService,
        AuthenticationGuard,
        OAuthCallbackHandler,
        AutomaticLoginGuard
    ]
})
export class AppRoutingModule { }
