<h2>Deadline</h2>
<div class="wrapper">
    <ng-container *ngxPermissionsOnly="['editMilestone']; else elseBlock; then thenBlock">main</ng-container>
    <ng-template #elseBlock>
        <span>{{Milestone.Datetime | date:'dd-MM-yy'}}</span>
    </ng-template>
    <ng-template #thenBlock>
        <p-calendar [(ngModel)]="Milestone.Datetime" dateFormat="dd-mm-yy"></p-calendar>
    </ng-template>
</div>
