/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./PlanningModal.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./PlanningView.ngfactory";
import * as i3 from "./PlanningView";
import * as i4 from "../Services/OperationService";
import * as i5 from "../Services/MilestoneService";
import * as i6 from "ngx-logger";
import * as i7 from "primeng/components/dynamicdialog/dialogservice";
import * as i8 from "./PlanningModal";
import * as i9 from "primeng/components/dynamicdialog/dynamicdialog-ref";
import * as i10 from "primeng/components/dynamicdialog/dynamicdialog-config";
var styles_PlanningModal = [i0.styles];
var RenderType_PlanningModal = i1.ɵcrt({ encapsulation: 0, styles: styles_PlanningModal, data: {} });
export { RenderType_PlanningModal as RenderType_PlanningModal };
export function View_PlanningModal_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "planning-view", [], null, [[null, "SavingSuccessful"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("SavingSuccessful" === en)) {
        var pd_0 = (_co.SaveAndClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PlanningView_0, i2.RenderType_PlanningView)), i1.ɵdid(1, 114688, null, 0, i3.PlanningView, [i4.OperationService, i5.MilestoneService, i6.NGXLogger], { Waterway: [0, "Waterway"], SelectedOperationType: [1, "SelectedOperationType"], SelectedMilestone: [2, "SelectedMilestone"], SelectedOperation: [3, "SelectedOperation"] }, { SavingSuccessful: "SavingSuccessful" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.data.waterway; var currVal_1 = _co.config.data.operationType; var currVal_2 = _co.config.data.milestone; var currVal_3 = _co.config.data.operation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_PlanningModal_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "planning-modal", [], null, null, null, View_PlanningModal_0, RenderType_PlanningModal)), i1.ɵprd(4608, null, i7.DialogService, i7.DialogService, [i1.ComponentFactoryResolver, i1.ApplicationRef, i1.Injector]), i1.ɵdid(2, 49152, null, 0, i8.PlanningModal, [i6.NGXLogger, i9.DynamicDialogRef, i10.DynamicDialogConfig], null, null)], null, null); }
var PlanningModalNgFactory = i1.ɵccf("planning-modal", i8.PlanningModal, View_PlanningModal_Host_0, {}, {}, []);
export { PlanningModalNgFactory as PlanningModalNgFactory };
