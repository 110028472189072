/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./WaterwayTimeline.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./OBPTimelineGroup";
import * as i5 from "./OBPTimeline.ngfactory";
import * as i6 from "./OBPTimeline";
import * as i7 from "../Services/SelectedTimeService";
import * as i8 from "ngx-logger";
import * as i9 from "./WaterwayTimeline";
var styles_WaterwayTimeline = [i0.styles];
var RenderType_WaterwayTimeline = i1.ɵcrt({ encapsulation: 0, styles: styles_WaterwayTimeline, data: {} });
export { RenderType_WaterwayTimeline as RenderType_WaterwayTimeline };
function View_WaterwayTimeline_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["OBPTimelineGroupTarget", "timeline"], ["class", "go-to-detail"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[3, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), i1.ɵdid(5, 16384, [[1, 4]], 0, i4.OBPTimelineGroup, [i1.ElementRef], { ObpTimelineGroup: [0, "ObpTimelineGroup"], OBPTimelineGroupTarget: [1, "OBPTimelineGroupTarget"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [["href", "assets/icons.svg#icn-arrow_next"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "/waterway/", _v.context.$implicit.Id, ""); _ck(_v, 1, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.GetGroupForWaterway(_v.context.$implicit); var currVal_5 = "timeline"; _ck(_v, 5, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_WaterwayTimeline_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "obp-timeline", [], null, [[null, "SelectedGroupChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("SelectedGroupChange" === en)) {
        var pd_0 = ((_co.SelectedGroup = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_OBPTimeline_0, i5.RenderType_OBPTimeline)), i1.ɵdid(1, 1163264, null, 1, i6.OBPTimeline, [i1.ElementRef, i1.Renderer2, i7.SelectedTimeService, i8.NGXLogger], { verticalScroll: [0, "verticalScroll"], Items: [1, "Items"], Groups: [2, "Groups"], SelectedGroup: [3, "SelectedGroup"] }, { SelectedGroupChange: "SelectedGroupChange" }), i1.ɵqud(603979776, 1, { contentChildren: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaterwayTimeline_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.items; var currVal_2 = _co.groups; var currVal_3 = _co.SelectedGroup; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.Waterways; _ck(_v, 4, 0, currVal_4); }, null); }
export function View_WaterwayTimeline_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "waterway-timeline", [], null, null, null, View_WaterwayTimeline_0, RenderType_WaterwayTimeline)), i1.ɵdid(1, 114688, null, 0, i9.WaterwayTimeline, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WaterwayTimelineNgFactory = i1.ɵccf("waterway-timeline", i9.WaterwayTimeline, View_WaterwayTimeline_Host_0, { Waterways: "Waterways", SelectedWaterway: "SelectedWaterway", ShowPriorityDate: "ShowPriorityDate" }, { WaterwaySelected: "WaterwaySelected" }, []);
export { WaterwayTimelineNgFactory as WaterwayTimelineNgFactory };
