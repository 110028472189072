import { EventEmitter } from '@angular/core';
import { Waterway } from '../Models/Waterway';
import { ContractualArea } from '../Models/ContractualArea';
import { ContractualAreaPointLevelSet } from '../Models/ContractualAreaPointLevelSet';
import { SelectedTimeService } from "../Services/SelectedTimeService";
import { NGXLogger } from "ngx-logger";
import * as moment from "moment";
import { Globals } from "../Util/Globals";
var WaterwayInfo = /** @class */ (function () {
    function WaterwayInfo(selectedTimeService, logger) {
        var _this = this;
        this.selectedTimeService = selectedTimeService;
        this.logger = logger;
        this.arrow = "assets/icons.svg#icn-arrow_up";
        this.currentTime = moment();
        this.currentRequirmentStartDate = '';
        this.currentRequirmentEndDate = '';
        this.SelectedContractualAreaChange = new EventEmitter();
        this.selectedTimeService.SelectedTimeChanged.subscribe(function (c) { return _this.UpdateRequirement(c); });
    }
    Object.defineProperty(WaterwayInfo.prototype, "Waterway", {
        get: function () {
            return this.waterway;
        },
        set: function (value) {
            this.waterway = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayInfo.prototype, "ContractualAreas", {
        get: function () {
            return this.contractualAreas;
        },
        set: function (value) {
            this.contractualAreas = value && value.sort(function (a, b) { return a.Urgency - b.Urgency; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayInfo.prototype, "SelectedContractualArea", {
        get: function () {
            return this.selectedContractualArea;
        },
        set: function (value) {
            this.selectedContractualArea = value;
            this.UpdateRequirement(this.currentTime);
        },
        enumerable: true,
        configurable: true
    });
    WaterwayInfo.prototype.UpdateRequirement = function (newDate) {
        this.currentTime = newDate;
        if (this.SelectedContractualArea) {
            this.currentRequirement = this.SelectedContractualArea.GetValidRequirement(newDate);
            if (this.currentRequirement) {
                this.currentRequirmentStartDate = this.GetValidStartDateString(this.currentRequirement);
                this.currentRequirmentEndDate = this.GetValidEndDateString(this.currentRequirement);
            }
        }
    };
    Object.defineProperty(WaterwayInfo.prototype, "SelectedPointSetType", {
        get: function () {
            return this.selectedPointSetType;
        },
        set: function (value) {
            this.selectedPointSetType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayInfo.prototype, "SelectedContractualAreaPointLevelSet", {
        get: function () {
            return !(this.SelectedPointSetType == 'prediction' && !this.selectedContractualArea.UseAnalysis) ? this.selectedContractualAreaPointLevelSet : null;
        },
        set: function (value) {
            this.selectedContractualAreaPointLevelSet = value;
        },
        enumerable: true,
        configurable: true
    });
    WaterwayInfo.prototype.OnSelectedContractualAreaChanged = function (value) {
        this.SelectedContractualArea = value;
        this.SelectedContractualAreaChange.emit(this.SelectedContractualArea);
    };
    WaterwayInfo.prototype.GetCurrentDate = function () {
        return new Date();
    };
    WaterwayInfo.prototype.GetValidStartDateString = function (req) {
        if (!req.ValidRange.start.isSame(Globals.minTime, 'D')) {
            return req.ValidRange.start.format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    };
    WaterwayInfo.prototype.GetValidEndDateString = function (req) {
        if (!req.ValidRange.end.isSame(Globals.maxTime, 'D')) {
            return req.ValidRange.end.format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    };
    WaterwayInfo.prototype.Home = function () {
    };
    return WaterwayInfo;
}());
export { WaterwayInfo };
