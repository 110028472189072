﻿import { Injectable } from '@angular/core';

@Injectable()
export class SelectedFilterService {

    private selectedFilter: string;

    constructor() {
        this.selectedFilter = '';
        }

    get SelectedFilter(): string {
        return this.selectedFilter;
    }

    set SelectedFilter(value: string) {
        this.selectedFilter = value;
    }

    printStatus() {
        console.debug(`Current Filter Stack: ${this.selectedFilter}`);
    }

}
