/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./SurveyView.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../Services/PointSetService";
import * as i4 from "../Services/SurveyService";
import * as i5 from "../Services/HttpAuthService";
import * as i6 from "ngx-permissions";
import * as i7 from "ngx-logger";
import * as i8 from "./SurveyView";
var styles_SurveyView = [i0.styles];
var RenderType_SurveyView = i1.ɵcrt({ encapsulation: 0, styles: styles_SurveyView, data: {} });
export { RenderType_SurveyView as RenderType_SurveyView };
function View_SurveyView_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.DeleteSurvey(_co.survey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Verwijder"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDeleting; _ck(_v, 0, 0, currVal_0); }); }
function View_SurveyView_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SurveyView_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.AllowDelete; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.survey.PointSet.Datetime, "dd-MM-yyyy")); _ck(_v, 1, 0, currVal_0); }); }
export function View_SurveyView_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SurveyView_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.survey; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SurveyView_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "survey-view", [], null, null, null, View_SurveyView_0, RenderType_SurveyView)), i1.ɵprd(512, null, i3.PointSetService, i3.PointSetService, []), i1.ɵprd(512, null, i4.SurveyService, i4.SurveyService, [i5.HttpAuthService, i3.PointSetService, i6.NgxRolesService, i7.NGXLogger]), i1.ɵdid(3, 49152, null, 0, i8.SurveyView, [i4.SurveyService], null, null)], null, null); }
var SurveyViewNgFactory = i1.ɵccf("survey-view", i8.SurveyView, View_SurveyView_Host_0, { AllowDelete: "AllowDelete", SurveyId: "SurveyId" }, { onDelete: "onDelete" }, []);
export { SurveyViewNgFactory as SurveyViewNgFactory };
