import { OnInit } from '@angular/core';
import { Milestone } from '../Models/Milestone';
var MilestoneView = /** @class */ (function () {
    function MilestoneView() {
    }
    MilestoneView.prototype.ngOnInit = function () {
    };
    Object.defineProperty(MilestoneView.prototype, "Milestone", {
        get: function () {
            return this.milestone;
        },
        set: function (value) {
            this.milestone = value;
        },
        enumerable: true,
        configurable: true
    });
    return MilestoneView;
}());
export { MilestoneView };
