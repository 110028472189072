﻿import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Waterway } from '../Models/Waterway';
import { SurveyResult } from '../Models/SurveyResult';
import { ISurveyResult } from '../DTO/ISurveyResult';
import { URLSearchParams } from '@angular/http';
import { PointSetService } from './PointSetService';
import { HttpAuthService } from './HttpAuthService';
import { NgxRolesService } from 'ngx-permissions';
import { flatMap, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class SurveyService {
  constructor(private http: HttpAuthService, private pointSetService: PointSetService, private roleService: NgxRolesService, private logger: NGXLogger) {
    logger.debug('started SurveyService');
  }

  private url = '/api/survey';

  GetSurveysByWaterway(waterway: Waterway): Observable<SurveyResult[]> {
    this.logger.debug('getting surveyByWaterWay wwid: ' + waterway);
    return from(this.roleService.hasOnlyRoles('WRITE')).pipe(
      flatMap(hasWriteRole => {
        const params = new URLSearchParams();
        params.append('waterwayId', waterway.Id.toString());
        let url = this.url;
        if (!hasWriteRole) {
          url = `${url}/external`;
        }
        return this.http.get(url, {search: params});
      }),
      map(data => {
        const jsonData: ISurveyResult[] = data.json();

        const surveys: SurveyResult[] = jsonData.map(jsonSurveyResult => this.ConvertJsonToSurveyResult(jsonSurveyResult));

        return surveys;
      }))
      ;
  }

  GetSurveyById(id: number): Observable<SurveyResult> {
    this.logger.debug(`getting surveyById: ${id}`);
    return from(this.roleService.hasOnlyRoles('WRITE'))
      .pipe(
        flatMap(hasWriteRole => {
          let url = `${this.url}/${id}`;
          if (!hasWriteRole) {
            this.logger.debug('no rights');
            url = `${url}/external`;
          }
          return this.http.get(url);
        }),
        map(data => {
          const jsonData: ISurveyResult = data.json();
          return this.ConvertJsonToSurveyResult(jsonData);
        })
      );
  }

  DeleteSurvey(id: number): Observable<any> {
    this.logger.debug(`deleting survey with id: ${id}`);
    return this.http.delete(`${this.url}/${id}`);
  }

  private ConvertJsonToSurveyResult(jsonSurvey: ISurveyResult): SurveyResult {
    const survey = new SurveyResult(
      jsonSurvey.id
    );

    survey.PointSet = this.pointSetService.ConvertJsonToPointSet(jsonSurvey.pointSet, 1);

    return survey;
  }

}
