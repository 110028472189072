/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./OBPTimeline.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./OBPTimeline";
import * as i3 from "../Services/SelectedTimeService";
import * as i4 from "ngx-logger";
var styles_OBPTimeline = [i0.styles];
var RenderType_OBPTimeline = i1.ɵcrt({ encapsulation: 0, styles: styles_OBPTimeline, data: {} });
export { RenderType_OBPTimeline as RenderType_OBPTimeline };
export function View_OBPTimeline_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "inner-timeline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "size-overlay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.DecreaseTimeScaleOrReset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "btn-icn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [["href", "assets/icons.svg#icn-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.GetTimeScaleName(); _ck(_v, 4, 0, currVal_0); }); }
export function View_OBPTimeline_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "obp-timeline", [], null, null, null, View_OBPTimeline_0, RenderType_OBPTimeline)), i1.ɵdid(1, 1163264, null, 1, i2.OBPTimeline, [i1.ElementRef, i1.Renderer2, i3.SelectedTimeService, i4.NGXLogger], null, null), i1.ɵqud(603979776, 1, { contentChildren: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OBPTimelineNgFactory = i1.ɵccf("obp-timeline", i2.OBPTimeline, View_OBPTimeline_Host_0, { verticalScroll: "verticalScroll", TimeScale: "TimeScale", Items: "Items", Groups: "Groups", ContinuousRedrawing: "ContinuousRedrawing", SelectedGroup: "SelectedGroup", SelectedItem: "SelectedItem" }, { SelectedGroupChange: "SelectedGroupChange", SelectedItemChange: "SelectedItemChange", ItemDoubleClick: "ItemDoubleClick", GroupDoubleClick: "GroupDoubleClick" }, []);
export { OBPTimelineNgFactory as OBPTimelineNgFactory };
