import * as moment from 'moment';
import { duration } from 'moment';
var ContractualArea = /** @class */ (function () {
    function ContractualArea(Id, Waterway, Code, UseAnalysis, MinSoilClass, SoilTypeSummary, MinConformArea, TotalArea, MinLevelMargin) {
        this.Id = Id;
        this.Waterway = Waterway;
        this.Code = Code;
        this.UseAnalysis = UseAnalysis;
        this.MinSoilClass = MinSoilClass;
        this.SoilTypeSummary = SoilTypeSummary;
        this.MinConformArea = MinConformArea;
        this.TotalArea = TotalArea;
        this.MinLevelMargin = MinLevelMargin;
    }
    Object.defineProperty(ContractualArea.prototype, "Urgency", {
        ///Urgency: range for 0-* where 0 is the most urgent en the urgency decreases when the number decrease
        get: function () {
            var now = moment();
            var maxDredingDate = this.MaxDredgingDate && moment(this.MaxDredgingDate);
            if (maxDredingDate != null) {
                var diff = duration(maxDredingDate.diff(now));
                var diffMonths = diff.asMonths();
                diffMonths = Math.max(diffMonths, 0);
                return Math.round(diffMonths);
            }
            else
                return Infinity;
        },
        enumerable: true,
        configurable: true
    });
    ContractualArea.prototype.GetValidRequirement = function (date) {
        var candidate = this.Requirements
            .filter(function (r) { return r.ValidRange.contains(date); });
        if (candidate.length > 1)
            // Find shortest period which contains date, diff gives duration between start and end.
            return candidate.reduce(function (acc, val) { return val.ValidRange.diff() < acc.ValidRange.diff() ? val : acc; });
        else if (candidate.length == 1)
            return candidate[0];
        else
            return null;
    };
    Object.defineProperty(ContractualArea.prototype, "MaxDredgingDate", {
        get: function () {
            return this.FirstInvalidPointSet && this.FirstInvalidPointSet.PointLevelSet.Datetime;
        },
        enumerable: true,
        configurable: true
    });
    return ContractualArea;
}());
export { ContractualArea };
