﻿import { Operation } from './Operation';
import { Waterway } from './Waterway';

export class Milestone {
  public Operations: Operation[];

  constructor(
    public Id: number,
    public Datetime: Date,
    public Waterway: Waterway,
    public OperationTypeCode: string,
    public labelCodes: string[]) {
  }

  GetLastDateForOperation(): Date {
    let dateDesc: Date[] = this.Operations.map(o => o.EndDatetime).filter(d => d != null).sort((d1, d2) => d2.valueOf() - d1.valueOf());
    return dateDesc.length > 0 ? dateDesc[0] : null;
  }

  public toString(): string {
    return `Milestone: ${this.Id} ${this.Datetime}`;
  }
}
