﻿import { ContractualArea } from './ContractualArea';
import { PointSet } from './PointSet';

export class ContractualAreaPointLevelSet
{
    constructor(public ContractualArea: ContractualArea, public PointLevelSet: PointSet,
        public VolumeAboveBottomLevel: number, public AreaAboveBottomLevel: number, public BottomLevelExceedance: number) {

    }

    public get ConformArea(): number {
        return this.ContractualArea.TotalArea - this.AreaAboveBottomLevel;
    }

}