﻿import { Milestone } from './Milestone';
import { Operation } from './Operation';

export class Waterway {
    public Milestones: Milestone[] = [];
    public Operations: Operation[] = [];
    constructor(public Id: number, public Code: string, public Name: string, public FirstInvalidPointSetDate: Date) {
    }

    public GetFirstMilestoneAfterDate(date: Date, operationType: string = undefined): Milestone {
        let predicate = (milestone) => {
            if (milestone.OperationTypeCode === operationType || operationType === undefined)
                return milestone.Datetime > date;
            return false;
        };
        return this.GetFirstMilestoneMeetingCondition(predicate);
    }

    public GetFirstMilestoneMeetingCondition(predicate: (Milestone) => boolean)
    {
        return this.Milestones
            .map(ms => ms)//clone to prevent sort messing up the current array
            .sort((m1, m2) => m1.Datetime.valueOf() - m2.Datetime.valueOf())
            .find(predicate);
    }

    public Clone(): Waterway {
        const waterway = new Waterway(this.Id, this.Code, this.Name, this.FirstInvalidPointSetDate);
        waterway.Milestones = this.Milestones.map(ms => ms);
        waterway.Operations = this.Operations.map(op => op);
        return waterway;
    }

    public toString(): string {
      return `Waterway "${this.Code}:${this.Name}"`;
  }
}
