﻿import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { publishReplay, refCount } from "rxjs/operators";
import { ConfigDto } from "../DTO/IConfigDto";

@Injectable()
export class ConfigService {
  private readonly baseUrl: string;
  private readonly config$: Observable<ConfigDto>;
  private cachedConfig: ConfigDto;

  constructor(private http: HttpClient) {
    this.baseUrl = ConfigService.toUrlBase(document.getElementsByTagName('base')[0].href);
    this.config$ = this.CreateConfig$();
  }

  public get AdalConfig(): any {
    const configDto = this.getCachedConfig();
    return {
      tenant: configDto.tenantId,
      clientId: configDto.clientId,
      redirectUri: window.location.origin + '/login/aad/callback',
      postLogoutRedirectUri: window.location.origin + '/',
      navigateToLoginRequestUrl: false
    };
  }

  public static toUrlBase(url: string): string {
    // Get base address, without any trailing slashes or paths
    const base = /^(.*?:\/\/)?([\w\-]*\.?)+(\.\w{2,})?(:?\d{1,5})?/.exec(url);
    if (base != null) {
      return base[0];
    } else {
      console.error('Could not match address!');
      throw new Error('Could not match address!');
    }
  }

  // Is used by APP_INITIALIZER to initialize the config cache, *before* the rest of the app runs
  public loadCache(): Promise<any> {
    return this.config$
      .toPromise()
      .then(c => (this.cachedConfig = c));
  }

  public getConfig$(): Observable<ConfigDto> {
    return this.config$;
  }

  // Is safe to use through whole app
  public getCachedConfig(): ConfigDto {
    return this.cachedConfig;
  }

  CreateConfig$(): Observable<ConfigDto> {
    return this.http.get<ConfigDto>(`${this.baseUrl}/api/config`).pipe(
      publishReplay(1),
      refCount()
    );
  }
}
