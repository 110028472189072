﻿import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { PointSetService } from './PointSetService';
import { Waterway } from '../Models/Waterway';
import { PredictionResult } from '../Models/PredictionResult';
import { PredictionResultTimestep } from '../Models/PredictionResultTimestep';
import { IPredictionResult } from '../DTO/IPredictionResult';
import { IPredictionResultTimestep } from '../DTO/IPredictionResultTimestep';
import { URLSearchParams } from '@angular/http';
import { HttpAuthService } from './HttpAuthService';
import { duration } from 'moment';
import { NgxRolesService } from 'ngx-permissions';
import { flatMap, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class PredictionService {
  constructor(private http: HttpAuthService, private pointSetService: PointSetService, private roleService: NgxRolesService, private logger: NGXLogger) {
    this.logger.debug('started PredictionService');
  }

  GetPredictionsByWaterway(waterway: Waterway): Observable<PredictionResult[]> {
    this.logger.debug(`getting prediction of waterway`);
    return from(this.roleService.hasOnlyRoles(['WRITE', 'READ'])).pipe(
      flatMap(hasWriteRole => {
        let url = '/api/prediction/latest';

        if (!hasWriteRole) {
          url = `${url}/external`;
        }

        const params = new URLSearchParams();
        params.append('waterwayId', waterway.Id.toString());

        return this.http.get(url, {search: params});
      }),
      map(data => {
        const json: IPredictionResult = data.json();
        const jsonData: IPredictionResult[] = json ? [json] : [];
        const predictions: PredictionResult[] =
          jsonData.map(jsonPredictionResult => this.ConvertJsonToPredictionResult(jsonPredictionResult, waterway));
        return predictions;
      })
    );
  }

  GetPredictionMostRecentTimesteps(predictions: PredictionResult[]): PredictionResultTimestep[] {
    this.logger.debug(`getting most recent predictionTimesteps`);
    let result: PredictionResultTimestep[] = [];

    // sort reverse
    predictions = predictions.sort((p1, p2) => p2.Timestart.valueOf() - p1.Timestart.valueOf());
    let lastTimestep: PredictionResultTimestep;
    for (const prediction of predictions) {
      const timesteps = prediction.Timesteps
        .filter(ts => !lastTimestep || ts.PointSet.Datetime < lastTimestep.PointSet.Datetime)
        // sort forward
        .sort((t1, t2) => t1.PointSet.Datetime.valueOf() - t2.PointSet.Datetime.valueOf());

      if (timesteps.length) {
        lastTimestep = timesteps[0];
      }
      result = timesteps.concat(result);
    }

    return result;
  }

  private ConvertJsonToPredictionResult(jsonPrediction: IPredictionResult, waterway: Waterway): PredictionResult {

    const prediction = new PredictionResult(
      jsonPrediction.id,
      new Date(jsonPrediction.timestart),
      duration(jsonPrediction.timeinterval),
      jsonPrediction.externalId,
      jsonPrediction.rasterType,
      new Date(jsonPrediction.creationDate)
    );

    prediction.Timesteps = jsonPrediction.timesteps.map(ts => this.ConvertJsonToPredictionResultTimestep(ts, prediction));

    return prediction;
  }

  private ConvertJsonToPredictionResultTimestep(jsonPredictionTimestep: IPredictionResultTimestep, prediction: PredictionResult): PredictionResultTimestep {
    const result = new PredictionResultTimestep(prediction);
    result.PointSet = this.pointSetService.ConvertJsonToPointSet(jsonPredictionTimestep.pointSet, 5);
    return result;
  }
}
