/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./MetroMap.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./MetroMap";
import * as i3 from "ngx-logger";
var styles_MetroMap = [i0.styles];
var RenderType_MetroMap = i1.ɵcrt({ encapsulation: 0, styles: styles_MetroMap, data: {} });
export { RenderType_MetroMap as RenderType_MetroMap };
export function View_MetroMap_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_MetroMap_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "metromap", [], null, null, null, View_MetroMap_0, RenderType_MetroMap)), i1.ɵdid(1, 114688, null, 0, i2.MetroMap, [i1.ElementRef, i3.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MetroMapNgFactory = i1.ɵccf("metromap", i2.MetroMap, View_MetroMap_Host_0, { Waterways: "Waterways", SelectedWaterway: "SelectedWaterway" }, { waterwaySelected: "waterwaySelected" }, []);
export { MetroMapNgFactory as MetroMapNgFactory };
