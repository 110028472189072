import { DateRange } from 'moment-range'
import { Globals } from "../Util/Globals";

export class Requirement{

  constructor(
    public Id: number,
    public ValidRange: DateRange,
    public MinimumDepth: number,
    public MaximumDepth: number,
    public VtwNr: string
  ) { }

}
