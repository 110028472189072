﻿import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Waterway } from '../Models/Waterway';
import { ContractualArea } from '../Models/ContractualArea';
import { ContractualAreaPointLevelSet } from '../Models/ContractualAreaPointLevelSet';
import { IContractualArea } from '../DTO/IContractualArea';
import { IContractualAreaPointLevelSet } from '../DTO/IContractualAreaPointLevelSet';
import { PointSetService } from './PointSetService';
import { URLSearchParams } from '@angular/http';
import { HttpAuthService } from './HttpAuthService';
import { NgxRolesService } from 'ngx-permissions';
import { flatMap, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { IRequirement } from "../DTO/IRequirement";
import { Requirement } from "../Models/Requirement";
import { DateRange } from "moment-range";
import * as moment from 'moment';
import { Globals } from "../Util/Globals";


@Injectable()
export class ContractualAreaService {
  constructor(private http: HttpAuthService,
              private pointSetService: PointSetService,
              private roleService: NgxRolesService,
              private logger: NGXLogger) {
    logger.debug('started ContractualAreaService');
  }

  GetContractualAreasByWaterwayId(waterway: Waterway): Observable<ContractualArea[]> {
    this.logger.debug(`getting ContractualAreas of Waterway: ${waterway}`);
    return from(this.roleService.hasOnlyRoles('WRITE')).pipe(
      flatMap(hasWriteRole => {
        let url = '/api/contractualarea/' + waterway.Id;
        if (!hasWriteRole) {
          url = `${url}/external`;
        }
        return this.http.get(url);
      }),
      map(data => {
        const jsonData: IContractualArea[] = data.json();

        const contractualAreas: ContractualArea[] = jsonData.map(jsonContractualArea => this.ConvertJsonToContractualArea(jsonContractualArea, waterway));

        return contractualAreas;
      }));
  }

  GetContractualAreaPointLevelSet(contractualArea: ContractualArea, pointLevelSetId: number): Observable<ContractualAreaPointLevelSet> {
    this.logger.debug(`Getting ContractualArea PointLevelSet for ${contractualArea} and pointLevelSetId: ${pointLevelSetId}`);
    return from(this.roleService.hasOnlyRoles('WRITE')).pipe(
      flatMap(hasWriteRole => {
        let url = '/api/contractualarea/pointset';
        if (!hasWriteRole) {
          url = `${url}/external`;
        }
        const params = new URLSearchParams();
        params.append('contractualAreaId', contractualArea.Id.toString());
        params.append('pointSetId', pointLevelSetId.toString());
        return this.http.get(url, {
          search: params
        });
      }),
      map(data => {
        const jsonData: IContractualAreaPointLevelSet = data.json();

        const contractualAreaPointLevelSet: ContractualAreaPointLevelSet = this.ConvertJsonToContractualAreaPointLevelSet(jsonData, contractualArea);

        return contractualAreaPointLevelSet;
      }));
  }

  private ConvertJsonToContractualArea(jsonContractualArea: IContractualArea, waterway: Waterway): ContractualArea {
    const contractualArea = new ContractualArea(
      jsonContractualArea.id,
      waterway,
      jsonContractualArea.code,
      jsonContractualArea.useAnalysis,
      jsonContractualArea.minSoilClass,
      jsonContractualArea.soilTypeSummary,
      jsonContractualArea.minConformArea,
      jsonContractualArea.totalArea,
      jsonContractualArea.minLevelMargin
    );

    contractualArea.FirstInvalidPointSet = jsonContractualArea.firstInvalidPointSet && this.ConvertJsonToContractualAreaPointLevelSet(jsonContractualArea.firstInvalidPointSet, contractualArea);
    contractualArea.Requirements = jsonContractualArea.requirements.map(r => this.ConvertJsonToRequirement(r));
    return contractualArea;
  }

  private ConvertJsonToContractualAreaPointLevelSet(jsonContractualAreaPointLevelSet: IContractualAreaPointLevelSet, contractualArea: ContractualArea): ContractualAreaPointLevelSet {
    const contractualAreaPointLevelSet = new ContractualAreaPointLevelSet(contractualArea, this.pointSetService.ConvertJsonToPointSet(jsonContractualAreaPointLevelSet.pointSet, null),
      jsonContractualAreaPointLevelSet.volumeAboveBottomLevel, jsonContractualAreaPointLevelSet.areaAboveBottomLevel, jsonContractualAreaPointLevelSet.bottomLevelExceedance);

    return contractualAreaPointLevelSet;
  }


  private ConvertJsonToRequirement(data: IRequirement): Requirement {
    let startDate = moment(data.startDate);
    let endDate = moment(data.endDate);

    if (startDate == null) {
      startDate = Globals.minTime;
    }
    if (endDate == null) {
      endDate = Globals.maxTime;
    }

    return new Requirement(
      data.id,
      new DateRange(startDate, endDate),
      data.minimumDepth,
      data.maximumDepth,
      data.vtwNr
    );
  }
}
