var SelectedFilterService = /** @class */ (function () {
    function SelectedFilterService() {
        this.selectedFilter = '';
    }
    Object.defineProperty(SelectedFilterService.prototype, "SelectedFilter", {
        get: function () {
            return this.selectedFilter;
        },
        set: function (value) {
            this.selectedFilter = value;
        },
        enumerable: true,
        configurable: true
    });
    SelectedFilterService.prototype.printStatus = function () {
        console.debug("Current Filter Stack: " + this.selectedFilter);
    };
    return SelectedFilterService;
}());
export { SelectedFilterService };
