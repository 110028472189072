﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHistoricalSurveyImport } from '../DTO/IHistoricalSurveyImport';
import { IProjectSurveyImport } from '../DTO/IProjectSurveyImport';
import { IPredictionImport } from '../DTO/IPredictionImport';
import { IHangfireJob } from '../DTO/IHangfireJob';
import { HangfireJob } from '../Models/HangfireJob';
import { SurveyCampaignService } from './SurveyCampaignService';
import { HttpAuthService } from './HttpAuthService';
import { map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class ImportService {
  private baseUrl: string = '/api/import';

  constructor(private http: HttpAuthService, private surveyCampaignService: SurveyCampaignService, private logger: NGXLogger) {
    logger.debug('started ImportService');
  }

  ImportHistoricalSurvey(historicalSurvey: IHistoricalSurveyImport): Observable<HangfireJob> {
    this.logger.debug(`Importing HistoricalSurvey: ${historicalSurvey}`);
    const url = `${this.baseUrl}/historical`;
    return this.http.post(url, historicalSurvey)
      .pipe(
        map(res => {
          const hj: IHangfireJob = res.json();
          return this.surveyCampaignService.ConvertJsonToHangfireJob(hj);
        })
      );
  }

  ImportProjectSurvey(project: IProjectSurveyImport): Observable<HangfireJob> {
    this.logger.debug(`Importing Project survey: ${project}`);
    const url = `${this.baseUrl}/project`;
    return this.http.post(url, project)
      .pipe(
        map(res => {
          const hj: IHangfireJob = res.json();
          return this.surveyCampaignService.ConvertJsonToHangfireJob(hj);
        })
      );
  }

  ImportPrediction(prediction: IPredictionImport): Observable<string> {
    this.logger.debug(`importing prediction: ${prediction}`);
    const url = `${this.baseUrl}/prediction`;
    return this.http.post(url, prediction)
      .pipe(
        map(res => res.json())
      );
  }
}
