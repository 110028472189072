import { EventEmitter, OnInit } from '@angular/core';
import { OperationTypeHelper } from '../Util/OperationTypeHelper';
import { NGXLogger } from 'ngx-logger';
import { EmptyPointSet } from "../Models/EmptyPointSet";
var OperationTypeTimeline = /** @class */ (function () {
    function OperationTypeTimeline(logger) {
        this.logger = logger;
        this.SelectedTimestepChange = new EventEmitter();
        this.SelectedSurveyChange = new EventEmitter();
        this.SelectedEmptyPointSetChange = new EventEmitter();
        this.MilestoneDoubleClick = new EventEmitter();
        this.OperationDoubleClick = new EventEmitter();
        this.OperationTypeDoubleClick = new EventEmitter();
    }
    Object.defineProperty(OperationTypeTimeline.prototype, "Milestones", {
        get: function () {
            return this.milestones;
        },
        set: function (value) {
            this.milestones = value;
            this.CreateTimelineItemsFromMilestones();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationTypeTimeline.prototype, "Operations", {
        get: function () {
            return this.operations;
        },
        set: function (value) {
            this.operations = value;
            this.CreateTimelineItemsFromMilestones();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationTypeTimeline.prototype, "PredictionTimesteps", {
        get: function () {
            return this.predictionTimesteps;
        },
        set: function (value) {
            this.predictionTimesteps = value;
            this.CreateTimelineItemsFromMilestones();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationTypeTimeline.prototype, "Surveys", {
        get: function () {
            return this.surveys;
        },
        set: function (value) {
            this.surveys = value;
            this.CreateTimelineItemsFromMilestones();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationTypeTimeline.prototype, "SelectedTimestep", {
        get: function () {
            return this.selectedTimestep;
        },
        set: function (value) {
            this.selectedTimestep = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationTypeTimeline.prototype, "SelectedSurvey", {
        get: function () {
            return this.selectedSurvey;
        },
        set: function (value) {
            this.selectedSurvey = value;
        },
        enumerable: true,
        configurable: true
    });
    OperationTypeTimeline.prototype.ngOnInit = function () {
        this.operationTypes = OperationTypeHelper.GetOperationTypeSvgUrlPairs();
        this.groups = this.operationTypes.map(function (ot, i) { return ({
            id: i,
            content: "\n                <svg class=\"operation-type-icon " + ot.ColorBaseClass + "-fill\">\n                    <use href=\"" + ot.SvgUrl + "\" />\n                </svg>\n                ",
            order: i,
            className: "ot-" + ot.OperationType + " shadow-row",
            subgroupOrder: 'subgroupOrder'
        }); });
    };
    OperationTypeTimeline.prototype.CreateTimelineItemsFromMilestones = function () {
        var _this = this;
        if (!(this.milestones && this.operations && this.predictionTimesteps)) {
            return;
        }
        var operationTypeToGroup = function (oto) { return _this.operationTypes.findIndex(function (ot) { return ot.OperationType === oto; }); };
        var timelineItems = [];
        var milestoneOperationCombination = this.milestones
            .map(function (ms) { return ({ milestone: ms, operations: ms.Operations, operationTypeCode: ms.OperationTypeCode }); })
            .concat(this.operations.filter(function (o) { return o.Milestone == null; })
            .map(function (o) { return ({ milestone: null, operations: [o], operationTypeCode: o.OperationTypeCode }); }));
        var milestoneOperationPerType = milestoneOperationCombination.reduce(function (map, mo) {
            if (!map.has(mo.operationTypeCode)) {
                map.set(mo.operationTypeCode, []);
            }
            map.get(mo.operationTypeCode).push(mo);
            return map;
        }, new Map());
        var now = new Date();
        Array.from(milestoneOperationPerType.entries())
            .map(function (a) { return ({ key: a[0], value: a[1] }); }).forEach(function (e) {
            var sortedMilestoneAfterToday = e.value
                .filter(function (mo) { return mo.milestone != null; })
                .filter(function (mo) { return mo.milestone.GetLastDateForOperation() > now; })
                .sort(function (mo1, mo2) { return mo1.milestone.Datetime.valueOf() - mo2.milestone.Datetime.valueOf(); });
            e.value.forEach(function (mo) { return mo.isFirst = false; });
            if (sortedMilestoneAfterToday.length > 0) {
                sortedMilestoneAfterToday[0].isFirst = true;
            }
        });
        var surveyIdGetter = function (o) {
            var surveyCampaigns = o.SurveyCampaigns;
            var surveyIds = surveyCampaigns.reduce(function (col, sc) { return [].concat(col, sc.SurveyIds); }, []);
            return surveyIds
                .map(function (id) { return _this.surveys.find(function (su) { return su.Id == id; }); })
                .filter(function (su) { return su != null; })
                .map(function (su) { return su.PointSet.Id; })
                .map(function (spid) { return "su" + spid; });
        };
        var milestones = milestoneOperationCombination.filter(function (mo) { return mo.milestone != null; }).map(function (mo) { return ({
            first: mo.isFirst,
            ms: mo.milestone
        }); });
        var operations = milestoneOperationCombination
            .filter(function (mo) { return mo.operations != null && mo.operations.length > 0; })
            .map(function (mo) { return mo.operations.map(function (o) { return ({ withFirst: mo.isFirst, o: o }); }); })
            .reduce(function (o1, o2) { return o1.concat(o2); }, []);
        timelineItems = timelineItems.concat(milestones.map(function (ms) {
            var obj = OperationTypeHelper.GetObjectForOperationType(ms.ms.OperationTypeCode);
            var baseColor = ms.first ? obj.ColorBaseClass : 'grey';
            var surveyPointSetIds = [];
            if (_this.surveys) {
                surveyPointSetIds = ms.ms.Operations.reduce(function (col, o) { return [].concat(col, surveyIdGetter(o)); }, []);
            }
            return {
                id: 'ms' + ms.ms.Id,
                group: operationTypeToGroup(ms.ms.OperationTypeCode),
                start: ms.ms.Datetime,
                type: 'point',
                className: "milestone " + ms.ms.OperationTypeCode + " " + baseColor + "-bb-transparent",
                subgroup: 'milestones',
                subgroupOrder: 2,
                content: undefined,
                linkedIds: [].concat(ms.ms.Operations.map(function (o) { return "o" + o.Id; }), surveyPointSetIds)
            };
        }));
        if (this.predictionTimesteps) {
            timelineItems = timelineItems.concat(this.predictionTimesteps.map(function (ts) {
                return {
                    id: 'ts' + ts.PointSet.Id,
                    group: operationTypeToGroup('baggeren'),
                    start: ts.PointSet.Datetime,
                    type: 'point',
                    className: "timestep square",
                    subgroup: 'pointsets',
                    subgroupOrder: 2,
                    content: undefined
                };
            }));
        }
        if (this.surveys) {
            timelineItems = timelineItems.concat(this.surveys.map(function (sur) {
                var linkedOperation = null;
                if (operations) {
                    linkedOperation = operations.find(function (o) { return o.o.SurveyCampaigns.some(function (sc) { return sc.SurveyIds.includes(sur.Id); }); });
                }
                var linkedIds = [];
                if (linkedOperation) {
                    linkedIds.push("o" + linkedOperation.o.Id);
                    if (linkedOperation.o.Milestone) {
                        linkedIds.push("ms" + linkedOperation.o.Milestone.Id);
                    }
                }
                return {
                    id: 'su' + sur.PointSet.Id,
                    group: operationTypeToGroup('peilen'),
                    start: sur.PointSet.Datetime,
                    type: 'point',
                    className: "survey square",
                    subgroup: 'pointsets',
                    subgroupOrder: 2,
                    content: undefined,
                    linkedIds: linkedIds
                };
            }));
        }
        timelineItems = timelineItems.concat(operations.map(function (o) {
            var obj = OperationTypeHelper.GetObjectForOperationType(o.o.OperationTypeCode);
            var baseColor = o.withFirst ? obj.ColorBaseClass : 'grey';
            var linkedIds = [];
            if (o.o.Milestone != null) {
                linkedIds = ["ms" + o.o.Milestone.Id];
            }
            if (_this.surveys) {
                linkedIds = [].concat(linkedIds, surveyIdGetter(o.o));
            }
            return {
                id: 'o' + o.o.Id,
                group: operationTypeToGroup(o.o.OperationTypeCode),
                start: o.o.StartDatetime,
                end: o.o.EndDatetime || o.o.StartDatetime,
                className: "operation " + o.o.OperationTypeCode + " " + baseColor + "-bb-transparent",
                subgroup: 'operations',
                subgroupOrder: 1,
                content: undefined,
                linkedIds: linkedIds
            };
        })
            .filter(function (o) { return !!o.start; }));
        this.items = timelineItems;
    };
    OperationTypeTimeline.prototype.OnItemSelect = function (item) {
        var _this = this;
        var itemTypeIds = [item.id];
        if (item.linkedIds) {
            itemTypeIds = [].concat(itemTypeIds, item.linkedIds);
        }
        var itemIds = itemTypeIds.map(function (itemId) { return itemId.toString(); });
        var actions = itemIds.map(function (itemId) {
            if (itemId.startsWith('ts')) {
                var timestep_1 = _this.PredictionTimesteps.find(function (ts) { return ts.PointSet.Id === +itemId.replace('ts', ''); });
                return function () { return _this.OnTimestepSelect(timestep_1); };
            }
            else if (itemId.startsWith('su')) {
                var survey_1 = _this.surveys.find(function (su) { return su.PointSet.Id === +itemId.replace('su', ''); });
                return function () { return _this.OnSurveySelect(survey_1); };
            }
            else
                return null;
        }).filter(function (a) { return a != null; });
        if (actions.length === 0)
            this.OnEmptySelect();
        else
            actions.forEach(function (a) { return a(); });
    };
    OperationTypeTimeline.prototype.OnTimestepSelect = function (timestep) {
        this.selectedTimestep = timestep;
        this.SelectedTimestepChange.emit(timestep);
    };
    OperationTypeTimeline.prototype.OnSurveySelect = function (value) {
        this.selectedSurvey = value;
        this.SelectedSurveyChange.emit(value);
    };
    OperationTypeTimeline.prototype.OnEmptySelect = function () {
        this.SelectedEmptyPointSetChange.emit(new EmptyPointSet());
    };
    OperationTypeTimeline.prototype.OnItemDoubleClick = function (item) {
        this.logger.debug('double click');
        var itemId = item.id.toString();
        if (itemId.startsWith('ms')) {
            var milestone = this.milestones.find(function (ms) { return ms.Id === +itemId.replace('ms', ''); });
            this.MilestoneDoubleClick.emit(milestone);
        }
        else if (itemId.startsWith('o')) {
            var operation = this.operations.find(function (o) { return o.Id === +itemId.replace('o', ''); });
            this.OperationDoubleClick.emit(operation);
        }
    };
    OperationTypeTimeline.prototype.OnGroupDoubleClick = function (group) {
        this.OperationTypeDoubleClick.emit(this.operationTypes[+group.id].OperationType);
    };
    OperationTypeTimeline.prototype.RefreshTimelines = function () {
        this.CreateTimelineItemsFromMilestones();
    };
    return OperationTypeTimeline;
}());
export { OperationTypeTimeline };
