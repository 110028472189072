import { OnInit, ViewContainerRef } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { MapView } from './MapView';
import { OperationTypeTimeline } from './OperationTypeTimeline';
import { Waterway } from '../Models/Waterway';
import { ContractualArea } from '../Models/ContractualArea';
import { ContractualAreaService } from '../Services/ContractualAreaService';
import { PredictionService } from '../Services/PredictionService';
import { SurveyService } from '../Services/SurveyService';
import { MilestoneService } from '../Services/MilestoneService';
import { OperationService } from '../Services/OperationService';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractualAreaPointLevelSet } from '../Models/ContractualAreaPointLevelSet';
import { PlanningModal, PlanningModalContext } from './PlanningModal';
import { ModelChangeType } from '../Util/ModelChange';
import { filter, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from 'primeng/api';
var WaterwayDetail = /** @class */ (function () {
    function WaterwayDetail(contractualAreaService, route, router, predictionService, surveyService, milestoneService, operationService, dialogService, permissionsService, roleService, vcRef, logger) {
        this.contractualAreaService = contractualAreaService;
        this.route = route;
        this.router = router;
        this.predictionService = predictionService;
        this.surveyService = surveyService;
        this.milestoneService = milestoneService;
        this.operationService = operationService;
        this.dialogService = dialogService;
        this.permissionsService = permissionsService;
        this.roleService = roleService;
        this.logger = logger;
        this.state = 'in';
    }
    WaterwayDetail.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data
            .subscribe(function (data) {
            _this.Waterway = data.waterway;
            _this.contractualAreaService.GetContractualAreasByWaterwayId(_this.waterway)
                .subscribe(function (contractualAreas) { return _this.contractualAreas = contractualAreas; });
            _this.predictionService.GetPredictionsByWaterway(_this.waterway)
                .subscribe(function (predictions) {
                return _this.timesteps = _this.predictionService.GetPredictionMostRecentTimesteps(predictions);
            });
            _this.surveyService.GetSurveysByWaterway(_this.waterway)
                .subscribe(function (surveys) { return _this.surveys = surveys; });
        });
        this.milestoneService.GetChangeObservable()
            .pipe(filter(function (c) { return c.ChangeType === ModelChangeType.Create; }), map(function (c) { return c.Value; }))
            .subscribe(function (milestone) {
            _this.selectedMilestones.push(milestone);
        });
        this.milestoneService.GetChangeObservable()
            .pipe(filter(function (c) { return c.ChangeType === ModelChangeType.Delete; }), map(function (c) { return c.Value; }))
            .subscribe(function (milestone) {
            _this.selectedMilestones.splice(_this.selectedMilestones.indexOf(milestone), 1);
        });
        this.operationService.GetChangeObservable()
            .pipe(filter(function (c) { return c.ChangeType === ModelChangeType.Create; }), map(function (c) { return c.Value; }))
            .subscribe(function (operation) {
            _this.selectedOperations.push(operation);
        });
        this.operationService.GetChangeObservable()
            .pipe(filter(function (c) { return c.ChangeType === ModelChangeType.Delete; }), map(function (c) { return c.Value; }))
            .subscribe(function (operation) {
            _this.selectedOperations.splice(_this.selectedOperations.indexOf(operation), 1);
        });
    };
    WaterwayDetail.prototype.ToggleFullscreen = function () {
        this.state = (this.state === 'in' ? 'out' : 'in');
    };
    WaterwayDetail.prototype.UpdateMap = function () {
        this.mapView.UpdateMap();
    };
    Object.defineProperty(WaterwayDetail.prototype, "Waterway", {
        get: function () {
            return this.waterway;
        },
        set: function (value) {
            this.waterway = value;
            if (this.waterway) {
                this.selectedMilestones = this.waterway.Milestones;
                this.selectedOperations = this.waterway.Operations;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayDetail.prototype, "SelectedContractualArea", {
        get: function () {
            return this.selectedContractualArea;
        },
        set: function (value) {
            this.selectedContractualArea = value;
            this.UpdateContractualAreaPointLevelSetSubscription();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayDetail.prototype, "SelectedPointSetType", {
        get: function () {
            return this.selectedPointSetType;
        },
        set: function (value) {
            this.selectedPointSetType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaterwayDetail.prototype, "SelectedContractualAreaPointLevelSet", {
        get: function () {
            return this.selectedContractualAreaPointLevelSet;
        },
        set: function (value) {
            this.selectedContractualAreaPointLevelSet = value;
        },
        enumerable: true,
        configurable: true
    });
    WaterwayDetail.prototype.OnTimestepSelected = function (timestep) {
        timestep && this.OnPointSetHolderSelected(timestep);
    };
    WaterwayDetail.prototype.OnSurveySelected = function (survey) {
        survey && this.OnPointSetHolderSelected(survey);
    };
    WaterwayDetail.prototype.OnEmptyPointSetSelected = function (value) {
        value && this.OnPointSetHolderSelected(value);
    };
    WaterwayDetail.prototype.OnPointSetHolderSelected = function (pointSetHolder) {
        this.selectedPointSetHolder = pointSetHolder;
        this.UpdateContractualAreaPointLevelSetSubscription();
    };
    WaterwayDetail.prototype.UpdateContractualAreaPointLevelSetSubscription = function () {
        var _this = this;
        this.SelectedContractualAreaPointLevelSet = null;
        this.getContractualAreaPointLevelSetSubscription && this.getContractualAreaPointLevelSetSubscription.unsubscribe();
        if (this.selectedContractualArea) {
            if (this.selectedPointSetHolder.Type === "survey" || this.selectedPointSetHolder.Type === "prediction") {
                this.getContractualAreaPointLevelSetSubscription = this.contractualAreaService.GetContractualAreaPointLevelSet(this.selectedContractualArea, this.selectedPointSetHolder.PointSet.Id)
                    .subscribe(function (caPls) {
                    _this.SelectedPointSetType = _this.selectedPointSetHolder.Type;
                    _this.SelectedContractualAreaPointLevelSet = caPls;
                });
            }
            else if (this.selectedPointSetHolder.Type === "emptyPointSet") {
                this.SelectedPointSetType = this.selectedPointSetHolder.Type;
                this.SelectedContractualAreaPointLevelSet = null;
            }
            else if (this.selectedContractualArea.FirstInvalidPointSet) {
                this.SelectedPointSetType = 'prediction';
                this.SelectedContractualAreaPointLevelSet = this.selectedContractualArea.FirstInvalidPointSet;
            }
            else {
                this.logger.error("unknown point set type: " + this.selectedPointSetHolder.Type + ". Object: " + this.selectedPointSetHolder);
            }
        }
    };
    WaterwayDetail.prototype.OnMilestoneDoubleClick = function (milestone) {
        this.OpenModal(milestone.OperationTypeCode, milestone, milestone.Operations.length > 0 ? milestone.Operations[0] : null);
    };
    WaterwayDetail.prototype.OnOperationDoubleClick = function (operation) {
        this.OpenModal(operation.OperationTypeCode, operation.Milestone, operation);
    };
    WaterwayDetail.prototype.OnOperationTypeDoubleClick = function (operationType) {
        var _this = this;
        this.permissionsService.hasPermission('addOperation').then(function (hasPermission) {
            if (hasPermission) {
                _this.logger.debug('user has permission');
                _this.OpenModal(operationType, null, null);
            }
            else {
                _this.logger.debug('user has no permission to edit');
            }
        });
    };
    WaterwayDetail.prototype.OpenModal = function (operationType, milestone, operation) {
        var _this = this;
        this.logger.debug('opening planning View');
        var dialogRef = this.dialogService.open(PlanningModal, {
            data: new PlanningModalContext(this.waterway, operationType, milestone, operation)
        });
        dialogRef.onClose.subscribe(function () { return _this.operationTypeTimeline.RefreshTimelines(); });
    };
    return WaterwayDetail;
}());
export { WaterwayDetail };
