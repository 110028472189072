<div class="wrapper">
    <div>
        <label for="survey-time">Datum</label>
        <p-calendar [ngModel]="datetime" (ngModelChange)="userDatetime=$event" id="survey-time"
                    dateFormat="dd-mm-yy"></p-calendar>
    </div>
    <div>
        <label for="survey-time-parsed">Gebruikte datum:&emsp;</label>{{ datetime | date:'dd-MM-yyyy' }}
        <input style="margin: auto" *ngIf="!surveyFile" type="file" ng2FileSelect [uploader]="uploader" />
    </div>
    <div *ngIf="!!surveyFile">
        <div>
            Bestandsnaam: {{surveyFile.file.name}}
            <div>
                <div [ngStyle]="{ 'width': surveyFile.progress + '%', 'height': '20px', 'background-color': 'green' }"></div>
            </div>
        </div>
    </div>
    <button class="btn" [class.disabled]="!CanImport()" [disabled]="!CanImport()" (click)="Import()">Import</button>
</div>
