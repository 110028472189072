﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    template: '<div>This should not be reached</div>'
})
export class DummyComponent implements OnInit {
    constructor(private router: Router) {

    }

    ngOnInit() {
        console.log('DummyComponent reached this should not happen, probably a failure in some guard, redirecting to home');
        this.router.navigate(['']);
    }
} 