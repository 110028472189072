﻿import { Component, OnInit, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { Operation } from '../Models/Operation';

@Component({
    selector: 'operation-view',
    templateUrl: 'OperationView.html',
    styleUrls: ['OperationView.scss']
})
export class OperationView implements OnInit {
    private operation: Operation;

    constructor() {
    }

    ngOnInit() {
    }

    get Operation(): Operation {
        return this.operation;
    }

    @Input()
    set Operation(value: Operation) {
        this.operation = value;
    }

    OnEndDateChange(date: Date)
    {
        if(date)
            date.setHours(23, 59, 59, 999);
        this.operation.EndDatetime = date;
    }
}
