﻿import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2
} from '@angular/core';
import { DataGroup, DataGroupCollectionType, Timeline, TimelineEventPropertiesResult } from 'vis';
import { TimeScale } from '../Models/TimeScale';
import { TimeScaleHelper } from '../Util/TimeScaleHelper';
import * as moment from 'moment';
import { Duration, duration, Moment } from 'moment';
import { OBPTimelineGroup } from './OBPTimelineGroup';

import { OBPDataItem } from '../Models/OBPDataItem';
import { NGXLogger } from 'ngx-logger';
import { SelectedTimeService } from "../Services/SelectedTimeService";
import { fromEventPattern } from "rxjs";
import { filter, first, switchMap } from "rxjs/operators";

@Component({
  selector: 'obp-timeline',
  templateUrl: 'OBPTimeline.html',
  styleUrls: ['OBPTimeline.scss']
})
export class OBPTimeline implements OnInit, AfterContentInit {
  @ContentChildren(OBPTimelineGroup)
  private contentChildren: QueryList<OBPTimelineGroup>;

  private timeScale: TimeScale = TimeScale.Quarter;
  private timeline: Timeline;
  private timeScaleDuration: Duration;
  private continuousRedrawing: boolean;

  private changeDelay = 50;
  private lastChange: Moment = moment();
  private activeWindow: { start: Moment, end: Moment };
  private timelineFocus: Moment = moment();
  private timelineOffsetFactor = 0.15;

  private groups: DataGroupCollectionType;
  private items: OBPDataItem[];

  public timeScales: { timeScale: TimeScale, name: string }[];

  @Input()
  public verticalScroll: boolean;

  @Output()
  public SelectedGroupChange = new EventEmitter<DataGroup>();

  private selectedGroup: DataGroup;

  @Output()
  public SelectedItemChange = new EventEmitter<OBPDataItem>();

  @Output()
  public ItemDoubleClick = new EventEmitter<OBPDataItem>();

  @Output()
  public GroupDoubleClick = new EventEmitter<DataGroup>();

  private selectedItem: OBPDataItem;

  constructor(private element: ElementRef, private renderer: Renderer2, private selectedTimeService: SelectedTimeService, private logger: NGXLogger) {
    this.timeScales = [
      { timeScale: TimeScale.Week, name: 'Week' },
      { timeScale: TimeScale.Month, name: 'Maand' },
      { timeScale: TimeScale.Quarter, name: 'Kwartaal' },
      { timeScale: TimeScale.Year, name: 'Jaar' },
      { timeScale: TimeScale.FiveYear, name: '5 Jaar' }
    ];

    this.SelectedItemChange.subscribe(() => this.NotifyTimeService());
  }

  ngOnInit() {
    this.CreateTimeLine();
  }

  get TimeScale(): TimeScale {
    return this.timeScale;
  }

  @Input()
  set TimeScale(timeScale: TimeScale) {
    this.timeScale = timeScale;
    this.SetTimeScaleToTimeline(this.TimeScale);
  }

  get Items(): OBPDataItem[] {
    return this.items;
  }

  @Input()
  set Items(items: OBPDataItem[]) {
    this.items = items;
    this.timeline && this.timeline.setItems(this.items);
  }

  get Groups(): DataGroupCollectionType {
    return this.groups;
  }

  @Input()
  set Groups(groups: DataGroupCollectionType) {
    this.groups = groups;
    this.timeline && this.timeline.setGroups(this.groups);
  }

  get ContinuousRedrawing(): boolean {
    return this.continuousRedrawing;
  }

  @Input()
  set ContinuousRedrawing(value: boolean) {
    this.continuousRedrawing = value;
    if (this.continuousRedrawing) {

    }
  }

  get SelectedGroup(): DataGroup {
    return this.selectedGroup;
  }

  @Input()
  set SelectedGroup(value: DataGroup) {
    this.selectedGroup = value;
    if (this.selectedGroup) {
      const possibleElements = this.element.nativeElement.getElementsByClassName(`vis-label ${this.selectedGroup.className}`);
      if (possibleElements.length > 0) {
        possibleElements[0].scrollIntoView();
      }
    }
  }


  get SelectedItem(): OBPDataItem {
    return this.selectedItem;
  }

  @Input()
  set SelectedItem(value: OBPDataItem) {
    this.selectedItem = value;
    this.selectedItem && this.timeline.setSelection(this.selectedItem.id);
  }

  private CreateTimeLine() {
    this.logger.debug('Creating timeline');
    // specify options
    const options = {
      stack: false,
      editable: false,
      moveable: true,
      verticalScroll: this.verticalScroll,
      height: '100%',
      margin: {
        item: 10, // minimal margin between items
        axis: 5   // minimal margin between items and the axis
      },
      orientation: {
        axis: 'bottom',
        item: 'top'
      },

    };

    // create a Timeline
    const container = this.element.nativeElement.getElementsByClassName('inner-timeline')[0];
    this.timeline = new Timeline(container, [], options);

    this.timeline.on('rangechange', e => this.OnRangeChange(e.start, e.end, e.byUser));

    const mouseDown = fromEventPattern<TimelineEventPropertiesResult>(h => this.timeline.on('mouseDown', h),
        h => this.timeline.off('mouseDown', h));

    const mouseUp = fromEventPattern<TimelineEventPropertiesResult>(h => this.timeline.on('mouseUp', h),
      h => this.timeline.off('mouseUp', h));

    mouseDown.pipe(
      switchMap(md => mouseUp.pipe(
        first(),
        filter(mu => Math.abs(md.x - mu.x) < 10 && Math.abs(md.y - mu.y) < 10 )
      ))).subscribe(e => e.group && this.OnGroupSelect(e.group));

    this.timeline.on('select', e => e.items && e.items.length > 0 && this.OnItemSelect(e.items[0]));
    this.timeline.on('doubleClick', e => {
      if (e.item != null) {
        this.OnItemDoubleClick(e.item);
      } else if (e.group != null) {
        this.OnGroupDoubleClick(e.group);
      }
    });

    this.groups && this.timeline.setGroups(this.groups);
    this.items && this.timeline.setItems(this.items);

    this.SetTimeScaleToTimeline(this.TimeScale);
    this.logger.debug('timeline created');
  }

  ngAfterContentInit() {
    this.UpdateFromContentChildren(this.contentChildren);
    this.contentChildren.changes.subscribe(contentChildren => this.UpdateFromContentChildren(contentChildren));
  }

  private UpdateFromContentChildren(contentChildren: QueryList<OBPTimelineGroup>) {
    const foreground = this.element.nativeElement.getElementsByClassName('vis-foreground')[0];
    contentChildren.forEach(contentChild => {
      let targetParent = null;
      if (contentChild.OBPTimelineGroupTarget === 'timeline') {
        targetParent = foreground.getElementsByClassName(`vis-group ${contentChild.ObpTimelineGroup.className}`)[0];
      } else {
        this.logger.warn(`OBPTimelineGroupTarget ${contentChild.OBPTimelineGroupTarget} not supported by OBPTimeline`);
      }
      if (targetParent) {
        this.renderer.appendChild(targetParent, contentChild.element.nativeElement);
      }
    });
  }

  private OnGroupSelect(id: number) {
    const groupsAny: any = this.groups;
    const groups: Array<DataGroup> = groupsAny;

    this.SelectedGroup = groups.find(g => g.id === id);
    this.SelectedGroupChange.emit(this.selectedGroup);
  }

  private OnItemSelect(id: number) {
    this.SelectedItem = this.items.find(i => i.id === id);
    if (this.SelectedItem.linkedIds != null) {
      this.timeline.setSelection([].concat([id], this.SelectedItem.linkedIds));
    }

    this.SelectedItemChange.emit(this.selectedItem);

  }

  private NotifyTimeService(){
    this.selectedTimeService.TimeChanged(moment(this.selectedItem.start));
  }

  private OnItemDoubleClick(id: number) {
    this.ItemDoubleClick.emit(this.items.find(i => i.id === id));
  }

  private OnGroupDoubleClick(id: number) {
    const groupsAny: any = this.groups;
    const groups: Array<DataGroup> = groupsAny;

    this.GroupDoubleClick.emit(groups.find(g => g.id === id));
  }

  private SetTimeScaleToTimeline(timeScale: TimeScale) {
    const scaleDuration = TimeScaleHelper.TimeScaleToDuration(timeScale);
    const timescale = TimeScaleHelper.TimeScaleAroundMoment(scaleDuration, this.timelineFocus, this.timelineOffsetFactor);

    this.timeScaleDuration = scaleDuration;

    this.timeline.setWindow(timescale.start, timescale.end);
  }

  public DecreaseTimeScaleOrReset() {
    let index = this.timeScales.findIndex(v => v.timeScale === this.TimeScale);
    if (index === 0) {
      index = this.timeScales.length;
    }

    index--;

    this.TimeScale = this.GetTimeScaleByIndex(index);
  }

  private MoveTimeScale(direction: 'decrease' | 'increase') {
    let index = this.GetTimeScaleIndex();
    if (direction === 'decrease') {
      index = Math.max(index - 1, 0);
    } else if (direction === 'increase') {
      index = Math.min(index + 1, this.timeScales.length - 1);
    }
    this.timeScale = this.GetTimeScaleByIndex(index);
    this.SetTimeScaleToTimeline(this.timeScale);
    this.lastChange = moment();
  }

  private GetTimeScaleIndex(timeScale: TimeScale = this.TimeScale): number {
    return this.timeScales.findIndex(v => v.timeScale === this.TimeScale);
  }

  private GetTimeScaleByIndex(index: number): TimeScale {
    return this.timeScales[index].timeScale;
  }

  private OnRangeChange(startTimestamp: number, endTimestamp: number, byUser: boolean) {
    if (byUser) {
      const start = moment(startTimestamp);
      const end = moment(endTimestamp);
      const t1 = end.diff(start);
      const timelineSize = duration(t1);

      const curStart = this.activeWindow.start;
      const curEnd = this.activeWindow.end;
      const currentDuration = duration(curEnd.diff(curStart));

      const durDiff = duration(timelineSize).subtract(currentDuration);
      const startDiff = duration(start.diff(curStart));

      this.timelineOffsetFactor = Math.abs(startDiff.asMilliseconds()) / Math.abs(durDiff.asMilliseconds());

      if (!isFinite(this.timelineOffsetFactor)) {
        this.timelineOffsetFactor = 1.0;
      }

      const center = start.clone().add(timelineSize.asMilliseconds() * this.timelineOffsetFactor, 'ms');
      this.timelineFocus = center;

      const timeSinceLastUpdate = moment().diff(this.lastChange);
      const canUpdate = timeSinceLastUpdate > this.changeDelay;
      if (canUpdate && currentDuration > timelineSize) {
        this.MoveTimeScale('decrease');
      } else if (canUpdate && currentDuration < timelineSize) {
        this.MoveTimeScale('increase');
      } else {
        this.SetTimeScaleToTimeline(this.timeScale);
      }// reset current window
    }
    this.activeWindow = { start: moment(startTimestamp), end: moment(endTimestamp) };
  }

  public GetTimeScaleName(timeScale: TimeScale = this.TimeScale) {
    return this.timeScales.find(v => v.timeScale === timeScale).name;
  }
}
