﻿import { Component, OnInit, ElementRef, Input, Output, EventEmitter, AfterContentChecked } from '@angular/core';
import { Timeline, DataGroupCollectionType, DataItemCollectionType, DataGroup } from 'vis';
import { Waterway } from '../Models/Waterway';
import { Milestone } from '../Models/Milestone';
import { Operation } from '../Models/Operation';
import { OperationTypeHelper } from '../Util/OperationTypeHelper';
import { OBPDataItem } from '../Models/OBPDataItem';

@Component({
    selector: 'waterway-timeline',
    templateUrl: 'WaterwayTimeline.html',
    styleUrls: ['WaterwayTimeline.scss']
})
export class WaterwayTimeline implements OnInit {
    private waterways: Waterway[];
    private selectedWaterway: Waterway;

    public items: OBPDataItem[];
    public groups: DataGroupCollectionType;

    private selectedGroup: DataGroup;

    private showPriorityDate: boolean;

    @Output()
    private WaterwaySelected = new EventEmitter<Waterway>();
    constructor() {
    }

    ngOnInit() {
    }

    get Waterways(): Waterway[] {
        return this.waterways;
    }
    @Input()
    set Waterways(value: Waterway[]) {
        this.waterways = value;
        this.CreateFromWaterways(this.waterways);
    }

    get SelectedWaterway(): Waterway {
        return this.selectedWaterway;
    }
    @Input()
    set SelectedWaterway(value: Waterway) {
        if (typeof value !== 'undefined' && value != null) {
            this.selectedWaterway = value;
            const groups: any = this.groups;
            this.selectedGroup = groups.find(g => g.id === this.selectedWaterway.Id);
        }
    }


    get SelectedGroup(): DataGroup {
        return this.selectedGroup;
    }

    set SelectedGroup(value: DataGroup) {
        this.selectedGroup = value;
        if (this.selectedGroup) {
            this.SelectedWaterway = this.waterways.find(ww => ww.Id == this.selectedGroup.id);
            this.WaterwaySelected.emit(this.SelectedWaterway);
        }
    }

    get ShowPriorityDate(): boolean {
        return this.showPriorityDate;
    }

    @Input()
    set ShowPriorityDate(value: boolean) {
        this.showPriorityDate = value;
        this.CreateFromWaterways(this.waterways);
    }

    public GetGroupForWaterway(waterway: Waterway): DataGroup {
        const a: any = this.groups;
        const groups: DataGroup[] = a;
        return groups.find(g => g.id === waterway.Id);
    }

    private CreateFromWaterways(waterways: Waterway[]) {
        if (!waterways) {
            return;
        }
        const now = new Date();
        // create groups
        const groups = waterways.map((ww, i) => ({
            id: ww.Id,
            content: `
                <div class='name'>${ww.Name}</div>
                <div class='code'>${ww.Code}</div>
                `,
            order: i,
            className: `ww${ww.Id} shadow-row`,
            subgroupOrder: 'subgroupOrder'
        }));
        if (groups.length === 0) {
            groups.push({
                id: -1,
                content: `
                    <div class='name'>Geen waterways met te behalen deadlines</div>
                    <div class='code'>Leeg</div>`,
                order: 0,
                className: `ww${-1} shadow-row`,
                subgroupOrder: 'subgroupOrder'
            });
        }

        const isFirst = (ms: Milestone, ww: Waterway) => {
            const firstMilestone = ww.GetFirstMilestoneMeetingCondition(ms => ms.GetLastDateForOperation() > now);
            return firstMilestone != null && ms.Id === firstMilestone.Id;
        };
        // create items
        const milestones: { first: boolean, ms: Milestone }[] = waterways.map(ww =>
            ww.Milestones.map(ms => ({
                first: isFirst(ms, ww),
                ms: ms
            }))
        ).reduce((ms1, ms2) => ms1.concat(ms2), []);

        const operations: { withFirst: boolean, o: Operation }[] = milestones.map(ms =>
            ms.ms.Operations.map(o => ({
                withFirst: ms.first,
                o: o
            }))
        ).reduce((o1, o2) => o1.concat(o2), []);

        let timelineItems: OBPDataItem[] = [];
        timelineItems = timelineItems.concat(
            milestones.map(ms => {
                const obj = OperationTypeHelper.GetObjectForOperationType(ms.ms.OperationTypeCode);
                const baseColor = ms.first ? obj.ColorBaseClass : 'grey';
                return {
                    id: 'ms' + ms.ms.Id,
                    group: ms.ms.Waterway.Id,
                    start: ms.ms.Datetime,
                    type: 'point',
                    className: `milestone ${ms.ms.OperationTypeCode}  ${baseColor}-bb-transparent`,
                    subgroup: 'milestones',
                    subgroupOrder: 2,
                    content: undefined,
                    linkedIds: ms.ms.Operations.map(o => `o${o.Id}`)
                };
            })
        );
        if (this.showPriorityDate) {
            timelineItems = timelineItems.concat(
                this.waterways.filter(ww => !!ww.FirstInvalidPointSetDate).map(ww => {
                    return {
                        id: 'flvps' + ww.Id,
                        group: ww.Id,
                        start: ww.FirstInvalidPointSetDate,
                        type: 'point',
                        className: `first-last-valid-point-set square red-bb-transparent`,
                        subgroup: 'pointsets',
                        subgroupOrder: 2,
                        content: undefined
                    };
                })
            );
        }

        const oCodeToSvg = (url, baseColor) => {
            return `<svg class="operation-icon ${baseColor}-fill"><use href="${url}" /></svg>`;
        };
        timelineItems = timelineItems.
            concat(operations.map(o => {

                const obj = OperationTypeHelper.GetObjectForOperationType(o.o.Milestone.OperationTypeCode);
                const baseColor = o.withFirst ? obj.ColorBaseClass : 'grey';
                let linkedIds = null;
                if (o.o.Milestone != null) {
                    linkedIds = [`ms${o.o.Milestone.Id}`];
                }
                return {
                    id: 'o' + o.o.Id,
                    group: o.o.Milestone.Waterway.Id,
                    start: o.o.StartDatetime,
                    end: o.o.EndDatetime || o.o.StartDatetime,
                    className: `operation ${o.o.Milestone.OperationTypeCode} ${baseColor}-bb-transparent`,
                    content: oCodeToSvg(obj.SvgUrl, baseColor),
                    subgroup: 'operations',
                    subgroupOrder: 1,
                    linkedIds: linkedIds
                };
            })
            .filter(o => !!o.start)
        );

        this.groups = groups;
        this.items = timelineItems;
    }
}
