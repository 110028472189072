﻿import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

import { MapView } from './MapView';
import { OperationTypeTimeline } from './OperationTypeTimeline';
import { Waterway } from '../Models/Waterway';
import { ContractualArea } from '../Models/ContractualArea';
import { ContractualAreaService } from '../Services/ContractualAreaService';
import { PointSetService } from '../Services/PointSetService';
import { PredictionService } from '../Services/PredictionService';
import { SurveyService } from '../Services/SurveyService';
import { MilestoneService } from '../Services/MilestoneService';
import { OperationService } from '../Services/OperationService';
import { Router, ActivatedRoute } from '@angular/router';
import { Milestone } from '../Models/Milestone';
import { Operation } from '../Models/Operation';
import { IPointSetHolder } from '../Models/IPointSetHolder';
import { PredictionResultTimestep } from '../Models/PredictionResultTimestep';
import { SurveyResult } from '../Models/SurveyResult';
import { ContractualAreaPointLevelSet } from '../Models/ContractualAreaPointLevelSet';
import { Subscription } from 'rxjs';
import { PlanningModal, PlanningModalContext } from './PlanningModal';
import { ModelChangeType } from '../Util/ModelChange';
import { SoilInvestigationCampaignService } from '../Services/SoilInvestigationCampaignService';
import { DredgingOperationService } from '../Services/DredgingOperationService';
import { SurveyCampaignService } from '../Services/SurveyCampaignService';
import { filter, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from 'primeng/api';
import { PlanningView } from './PlanningView';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { EmptyPointSet } from "../Models/EmptyPointSet";


@Component({
  selector: 'waterway-detail',
  templateUrl: 'WaterwayDetail.html',
  providers: [
    ContractualAreaService,
    PredictionService,
    PointSetService,
    SurveyService,
    SurveyCampaignService,
    DredgingOperationService,
    SoilInvestigationCampaignService,
    DialogService
  ],
  animations: [
    trigger('otfAnimation', [
      state('in', style({ opacity: 1, transform: 'translatey(0)' })),
      state('out', style({ opacity: 0, transform: 'translatey(-100%)' })),
      transition('in => out', [
        animate('200ms 200ms ease-out')
      ]),
      transition('out => in', [
        animate('200ms 200ms ease-out')
      ])
    ]),
    trigger('wiAnimation', [
      state('in', style({ opacity: 1, transform: 'translatex(0)' })),
      state('out', style({ opacity: 0, transform: 'translatex(-150%)' })),
      transition('in => out', [
        animate('200ms 200ms ease-out')
      ]),
      transition('out => in', [
        animate('200ms 200ms ease-out')
      ])
    ]),
    trigger('obptAnimation', [
      state('in', style({ opacity: 1, transform: 'translatey(0)' })),
      state('out', style({ opacity: 0, transform: 'translatey(100%)' })),
      transition('in => out', [
        animate('200ms 200ms ease-out')
      ]),
      transition('out => in', [
        animate('200ms 200ms ease-out')
      ])
    ]),
    trigger('mvAnimation', [
      state('in', style({
      })),
      state('out', style({
        width: '98vw',
        height: '98vh',
        position: 'absolute',
        top: '0',
        margin: '1vh 1vw',
      })),
      transition('in => out', [
        animate('200ms 200ms ease-in'),
      ]),
      transition('out => in', [
        animate('200ms 200ms ease-in')
      ])
    ])
  ],
  styleUrls: ['WaterwayDetail.scss']
})

export class WaterwayDetail implements OnInit {
  private waterway: Waterway;
  public contractualAreas: ContractualArea[];
  private selectedContractualArea: ContractualArea;
  public selectedMilestones: Milestone[];
  public selectedOperations: Operation[];
  public state: string = 'in';
  public timesteps: PredictionResultTimestep[];
  public surveys: SurveyResult[];

  private selectedContractualAreaPointLevelSet: ContractualAreaPointLevelSet;
  private selectedPointSetType: string;

  public selectedPointSetHolder: IPointSetHolder;

  private getContractualAreaPointLevelSetSubscription: Subscription;

  @ViewChild(MapView)
  private mapView: MapView;

  @ViewChild(OperationTypeTimeline)
  private operationTypeTimeline: OperationTypeTimeline;

  constructor(private contractualAreaService: ContractualAreaService,
              private route: ActivatedRoute,
              private router: Router,
              private predictionService: PredictionService,
              private surveyService: SurveyService,
              private milestoneService: MilestoneService,
              private operationService: OperationService,
              private dialogService: DialogService,
              private permissionsService: NgxPermissionsService,
              private roleService: NgxRolesService,
              vcRef: ViewContainerRef,
              private logger: NGXLogger) {
  }

  ngOnInit() {
    this.route.data
      .subscribe((data: { waterway: Waterway }) => {
        this.Waterway = data.waterway;
        this.contractualAreaService.GetContractualAreasByWaterwayId(this.waterway)
          .subscribe(contractualAreas => this.contractualAreas = contractualAreas);
        this.predictionService.GetPredictionsByWaterway(this.waterway)
          .subscribe(predictions =>
            this.timesteps = this.predictionService.GetPredictionMostRecentTimesteps(predictions)
          );
        this.surveyService.GetSurveysByWaterway(this.waterway)
          .subscribe(surveys => this.surveys = surveys);
      });

    this.milestoneService.GetChangeObservable()
      .pipe(
        filter(c => c.ChangeType === ModelChangeType.Create),
        map(c => c.Value)
      )
      .subscribe(milestone => {
        this.selectedMilestones.push(milestone);
      });

    this.milestoneService.GetChangeObservable()
      .pipe(
        filter(c => c.ChangeType === ModelChangeType.Delete),
        map(c => c.Value)
      )
      .subscribe(milestone => {
        this.selectedMilestones.splice(this.selectedMilestones.indexOf(milestone), 1);
      });

    this.operationService.GetChangeObservable()
      .pipe(
        filter(c => c.ChangeType === ModelChangeType.Create),
        map(c => c.Value)
      )
      .subscribe(operation => {
        this.selectedOperations.push(operation);
      });

    this.operationService.GetChangeObservable()
      .pipe(
        filter(c => c.ChangeType === ModelChangeType.Delete),
        map(c => c.Value)
      )
      .subscribe(operation => {
        this.selectedOperations.splice(this.selectedOperations.indexOf(operation), 1);
      });
  }

  public ToggleFullscreen() {
    this.state = (this.state === 'in' ? 'out' : 'in');
  }

  public UpdateMap() {
    this.mapView.UpdateMap();
  }

  get Waterway(): Waterway {
    return this.waterway;
  }

  @Input()
  set Waterway(value: Waterway) {
    this.waterway = value;
    if (this.waterway) {
      this.selectedMilestones = this.waterway.Milestones;
      this.selectedOperations = this.waterway.Operations;
    }
  }

  get SelectedContractualArea(): ContractualArea {
    return this.selectedContractualArea;
  }

  @Input()
  set SelectedContractualArea(value: ContractualArea) {
    this.selectedContractualArea = value;
    this.UpdateContractualAreaPointLevelSetSubscription();
  }

  get SelectedPointSetType(): string {
    return this.selectedPointSetType;
  }

  @Input()
  set SelectedPointSetType(value: string) {
    this.selectedPointSetType = value;
  }

  get SelectedContractualAreaPointLevelSet(): ContractualAreaPointLevelSet {
    return this.selectedContractualAreaPointLevelSet;
  }

  @Input()
  set SelectedContractualAreaPointLevelSet(value: ContractualAreaPointLevelSet) {
    this.selectedContractualAreaPointLevelSet = value;
  }

  OnTimestepSelected(timestep: PredictionResultTimestep) {
    timestep && this.OnPointSetHolderSelected(timestep);
  }

  OnSurveySelected(survey: SurveyResult) {
    survey && this.OnPointSetHolderSelected(survey);
  }

  OnEmptyPointSetSelected(value: EmptyPointSet) {
    value && this.OnPointSetHolderSelected(value);
  }

  OnPointSetHolderSelected(pointSetHolder: IPointSetHolder) {
    this.selectedPointSetHolder = pointSetHolder;
    this.UpdateContractualAreaPointLevelSetSubscription();
  }

  UpdateContractualAreaPointLevelSetSubscription() {
    this.SelectedContractualAreaPointLevelSet = null;
    this.getContractualAreaPointLevelSetSubscription && this.getContractualAreaPointLevelSetSubscription.unsubscribe();

    if (this.selectedContractualArea) {
      if (this.selectedPointSetHolder.Type === "survey" || this.selectedPointSetHolder.Type === "prediction") {
        this.getContractualAreaPointLevelSetSubscription = this.contractualAreaService.GetContractualAreaPointLevelSet(this.selectedContractualArea, this.selectedPointSetHolder.PointSet.Id)
          .subscribe(caPls => {
            this.SelectedPointSetType = this.selectedPointSetHolder.Type;
            this.SelectedContractualAreaPointLevelSet = caPls;
          });
      } else if (this.selectedPointSetHolder.Type === "emptyPointSet") {
        this.SelectedPointSetType = this.selectedPointSetHolder.Type;
        this.SelectedContractualAreaPointLevelSet = null;
      } else if (this.selectedContractualArea.FirstInvalidPointSet) {
        this.SelectedPointSetType = 'prediction';
        this.SelectedContractualAreaPointLevelSet = this.selectedContractualArea.FirstInvalidPointSet;
      } else {
        this.logger.error(`unknown point set type: ${this.selectedPointSetHolder.Type}. Object: ${this.selectedPointSetHolder}`)
      }
    }
  }

  public OnMilestoneDoubleClick(milestone: Milestone) {
    this.OpenModal(milestone.OperationTypeCode, milestone, milestone.Operations.length > 0 ? milestone.Operations[0] : null);
  }

  public OnOperationDoubleClick(operation: Operation) {
    this.OpenModal(operation.OperationTypeCode, operation.Milestone, operation);
  }

  public OnOperationTypeDoubleClick(operationType: string) {
    this.permissionsService.hasPermission('addOperation').then(hasPermission => {
      if (hasPermission) {
        this.logger.debug('user has permission');
        this.OpenModal(operationType, null, null);
      } else {
        this.logger.debug('user has no permission to edit');
      }
    });
  }

  private OpenModal(operationType: string, milestone: Milestone, operation: Operation) {
    this.logger.debug('opening planning View');
    const dialogRef = this.dialogService.open(PlanningModal,
      {
        data: new PlanningModalContext(this.waterway, operationType, milestone, operation)
      });
    dialogRef.onClose.subscribe(() => this.operationTypeTimeline.RefreshTimelines());

  }
}
