import { EventEmitter } from '@angular/core';
import { NGXLogger } from "ngx-logger";
import * as i0 from "@angular/core";
import * as i1 from "ngx-logger";
var SelectedTimeService = /** @class */ (function () {
    function SelectedTimeService(logger) {
        this.logger = logger;
        this.SelectedTimeChanged = new EventEmitter();
    }
    SelectedTimeService.prototype.TimeChanged = function (newTime) {
        this.logger.debug("time changed to " + newTime);
        this.SelectedTimeChanged.emit(newTime);
    };
    SelectedTimeService.ngInjectableDef = i0.defineInjectable({ factory: function SelectedTimeService_Factory() { return new SelectedTimeService(i0.inject(i1.NGXLogger)); }, token: SelectedTimeService, providedIn: "root" });
    return SelectedTimeService;
}());
export { SelectedTimeService };
