import { from } from 'rxjs';
import { AdalService } from './AdalService';
var ExternalAuthService = /** @class */ (function () {
    function ExternalAuthService(adalService) {
        this.adalService = adalService;
    }
    Object.defineProperty(ExternalAuthService.prototype, "IdToken", {
        get: function () {
            return from([this.adalService.accessToken]);
        },
        enumerable: true,
        configurable: true
    });
    return ExternalAuthService;
}());
export { ExternalAuthService };
