﻿import { Milestone } from "./Milestone"
import { Waterway } from "./Waterway"
import { SurveyCampaign } from "./SurveyCampaign";
import { SoilInvestigationCampaign } from "./SoilInvestigationCampaign";
import { DredgingOperation } from "./DredgingOperation";

export class Operation {
    constructor(public Id: number, 
                public Code: string, 
                public StartDatetime: Date, 
                public EndDatetime: Date, 
                public OperationTypeCode: string, 
                public Confirmed: boolean, 
                public Milestone: Milestone,
                public Waterway: Waterway,
                public SurveyCampaigns: SurveyCampaign[],
                public SoilInvestigationCampaigns: SoilInvestigationCampaign[],
                public DredgingOperations: DredgingOperation[],
                public labelCodes: string[]) {
    }

    public toString(): string {
      return `Operation: ${this.Id}, ${this.Code}, start: ${this.StartDatetime}, end: ${this.EndDatetime},`;
    }
}
