﻿const NON_RUNNING_STATES = ["Succeeded", "Failed", "Deleted"];

export class HangfireJob {
    public constructor(public HangfireJobId: string, public State: string) {

    }

    public get IsRunning(): boolean
    {
        return !NON_RUNNING_STATES.includes(this.State);
    }
}