var NON_RUNNING_STATES = ["Succeeded", "Failed", "Deleted"];
var HangfireJob = /** @class */ (function () {
    function HangfireJob(HangfireJobId, State) {
        this.HangfireJobId = HangfireJobId;
        this.State = State;
    }
    Object.defineProperty(HangfireJob.prototype, "IsRunning", {
        get: function () {
            return !NON_RUNNING_STATES.includes(this.State);
        },
        enumerable: true,
        configurable: true
    });
    return HangfireJob;
}());
export { HangfireJob };
