import { EventEmitter, OnInit } from '@angular/core';
import { Waterway } from '../Models/Waterway';
import { Milestone } from '../Models/Milestone';
import { Operation } from '../Models/Operation';
import { OperationTypeHelper } from '../Util/OperationTypeHelper';
import { OperationService } from '../Services/OperationService';
import { MilestoneService } from '../Services/MilestoneService';
import { forkJoin, from, of } from 'rxjs';
import { finalize, flatMap, map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
var PlanningView = /** @class */ (function () {
    function PlanningView(operationService, milestoneService, logger) {
        this.operationService = operationService;
        this.milestoneService = milestoneService;
        this.logger = logger;
        this.errors = [];
        this.SavingSuccessful = new EventEmitter();
        this.OperationTypeSvgUrlPairs = OperationTypeHelper.GetOperationTypeSvgUrlPairs();
        logger.debug('Planningview created');
    }
    PlanningView.prototype.ngOnInit = function () {
    };
    Object.defineProperty(PlanningView.prototype, "Waterway", {
        get: function () {
            return this.waterway;
        },
        set: function (value) {
            this.waterway = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlanningView.prototype, "SelectedOperationType", {
        get: function () {
            return this.selectedOperationType;
        },
        set: function (value) {
            this.selectedOperationType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlanningView.prototype, "SelectedMilestone", {
        get: function () {
            return this.selectedMilestone;
        },
        set: function (value) {
            if (value == null) {
                value = this.milestoneService.CreateNew(this.waterway, this.selectedOperationType);
            }
            if (this.selectedMilestone !== value) {
                this.selectedMilestone = value;
                this.UpdateExternalLabel();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlanningView.prototype, "SelectedOperation", {
        get: function () {
            return this.selectedOperation;
        },
        set: function (value) {
            if (value == null) {
                value = this.operationService.CreateNew(this.waterway, this.selectedOperationType);
            }
            if (this.selectedOperation !== value) {
                this.selectedOperation = value;
                this.UpdateExternalLabel();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlanningView.prototype, "ExternalItem", {
        get: function () {
            var isMilestoneExternal = this.selectedMilestone && this.selectedMilestone.labelCodes.includes('external');
            var isOperationExternal = this.selectedOperation && this.selectedOperation.labelCodes.includes('external');
            return isMilestoneExternal || isOperationExternal;
        },
        set: function (value) {
            var indexMilestoneExternal = this.selectedMilestone && this.selectedMilestone.labelCodes.indexOf('external');
            var indexOperationExternal = this.selectedOperation && this.selectedOperation.labelCodes.indexOf('external');
            if (value) {
                if ((indexMilestoneExternal == null || indexMilestoneExternal) < 0 && this.selectedMilestone) {
                    this.selectedMilestone.labelCodes.push('external');
                }
                if ((indexOperationExternal == null || indexOperationExternal) < 0 && this.selectedOperation) {
                    this.selectedOperation.labelCodes.push('external');
                }
            }
            else {
                if (indexMilestoneExternal >= 0 && this.selectedMilestone) {
                    this.selectedMilestone.labelCodes.splice(indexMilestoneExternal, 1);
                }
                if (indexOperationExternal >= 0 && this.selectedOperation) {
                    this.selectedOperation.labelCodes.splice(indexOperationExternal, 1);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PlanningView.prototype.UpdateExternalLabel = function () {
        this.ExternalItem = this.ExternalItem;
    };
    PlanningView.prototype.Save = function () {
        var _this = this;
        this.isSaving = true;
        var milestoneToSave = this.selectedMilestone.Datetime != null ? this.selectedMilestone : null;
        var operationToSave = this.selectedOperation.StartDatetime == null && this.selectedOperation.EndDatetime == null ? null : this.selectedOperation;
        // when milestone exists and (is new or operation is new)
        if (milestoneToSave && (milestoneToSave.Id == null || operationToSave && operationToSave.Id == null)) {
            if (operationToSave != null) {
                milestoneToSave.Operations.push(operationToSave);
                operationToSave.Milestone = milestoneToSave;
            }
        }
        // when both already exist but one or both are deleted
        if (this.selectedMilestone.Id != null && this.selectedOperation.Id != null && (milestoneToSave == null || operationToSave == null)) {
            if (milestoneToSave != null) {
                milestoneToSave.Operations.splice(milestoneToSave.Operations.indexOf(this.selectedOperation), 1);
                this.selectedOperation.Milestone = null;
            }
            if (operationToSave != null) {
                this.selectedMilestone.Operations.splice(this.selectedMilestone.Operations.indexOf(operationToSave), 1);
                operationToSave.Milestone = null;
            }
        }
        var observablesToComplete = [];
        var saveObservable = of(null);
        if (milestoneToSave != null) {
            this.logger.debug("saving milestone " + milestoneToSave);
            saveObservable = saveObservable.pipe(flatMap(function () { return _this.milestoneService.Save(milestoneToSave); }), map(function () { return null; }));
        }
        else if (this.selectedMilestone.Id != null) {
            observablesToComplete.push(this.milestoneService.Delete(this.selectedMilestone).pipe(map(function () { return null; })));
            this.logger.debug("saving changed milestone " + this.selectedMilestone);
        }
        if (operationToSave != null) {
            this.logger.debug("saving operation " + operationToSave);
            saveObservable = saveObservable.pipe(flatMap(function () { return _this.operationService.Save(operationToSave); }), map(function () { return null; }));
        }
        else if (this.selectedOperation.Id != null) {
            this.logger.debug("'saving changed operation " + this.selectedOperation);
            observablesToComplete.push(from([this.selectedOperation]).pipe(tap(function (o) {
                if (o.SurveyCampaigns.some(function (sc) { return sc.SurveyIds.length > 0; })) {
                    throw Error('Kan operatie niet verwijderen omdat er nog peiling aangekoppeld zijn');
                }
            }), flatMap(function (o) { return _this.operationService.Delete(o).pipe(map(function () { return null; })); })));
        }
        observablesToComplete.push(saveObservable);
        forkJoin(observablesToComplete)
            .pipe(tap(function (v) {
            _this.logger.info('finalizing');
            return v;
        }, function (error) {
            // rollback
            _this.logger.error('rolling back save');
            if (milestoneToSave != null) {
                milestoneToSave.Operations.push(_this.selectedOperation);
                _this.selectedOperation.Milestone = milestoneToSave;
            }
            if (operationToSave != null) {
                _this.selectedMilestone.Operations.push(operationToSave);
                operationToSave.Milestone = _this.selectedMilestone;
            }
        }), finalize(function () {
            _this.isSaving = false;
            _this.logger.debug('Finalizing save');
        }))
            .subscribe(function (val) {
            _this.SavingSuccessful.emit();
        }, function (error) {
            _this.errors.push(error);
            _this.logger.error(error);
        });
    };
    return PlanningView;
}());
export { PlanningView };
