import { OnInit } from '@angular/core';
import { Waterway } from '../Models/Waterway';
import { WaterwayService } from '../Services/WaterwayService';
import { OperationTypeFilter } from './OperationTypeFilter';
import { SelectedFilterService } from '../Services/SelectedFilterService';
import { NGXLogger } from 'ngx-logger';
var Dashboard = /** @class */ (function () {
    function Dashboard(waterwayService, selectedFilterService, logger) {
        this.waterwayService = waterwayService;
        this.selectedFilterService = selectedFilterService;
        this.logger = logger;
        this.state = 'in';
        this.icon = 'assets/icons.svg#icn-arrow_up';
        this.cd = false;
        logger.debug('started Dashboard');
    }
    Dashboard.prototype.ngOnInit = function () {
        var me = this;
        this.waterwayService.GetWaterways().subscribe(function (waterways) {
            me.waterways = waterways;
            me.filteredWaterways = waterways;
        });
        if (this.selectedFilterService.SelectedFilter == null || this.selectedFilterService.SelectedFilter == '') {
            this.selectedFilterService.SelectedFilter = 'predict-dredge';
        }
    };
    Dashboard.prototype.WaterwaySelected = function (waterway) {
        this.logger.debug("selected waterway: " + waterway);
        this.selectedWaterway = waterway;
    };
    Object.defineProperty(Dashboard.prototype, "FilteredWaterways", {
        get: function () {
            return this.filteredWaterways;
        },
        set: function (value) {
            this.filteredWaterways = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dashboard.prototype, "SelectedWaterway", {
        get: function () {
            return this.selectedWaterway;
        },
        set: function (value) {
            this.selectedWaterway = value;
        },
        enumerable: true,
        configurable: true
    });
    Dashboard.prototype.ToggleAnimationState = function () {
        this.state = (this.state === 'in' ? 'out' : 'in');
        this.icon = (this.state === 'in' ? 'assets/icons.svg#icn-arrow_up' : 'assets/icons.svg#icn-arrow_down');
    };
    Dashboard.prototype.ContainsBaggeren = function () {
        return this.operationTypeFilter.ContainsOperationType('baggeren');
    };
    return Dashboard;
}());
export { Dashboard };
