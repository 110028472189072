﻿import { Component, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { FileItem } from 'ng2-file-upload/file-upload/file-item.class';
import * as moment from 'moment';
import { Moment } from 'moment';
import { IFileUploadRef } from '../DTO/IFileUploadRef';
import { ImportService } from '../Services/ImportService';
import { SurveyCampaign } from '../Models/SurveyCampaign';
import { IProjectSurveyImport } from '../DTO/IProjectSurveyImport';
import { AdalService } from '../Services/AdalService';
import { from, Observable } from 'rxjs';
import { catchError, finalize, flatMap, map, tap } from 'rxjs/operators';

// make a type for the parameter of FileItem.OnComplete
interface FileItemResponse {
  response: string;
  status: number;
  headers: {
    [headerFieldName: string]: string
  };
}

@Component({
  selector: 'survey-import',
  templateUrl: 'SurveyImport.html',
  providers: [ImportService],
  styleUrls: ['SurveyImport.scss']
})
export class SurveyImport {
  public uploader: FileUploader;
  public surveyFile: FileItem;
  public userDatetime: Date;
  private fileDatetime: Date;
  private surveyFileObservable: Observable<IFileUploadRef>;
  private surveyCampaign: SurveyCampaign;
  private isImporting: boolean = false;

  constructor(private importService: ImportService, private authenticationService: AdalService) {
    this.uploader = new FileUploader({
      isHTML5: true,
      url: '/api/fileupload',
      queueLimit: 1,
      autoUpload: false, // implement through method
      headers: [{name: 'Authorization', value: `Bearer ${this.authenticationService.accessToken}`}]
    });

    this.uploader.onAfterAddingFile = (fileItem) => {
      if (fileItem.file.name.endsWith('.pts')) {
        fileItem.upload();
        this.fileDatetime = this.GetDateFromFileName(fileItem.file.name).toDate();
        this.surveyFile = fileItem;
        this.surveyFileObservable = this.CreateObservableFromFileItem(fileItem).pipe(
          map(resp => {
            return JSON.parse(resp.response) as IFileUploadRef;
          }));
      } else {
        this.uploader.removeFromQueue(fileItem);
      }
    };
  }

  @Input()
  set SurveyCampaign(value: SurveyCampaign) {
    this.surveyCampaign = value;
  }

  private GetDateFromFileName(filename: string): Moment {
    const parts = filename.split('-');
    for (let p of parts) {
      let result: Moment = moment(p, 'YYMMDD');
      if (result.isValid()) {
        return result;
      }
    }
    return null;
  }

  private CreateObservableFromFileItem(fi: FileItem): Observable<FileItemResponse> {
    const prom = new Promise<FileItemResponse>((resolve, reject) => {
      fi.onSuccess = (response, status, headers) => {
        const result: FileItemResponse = {
          response: response,
          status: status,
          headers: headers
        };
        resolve(result);
      };

      fi.onError = (response, status, headers) => {
        const result: FileItemResponse = {
          response: response,
          status: status,
          headers: headers
        };
        reject(result);
      };
    });
    return from(prom);
  }

  public get datetime(): Date {
    return !!this.userDatetime ? this.userDatetime : (this.fileDatetime ? this.fileDatetime : null);
  }

  public Import() {
    this.isImporting = true;
    const surveyCampaign = this.surveyCampaign;
    this.surveyFileObservable.pipe(
      flatMap(fu => {
        const projectSurveyImport: IProjectSurveyImport = {
          timestamp: this.datetime.toISOString(),
          surveyCampaignId: surveyCampaign.Id,
          survey: fu,
          metadata: null
        };
        return this.importService.ImportProjectSurvey(projectSurveyImport);
      }),
      tap(hangFireJob => {
        surveyCampaign.HangfireJobs.push(hangFireJob);
      }),
      catchError((err) => {
        console.error(`err`, err);
        throw err;
      }),
      finalize(() => this.isImporting = false))
      .subscribe();
  }

  public CanImport(): boolean {
    return !!this.surveyFile && !this.isImporting && this.surveyFile.isUploaded;
  }

}
