﻿import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Headers, Http, ResponseContentType, URLSearchParams } from '@angular/http';
import * as ol from 'openlayers';
import { Waterway } from '../Models/Waterway';
import { ContractualArea } from '../Models/ContractualArea';
import { MapBarHelper } from '../Util/MapBarHelper';
import { ExternalAuthService } from '../Services/ExternalAuthService';
import { Observable } from 'rxjs';
import { first, flatMap, map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { source } from "openlayers";
import { TileEventTracker } from "../Util/TileEventTracker";
import { ConfigService } from "../Services/ConfigService";

@Component({
  selector: 'map-view',
  templateUrl: 'MapView.html',
  styleUrls: ['MapView.scss']
})
export class MapView implements OnInit {
  private geoserverBaseUrl: string;
  private element: ElementRef;
  private http: Http;
  private vectorSource: ol.source.Vector;
  private pointLevelRasterSource: ol.source.TileWMS;
  private selectedFeature: string;
  private map: ol.Map;
  private selectedWaterway: Waterway;
  private selectedContractualArea: ContractualArea;
  private selectedDate: Date;
  private rasterFactor: number;
  private MapBarSvgUrlPairs: { MapBar: string, SvgUrl: string }[];

  private bearerTokenObservable: Observable<string>;

  private legendUrl: string;

  public loading: boolean = true;

  @Output()
  public fullscreen = new EventEmitter();

  constructor(element: ElementRef, http: Http, configService: ConfigService ,private authService: ExternalAuthService, private tileEventTracker: TileEventTracker, private logger: NGXLogger) {
    this.element = element;
    this.http = http;
    this.MapBarSvgUrlPairs = MapBarHelper.GetMapBarSvgUrlPairs();
    this.geoserverBaseUrl = configService.getCachedConfig().geoServerUrl;
    this.bearerTokenObservable = this.authService.IdToken.pipe(map(idToken => `Bearer ${idToken}`));

    this.tileEventTracker.OnLoadStart.subscribe( () => this.loading = true);
    this.tileEventTracker.OnLoadEnd.subscribe( () => this.loading = false);

    logger.debug('created mapview');
  }

  ngOnInit() {
    // OpenStreetMaps Layer
    const osmLayer = new ol.layer.Tile({
      source: new ol.source.OSM()
    });

    let firstRun = true;
    // Source retrieving WFS data in GeoJSON format using a HTTP Req
    this.vectorSource = new ol.source.Vector({
      format: new ol.format.GeoJSON(),
      strategy: ol.loadingstrategy.tile(ol.tilegrid.createXYZ({
        maxZoom: 19
      }))
    });

    const params = {
      service: ['WFS'],
      request: ['GetFeature'],
      version: ['1.1.0'],
      outputFormat: ['application/json'],
      typename: ['deme:contractual_area'],
      srsname: ['EPSG:900913']
    };
    const paramsMap = new URLSearchParams();
    paramsMap.paramsMap = new Map(Object.entries(params));

    // load all contractual areas at once
    const url = `${this.geoserverBaseUrl}/deme/wfs`;
    this.bearerTokenObservable.pipe(
      first(),
      flatMap(bearer => this.http.get(url, {search: paramsMap, headers: this.CreateAuthHeaders(bearer)})),
      map(data => data.json()),
      tap(json => {

        const features = new ol.format.GeoJSON().readFeatures(json);
        features.forEach(f => {
          if (f.getProperties()['waterway_id'] === this.selectedWaterway.Id) {
            f.setStyle(new ol.style.Style({
              stroke: new ol.style.Stroke({
                color: 'black'
              })
            }));
          }
        });
        this.vectorSource.addFeatures(features);
        if (firstRun) {
          this.ZoomToExtent();
          firstRun = false;
        }
      }))
      .subscribe();

    // hacks
    const format: any = ol.format;

    // Vector layer
    const vectorLayer = new ol.layer.Vector({
      source: this.vectorSource,
      style: f => new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: f.getProperties()['waterway_id'] === this.selectedWaterway.Id ? 'black' : 'grey'
        })
      })
    });
    this.pointLevelRasterSource = new ol.source.TileWMS({
      url: `${this.geoserverBaseUrl}/wms`,
      params: {
        'TILED': true,
        'STYLES': 'deme:raster_difference',
        'INTERPOLATIONS': 'bilinear'
      },
      tileLoadFunction: (tile, src) => {
        const imageTile: ol.ImageTile = tile as ol.ImageTile;
        this.bearerTokenObservable
          .pipe(
            first(),
            flatMap(bearer => this.http.get(src, {
              headers: this.CreateAuthHeaders(bearer),
              responseType: ResponseContentType.Blob
            })),
            tap(response => {
              const data = URL.createObjectURL(response.blob());
              (imageTile.getImage() as HTMLImageElement).src = data;
            }))
          .subscribe();
      },
      serverType: 'geoserver',
      projection: undefined
    });

    const legendUrl = `${this.geoserverBaseUrl}/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=deme:point_level_raster&STYLE=deme:raster_difference`;

    this.bearerTokenObservable.pipe(
      flatMap(bearer => this.http.get(legendUrl, {
        headers: this.CreateAuthHeaders(bearer),
        responseType: ResponseContentType.Blob
      })),
      tap(response => {
        const data = URL.createObjectURL(response.blob());
        this.legendUrl = data;
      })).subscribe();

    this.tileEventTracker.RegisterEventsOf(this.pointLevelRasterSource);

    this.UpdatePointLevelSource();

    const plrLayer = new ol.layer.Tile({
      source: this.pointLevelRasterSource
    });

    // Map
    this.map = new ol.Map({
      controls: ol.control.defaults({
        zoom: false,
        attribution: false,
        rotate: false
      }),
      target: this.element.nativeElement.getElementsByClassName('map')[0],
      renderer: 'canvas',
      // order should be osm, soilclass, rasterdata, other polygonlayers according to WNZ-142
      layers: [osmLayer, plrLayer, vectorLayer],
      view: new ol.View({
        center: ol.proj.transform([4.777663, 51.814530], 'EPSG:4326', 'EPSG:3857'),
        maxZoom: 19,
        zoom: 10
      })
    });

  }

  private CreateAuthHeaders(bearerToken: string): Headers {
    const headers = new Headers();
    headers.append('Authorization', bearerToken);
    return headers;
  }

  private SetFeatureSelection(feature: ol.Feature, selected: boolean) {
    if (feature !== null && feature !== undefined) {
      if (selected) {
        feature.setStyle(new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: 'green',
            width: 2
          })
        }));
      } else {
        feature.setStyle(null);
      }
    }
  }

  private FindFeatureById(id: string): ol.Feature {
    for (const feature of this.vectorSource.getFeatures()) {
      const properties = feature.getProperties();
      if (properties['id'] === +id) {
        return feature;
      }
    }
    return null;
  }

  private ZoomToExtent() {
    this.ZoomToPolyExtend('mostDetailed');
  }

  public ZoomToPolyExtend(type: 'waterway' | 'mostDetailed') {
    if (!this.vectorSource) {
      return;
    }
    let extent: [number, number, number, number] | null = null;
    this.vectorSource.getFeatures()
      .filter(f => f.getProperties()['waterway_id'] === this.selectedWaterway.Id)
      .filter(f => type === 'waterway' || !this.selectedContractualArea || f.getProperties()['id'] === this.selectedContractualArea.Id)
      .forEach(f => {
        if (extent != null) {
          extent = ol.extent.extend(extent, f.getGeometry().getExtent());
        } else {
          extent = f.getGeometry().getExtent().map(v => v) as [number, number, number, number];
        }
        return extent;
      });
    if (extent) {
      this.map.getView().fit(extent, {size: this.map.getSize()});
    }
  }

  public UpdateMap() {
    this.map.updateSize();
  }

  get SelectedFeature() {
    return this.selectedFeature;
  }

  @Input()
  set SelectedFeature(id: string) {
    if (!this.vectorSource) {
      return;
    }
    this.SetFeatureSelection(this.FindFeatureById(this.selectedFeature), false);
    this.selectedFeature = id;
    this.SetFeatureSelection(this.FindFeatureById(this.selectedFeature), true);
  }

  get SelectedWaterway(): Waterway {
    return this.selectedWaterway;
  }

  @Input()
  set SelectedWaterway(value: Waterway) {
    this.selectedWaterway = value;
  }

  get SelectedContractualArea(): ContractualArea {
    return this.selectedContractualArea;
  }

  @Input()
  set SelectedContractualArea(value: ContractualArea) {
    this.selectedContractualArea = value;
    this.ZoomToExtent();
    this.SelectedFeature = this.selectedContractualArea && this.selectedContractualArea.Id.toString();
  }

  get SelectedDate(): Date {
    return this.selectedDate;
  }

  @Input()
  set SelectedDate(value: Date) {
    this.selectedDate = value;
    this.UpdatePointLevelSource();
  }


  get RasterFactor(): number {
    return this.rasterFactor;
  }

  @Input()
  set RasterFactor(value: number) {
    this.rasterFactor = value;
    this.UpdatePointLevelSource();
  }

  get LegendUrl(): string {
    return this.legendUrl;
  }

  private UpdatePointLevelSource() {
    if (this.pointLevelRasterSource) {
      const rasterFactorPostFix = this.rasterFactor !== 1 && this.rasterFactor != null ? '_x' + this.rasterFactor : '';
      const time = this.selectedDate ? this.selectedDate.toISOString() : null;
      const params = {'LAYERS': 'deme:point_level_raster' + rasterFactorPostFix, 'TIME' : time };
      this.pointLevelRasterSource.updateParams(params);
      }
  }
}
