import { AfterContentInit, ElementRef, EventEmitter, OnInit, QueryList, Renderer2 } from '@angular/core';
import { Timeline } from 'vis';
import { TimeScale } from '../Models/TimeScale';
import { TimeScaleHelper } from '../Util/TimeScaleHelper';
import * as moment from 'moment';
import { duration } from 'moment';
import { OBPTimelineGroup } from './OBPTimelineGroup';
import { NGXLogger } from 'ngx-logger';
import { SelectedTimeService } from "../Services/SelectedTimeService";
import { fromEventPattern } from "rxjs";
import { filter, first, switchMap } from "rxjs/operators";
var OBPTimeline = /** @class */ (function () {
    function OBPTimeline(element, renderer, selectedTimeService, logger) {
        var _this = this;
        this.element = element;
        this.renderer = renderer;
        this.selectedTimeService = selectedTimeService;
        this.logger = logger;
        this.timeScale = TimeScale.Quarter;
        this.changeDelay = 50;
        this.lastChange = moment();
        this.timelineFocus = moment();
        this.timelineOffsetFactor = 0.15;
        this.SelectedGroupChange = new EventEmitter();
        this.SelectedItemChange = new EventEmitter();
        this.ItemDoubleClick = new EventEmitter();
        this.GroupDoubleClick = new EventEmitter();
        this.timeScales = [
            { timeScale: TimeScale.Week, name: 'Week' },
            { timeScale: TimeScale.Month, name: 'Maand' },
            { timeScale: TimeScale.Quarter, name: 'Kwartaal' },
            { timeScale: TimeScale.Year, name: 'Jaar' },
            { timeScale: TimeScale.FiveYear, name: '5 Jaar' }
        ];
        this.SelectedItemChange.subscribe(function () { return _this.NotifyTimeService(); });
    }
    OBPTimeline.prototype.ngOnInit = function () {
        this.CreateTimeLine();
    };
    Object.defineProperty(OBPTimeline.prototype, "TimeScale", {
        get: function () {
            return this.timeScale;
        },
        set: function (timeScale) {
            this.timeScale = timeScale;
            this.SetTimeScaleToTimeline(this.TimeScale);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OBPTimeline.prototype, "Items", {
        get: function () {
            return this.items;
        },
        set: function (items) {
            this.items = items;
            this.timeline && this.timeline.setItems(this.items);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OBPTimeline.prototype, "Groups", {
        get: function () {
            return this.groups;
        },
        set: function (groups) {
            this.groups = groups;
            this.timeline && this.timeline.setGroups(this.groups);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OBPTimeline.prototype, "ContinuousRedrawing", {
        get: function () {
            return this.continuousRedrawing;
        },
        set: function (value) {
            this.continuousRedrawing = value;
            if (this.continuousRedrawing) {
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OBPTimeline.prototype, "SelectedGroup", {
        get: function () {
            return this.selectedGroup;
        },
        set: function (value) {
            this.selectedGroup = value;
            if (this.selectedGroup) {
                var possibleElements = this.element.nativeElement.getElementsByClassName("vis-label " + this.selectedGroup.className);
                if (possibleElements.length > 0) {
                    possibleElements[0].scrollIntoView();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OBPTimeline.prototype, "SelectedItem", {
        get: function () {
            return this.selectedItem;
        },
        set: function (value) {
            this.selectedItem = value;
            this.selectedItem && this.timeline.setSelection(this.selectedItem.id);
        },
        enumerable: true,
        configurable: true
    });
    OBPTimeline.prototype.CreateTimeLine = function () {
        var _this = this;
        this.logger.debug('Creating timeline');
        // specify options
        var options = {
            stack: false,
            editable: false,
            moveable: true,
            verticalScroll: this.verticalScroll,
            height: '100%',
            margin: {
                item: 10,
                axis: 5 // minimal margin between items and the axis
            },
            orientation: {
                axis: 'bottom',
                item: 'top'
            },
        };
        // create a Timeline
        var container = this.element.nativeElement.getElementsByClassName('inner-timeline')[0];
        this.timeline = new Timeline(container, [], options);
        this.timeline.on('rangechange', function (e) { return _this.OnRangeChange(e.start, e.end, e.byUser); });
        var mouseDown = fromEventPattern(function (h) { return _this.timeline.on('mouseDown', h); }, function (h) { return _this.timeline.off('mouseDown', h); });
        var mouseUp = fromEventPattern(function (h) { return _this.timeline.on('mouseUp', h); }, function (h) { return _this.timeline.off('mouseUp', h); });
        mouseDown.pipe(switchMap(function (md) { return mouseUp.pipe(first(), filter(function (mu) { return Math.abs(md.x - mu.x) < 10 && Math.abs(md.y - mu.y) < 10; })); })).subscribe(function (e) { return e.group && _this.OnGroupSelect(e.group); });
        this.timeline.on('select', function (e) { return e.items && e.items.length > 0 && _this.OnItemSelect(e.items[0]); });
        this.timeline.on('doubleClick', function (e) {
            if (e.item != null) {
                _this.OnItemDoubleClick(e.item);
            }
            else if (e.group != null) {
                _this.OnGroupDoubleClick(e.group);
            }
        });
        this.groups && this.timeline.setGroups(this.groups);
        this.items && this.timeline.setItems(this.items);
        this.SetTimeScaleToTimeline(this.TimeScale);
        this.logger.debug('timeline created');
    };
    OBPTimeline.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.UpdateFromContentChildren(this.contentChildren);
        this.contentChildren.changes.subscribe(function (contentChildren) { return _this.UpdateFromContentChildren(contentChildren); });
    };
    OBPTimeline.prototype.UpdateFromContentChildren = function (contentChildren) {
        var _this = this;
        var foreground = this.element.nativeElement.getElementsByClassName('vis-foreground')[0];
        contentChildren.forEach(function (contentChild) {
            var targetParent = null;
            if (contentChild.OBPTimelineGroupTarget === 'timeline') {
                targetParent = foreground.getElementsByClassName("vis-group " + contentChild.ObpTimelineGroup.className)[0];
            }
            else {
                _this.logger.warn("OBPTimelineGroupTarget " + contentChild.OBPTimelineGroupTarget + " not supported by OBPTimeline");
            }
            if (targetParent) {
                _this.renderer.appendChild(targetParent, contentChild.element.nativeElement);
            }
        });
    };
    OBPTimeline.prototype.OnGroupSelect = function (id) {
        var groupsAny = this.groups;
        var groups = groupsAny;
        this.SelectedGroup = groups.find(function (g) { return g.id === id; });
        this.SelectedGroupChange.emit(this.selectedGroup);
    };
    OBPTimeline.prototype.OnItemSelect = function (id) {
        this.SelectedItem = this.items.find(function (i) { return i.id === id; });
        if (this.SelectedItem.linkedIds != null) {
            this.timeline.setSelection([].concat([id], this.SelectedItem.linkedIds));
        }
        this.SelectedItemChange.emit(this.selectedItem);
    };
    OBPTimeline.prototype.NotifyTimeService = function () {
        this.selectedTimeService.TimeChanged(moment(this.selectedItem.start));
    };
    OBPTimeline.prototype.OnItemDoubleClick = function (id) {
        this.ItemDoubleClick.emit(this.items.find(function (i) { return i.id === id; }));
    };
    OBPTimeline.prototype.OnGroupDoubleClick = function (id) {
        var groupsAny = this.groups;
        var groups = groupsAny;
        this.GroupDoubleClick.emit(groups.find(function (g) { return g.id === id; }));
    };
    OBPTimeline.prototype.SetTimeScaleToTimeline = function (timeScale) {
        var scaleDuration = TimeScaleHelper.TimeScaleToDuration(timeScale);
        var timescale = TimeScaleHelper.TimeScaleAroundMoment(scaleDuration, this.timelineFocus, this.timelineOffsetFactor);
        this.timeScaleDuration = scaleDuration;
        this.timeline.setWindow(timescale.start, timescale.end);
    };
    OBPTimeline.prototype.DecreaseTimeScaleOrReset = function () {
        var _this = this;
        var index = this.timeScales.findIndex(function (v) { return v.timeScale === _this.TimeScale; });
        if (index === 0) {
            index = this.timeScales.length;
        }
        index--;
        this.TimeScale = this.GetTimeScaleByIndex(index);
    };
    OBPTimeline.prototype.MoveTimeScale = function (direction) {
        var index = this.GetTimeScaleIndex();
        if (direction === 'decrease') {
            index = Math.max(index - 1, 0);
        }
        else if (direction === 'increase') {
            index = Math.min(index + 1, this.timeScales.length - 1);
        }
        this.timeScale = this.GetTimeScaleByIndex(index);
        this.SetTimeScaleToTimeline(this.timeScale);
        this.lastChange = moment();
    };
    OBPTimeline.prototype.GetTimeScaleIndex = function (timeScale) {
        var _this = this;
        if (timeScale === void 0) { timeScale = this.TimeScale; }
        return this.timeScales.findIndex(function (v) { return v.timeScale === _this.TimeScale; });
    };
    OBPTimeline.prototype.GetTimeScaleByIndex = function (index) {
        return this.timeScales[index].timeScale;
    };
    OBPTimeline.prototype.OnRangeChange = function (startTimestamp, endTimestamp, byUser) {
        if (byUser) {
            var start = moment(startTimestamp);
            var end = moment(endTimestamp);
            var t1 = end.diff(start);
            var timelineSize = duration(t1);
            var curStart = this.activeWindow.start;
            var curEnd = this.activeWindow.end;
            var currentDuration = duration(curEnd.diff(curStart));
            var durDiff = duration(timelineSize).subtract(currentDuration);
            var startDiff = duration(start.diff(curStart));
            this.timelineOffsetFactor = Math.abs(startDiff.asMilliseconds()) / Math.abs(durDiff.asMilliseconds());
            if (!isFinite(this.timelineOffsetFactor)) {
                this.timelineOffsetFactor = 1.0;
            }
            var center = start.clone().add(timelineSize.asMilliseconds() * this.timelineOffsetFactor, 'ms');
            this.timelineFocus = center;
            var timeSinceLastUpdate = moment().diff(this.lastChange);
            var canUpdate = timeSinceLastUpdate > this.changeDelay;
            if (canUpdate && currentDuration > timelineSize) {
                this.MoveTimeScale('decrease');
            }
            else if (canUpdate && currentDuration < timelineSize) {
                this.MoveTimeScale('increase');
            }
            else {
                this.SetTimeScaleToTimeline(this.timeScale);
            } // reset current window
        }
        this.activeWindow = { start: moment(startTimestamp), end: moment(endTimestamp) };
    };
    OBPTimeline.prototype.GetTimeScaleName = function (timeScale) {
        if (timeScale === void 0) { timeScale = this.TimeScale; }
        return this.timeScales.find(function (v) { return v.timeScale === timeScale; }).name;
    };
    return OBPTimeline;
}());
export { OBPTimeline };
