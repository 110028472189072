/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./DummyComponent";
import * as i2 from "@angular/router";
var styles_DummyComponent = [];
var RenderType_DummyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DummyComponent, data: {} });
export { RenderType_DummyComponent as RenderType_DummyComponent };
export function View_DummyComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["This should not be reached"]))], null, null); }
export function View_DummyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DummyComponent_0, RenderType_DummyComponent)), i0.ɵdid(1, 114688, null, 0, i1.DummyComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DummyComponentNgFactory = i0.ɵccf("ng-component", i1.DummyComponent, View_DummyComponent_Host_0, {}, {}, []);
export { DummyComponentNgFactory as DummyComponentNgFactory };
