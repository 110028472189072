﻿import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { MilestoneService } from './MilestoneService';
import { OperationService } from './OperationService';
import { Waterway } from '../Models/Waterway';
import { IWaterwayFull } from '../DTO/IWaterwayFull';
import { HttpAuthService } from './HttpAuthService';
import { NgxRolesService } from 'ngx-permissions';
import { flatMap, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class WaterwayService {

  constructor(private http: HttpAuthService,
              private milestoneService: MilestoneService,
              private operationService: OperationService,
              private roleService: NgxRolesService,
              private logger: NGXLogger) {
    logger.debug('started WaterwayService');
  }

  GetWaterways(): Observable<Waterway[]> {
    this.logger.debug('getting waterways');
    return from(this.roleService.hasOnlyRoles('WRITE')).pipe(flatMap(hasWriteRole => {
        let url = '/api/waterway';
        if (!hasWriteRole) {
          url = `${url}/external`;
        }
        return this.http.get(url);
      }),
      map(data => {
        const jsonData: IWaterwayFull[] = data.json();

        return jsonData.map(jsonWaterway => this.ConvertJsonToWaterway(jsonWaterway));
      })
    );
  }

  GetWaterwayById(id: number): Observable<Waterway> {
    this.logger.debug(`getting waterway with id: ${id}`);
    return from(this.roleService.hasOnlyRoles('WRITE')).pipe(
      flatMap(hasWriteRole => {
        let url = `/api/waterway/${id}`;
        if (!hasWriteRole) {
          url = `${url}/external`;
        }
        return this.http.get(url);
      }),
      map(data => {
        const jsonData: IWaterwayFull = data.json();

        const waterway: Waterway = this.ConvertJsonToWaterway(jsonData);

        return waterway;
      }));
  }

  private ConvertJsonToWaterway(jsonWaterway: IWaterwayFull): Waterway {
    const waterway = new Waterway(jsonWaterway.id, jsonWaterway.code, jsonWaterway.name, jsonWaterway.firstInvalidPointSetDate && new Date(jsonWaterway.firstInvalidPointSetDate));
    waterway.Milestones = jsonWaterway.milestones.map(jsonMilestone => this.milestoneService.ConvertJsonToMileStone(jsonMilestone, waterway));
    waterway.Operations = jsonWaterway.operations.map(jsonOperation => this.operationService.ConvertJsonToOperation(jsonOperation, waterway.Milestones.find(ms => ms.Id === jsonOperation.milestoneId) || null, waterway));

    waterway.Milestones.forEach(ms => ms.Operations = []);
    waterway.Operations.forEach(o => o.Milestone && o.Milestone.Operations.push(o));

    return waterway;
  }


}
