﻿import { Injectable } from '@angular/core';
import { Operation } from '../Models/Operation';
import { IOperation } from '../DTO/IOperation';
import { Milestone } from '../Models/Milestone';
import { Waterway } from '../Models/Waterway';
import { HttpAuthService } from './HttpAuthService';
import * as moment from 'moment';
import { ModelChange, ModelChangeType } from '../Util/ModelChange';
import { SurveyCampaignService } from './SurveyCampaignService';
import { DredgingOperationService } from './DredgingOperationService';
import { SoilInvestigationCampaignService } from './SoilInvestigationCampaignService';
import { SurveyCampaign } from '../Models/SurveyCampaign';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class OperationService {
  private modelChangeSubject: Subject<ModelChange<Operation>>;
  private baseUrl = '/api/operation/';

  constructor(
    private http: HttpAuthService,
    private surveyCampaignService: SurveyCampaignService,
    private dredgingOpersionService: DredgingOperationService,
    private soilInvestigationCampaignService: SoilInvestigationCampaignService,
    private logger: NGXLogger
  ) {
    this.modelChangeSubject = new Subject<ModelChange<Operation>>();
    this.logger.debug('started OperationService');
  }

  // half solution: this is a workaround for observable and datastore not being present throughout the application
  public GetChangeObservable(): Observable<ModelChange<Operation>> {
    return this.modelChangeSubject.asObservable();
  }

  public NotifyChange(change: ModelChange<Operation>) {
    this.modelChangeSubject.next(change);
  }

  Save(operation: Operation): Observable<Operation> {
    if (operation.Id != null) {
      return this.SaveExisting(operation);
    } else {
      return this.SaveNew(operation);
    }
  }

  SaveNew(operation: Operation): Observable<Operation> {
    this.logger.debug(`saving operation: ${operation}`);
    const operationDto: IOperation = this.ConvertOperationToIOperation(operation);
    return this.http.post(this.baseUrl, operationDto).pipe(
      map(data => {
        const jsonData: IOperation = data.json();
        operation.Id = jsonData.id;

        this.UpdateIds(jsonData, operation);

        this.NotifyChange({ ChangeType: ModelChangeType.Create, Value: operation });

        return operation;
      })
    );
  }

  SaveExisting(operation: Operation): Observable<Operation> {
    this.logger.debug(`saving existing operation ${operation}`);
    const operationDto: IOperation = this.ConvertOperationToIOperation(operation);
    return this.http.put(`${this.baseUrl}${operation.Id}`, operationDto).pipe(
      map(data => {
        // this service does not return the adjusted data so it's not possible to add special operation types with an update for now
        // let jsonData: IOperation = data.json();
        // this.UpdateIds(jsonData, operation);

        this.NotifyChange({ ChangeType: ModelChangeType.Update, Value: operation });

        return operation;
      })
    );
  }

  private UpdateIds(jsonData: IOperation, operation: Operation) {
    for (let i = 0; i < jsonData.surveyCampaigns.length; i++) {
      operation.SurveyCampaigns[i].Id = jsonData.surveyCampaigns[i].id;
    }

    for (let i = 0; i < jsonData.dredgingOperations.length; i++) {
      operation.DredgingOperations[i].Id = jsonData.dredgingOperations[i].id;
    }

    for (let i = 0; i < jsonData.soilInvestigationCampaigns.length; i++) {
      operation.SoilInvestigationCampaigns[i].Id = jsonData.soilInvestigationCampaigns[i].id;
    }

  }

  Delete(operation: Operation): Observable<Operation> {
    this.logger.debug(`deleting operation: ${operation}`);
    return this.http.delete(`${this.baseUrl}${operation.Id}`).pipe(map(data => {
      this.NotifyChange({ ChangeType: ModelChangeType.Delete, Value: operation });

      return operation;
    }));
  }

  public CreateNew(waterway: Waterway, operationTypeCode: string): Operation {
    this.logger.debug(`creating new operation with typeCode ${operationTypeCode}`);
    const surveyCampaigns: SurveyCampaign[] = [];
    if (operationTypeCode === 'peilen') {
      surveyCampaigns.push(new SurveyCampaign(null, null, [], []));
    }
    return new Operation(null, null, null, null, operationTypeCode, null, null, waterway, surveyCampaigns, [], [], []);
  }

  public ConvertJsonToOperation(jsonOperation: IOperation, milestone: Milestone, waterway: Waterway): Operation {
    return new Operation(
      jsonOperation.id,
      jsonOperation.code,
      jsonOperation.startDateTime && new Date(jsonOperation.startDateTime),
      jsonOperation.endDateTime && new Date(jsonOperation.endDateTime),
      jsonOperation.operationTypeCode,
      false,
      milestone,
      waterway,
      this.surveyCampaignService.ConvertJsonToSurveyCampaigns(jsonOperation.surveyCampaigns),
      this.dredgingOpersionService.ConvertJsonToDredgingOperations(jsonOperation.dredgingOperations),
      this.soilInvestigationCampaignService.ConvertJsonToSoilInvestigationCampaign(jsonOperation.soilInvestigationCampaigns),
      jsonOperation.labelCodes
    );
  }

  public ConvertOperationToIOperation(operation: Operation): IOperation {
    return {
      id: operation.Id,
      startDateTime: operation.StartDatetime && moment(operation.StartDatetime).format(),
      endDateTime: operation.EndDatetime && moment(operation.EndDatetime).format(),
      code: operation.Code,
      operationTypeCode: operation.OperationTypeCode,
      milestoneId: operation.Milestone && operation.Milestone.Id,
      waterwayId: operation.Waterway && operation.Waterway.Id,
      surveyCampaigns: this.surveyCampaignService.ConvertSurveyCampaignsToISurveyCampaigns(operation.SurveyCampaigns),
      dredgingOperations: this.dredgingOpersionService.ConvertDredgingOperationsToIDredgingOperations(operation.DredgingOperations),
      soilInvestigationCampaigns: this.soilInvestigationCampaignService.ConvertSoilIInvestigationCampaignToISoilIInvestigationCampaign(operation.SoilInvestigationCampaigns),
      labelCodes: operation.labelCodes
    };
  }
}
