import { AdalService } from '../Services/AdalService';
var Login = /** @class */ (function () {
    function Login(adalService) {
        this.adalService = adalService;
    }
    Login.prototype.ngOnInit = function () {
    };
    Login.prototype.login = function () {
        this.adalService.login();
    };
    Login.prototype.logout = function () {
        this.adalService.logout();
    };
    Object.defineProperty(Login.prototype, "isLoggedIn", {
        get: function () {
            return this.adalService.isAuthenticated;
        },
        enumerable: true,
        configurable: true
    });
    return Login;
}());
export { Login };
