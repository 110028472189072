import { OnInit } from '@angular/core';
import { Operation } from '../Models/Operation';
var OperationView = /** @class */ (function () {
    function OperationView() {
    }
    OperationView.prototype.ngOnInit = function () {
    };
    Object.defineProperty(OperationView.prototype, "Operation", {
        get: function () {
            return this.operation;
        },
        set: function (value) {
            this.operation = value;
        },
        enumerable: true,
        configurable: true
    });
    OperationView.prototype.OnEndDateChange = function (date) {
        if (date)
            date.setHours(23, 59, 59, 999);
        this.operation.EndDatetime = date;
    };
    return OperationView;
}());
export { OperationView };
