import * as ol from "openlayers";
import { EventEmitter } from "@angular/core";

export class TileEventTracker {

  private pendingTiles = 0;
  private doneTiles = 0;

  public OnLoadStart: EventEmitter<{}> = new EventEmitter();

  public OnLoadEnd: EventEmitter<{}> = new EventEmitter();

  constructor(){}

  public RegisterEventsOf(tileSource: ol.source.Tile) {
    tileSource.on('tileloadstart', () =>this.TileLoadStartHandler());
    tileSource.on('tileloadend', () => this.TileLoadEndHandler());
    tileSource.on('tileloaderror', (e) => this.TileLoadErrorHandler(e as ol.source.TileEvent));
  }


  private TileLoadStartHandler() {
    this.pendingTiles++;

    if(this.pendingTiles - this.doneTiles === 1){
      this.OnLoadStart.emit({});
      }
  }

  private TileLoadEndHandler() {
      this.doneTiles++;
      if (this.pendingTiles - this.doneTiles <= 0) {
        this.OnLoadEnd.emit({});
        this.pendingTiles = 0;
        this.doneTiles = 0;
      }
  }

  private TileLoadErrorHandler(e: ol.source.TileEvent) {
    console.error(e);
    this.TileLoadEndHandler();
  }
}
