﻿import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AdalService } from './AdalService';

@Injectable()
export class ExternalAuthService {

  constructor(private adalService: AdalService) {
  }

  public get IdToken(): Observable<string> {
    return from([this.adalService.accessToken]);
  }
}
