import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MapView } from './Components/MapView';
import { WaterwayList } from './Components/WaterwayList';
import { Dashboard } from './Components/Dashboard';
import { MetroMap } from './Components/MetroMap';
import { WaterwayTimeline } from './Components/WaterwayTimeline';
import { OperationTypeFilter } from './Components/OperationTypeFilter';
import { OperationTypeTimeline } from './Components/OperationTypeTimeline';
import { WaterwayDetail } from './Components/WaterwayDetail';
import { WaterwayInfo } from './Components/WaterwayInfo';
import { OBPTimeline } from './Components/OBPTimeline';
import { OBPTimelineGroup } from './Components/OBPTimelineGroup';
import { SurveyImport } from './Components/SurveyImport';
import { PlanningView } from './Components/PlanningView';
import { MilestoneView } from './Components/MilestoneView';
import { OperationView } from './Components/OperationView';
import { DummyComponent } from './Components/DummyComponent';
import { NoAuthorizationPage } from './Components/NoAuthorizationPage';
import { SelectedFilterService } from './Services/SelectedFilterService';
import { SurveyView } from './Components/SurveyView';
import { Login } from './Components/Login';
import { ExternalAuthService } from './Services/ExternalAuthService';
import { SafePipe } from './Pipes/SafePipe';
import { AdalService } from './Services/AdalService';
import { ConfigService } from './Services/ConfigService';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './Routing/app-routing.module';
import { PlanningModal } from './Components/PlanningModal';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PermissionManager } from './Services/PermissionManager';
import { HttpAuthService } from './Services/HttpAuthService';
import { MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { CalendarModule } from 'primeng/primeng';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TileEventTracker } from "./Util/TileEventTracker";
import { FileUploadModule } from "ng2-file-upload";

// init blocks loading the rest of the app until the promises are resolved.
export function init(configService: ConfigService): Function {
  return () => configService.loadCache();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    FormsModule,
    AppRoutingModule,
    CalendarModule,
    NgxPermissionsModule.forRoot(),
    MsAdalAngular6Module,
    LoggerModule.forRoot({ level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.DEBUG }),
    DynamicDialogModule,
    FileUploadModule
  ],
  declarations: [
    AppComponent,
    MapView,
    WaterwayList,
    Dashboard,
    MetroMap,
    WaterwayTimeline,
    OperationTypeFilter,
    OperationTypeTimeline,
    WaterwayDetail,
    WaterwayInfo,
    OBPTimeline,
    OBPTimelineGroup,
    SurveyImport,
    PlanningView,
    MilestoneView,
    OperationView,
    PlanningModal,
    SurveyView,
    SafePipe,
    Login,
    DummyComponent,
    NoAuthorizationPage
  ],
  providers: [
    SelectedFilterService,
    AdalService,
    ConfigService,
    ExternalAuthService,
    PermissionManager,
    HttpAuthService,
    TileEventTracker,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [ConfigService],
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [PlanningModal]
})
export class AppModule {
}
