/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./OperationTypeFilter.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./OperationTypeFilter";
var styles_OperationTypeFilter = [i0.styles];
var RenderType_OperationTypeFilter = i1.ɵcrt({ encapsulation: 0, styles: styles_OperationTypeFilter, data: {} });
export { RenderType_OperationTypeFilter as RenderType_OperationTypeFilter };
function View_OperationTypeFilter_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "btn-icn"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.SelectedFilter = _v.context.$implicit.OperationType) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.SelectedFilter === _v.context.$implicit.OperationType); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.SvgUrl; _ck(_v, 1, 0, currVal_1); }); }
export function View_OperationTypeFilter_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OperationTypeFilter_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["title", "Milieu Kwaliteit Bodem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "btn-icn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToMilieuPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:use", [["href", "assets/icons.svg#tableIcon"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.OperationTypeSvgUrlPairs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OperationTypeFilter_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "operation-type-filter", [], null, null, null, View_OperationTypeFilter_0, RenderType_OperationTypeFilter)), i1.ɵdid(1, 49152, null, 0, i3.OperationTypeFilter, [], null, null)], null, null); }
var OperationTypeFilterNgFactory = i1.ɵccf("operation-type-filter", i3.OperationTypeFilter, View_OperationTypeFilter_Host_0, { Waterways: "Waterways", SelectedFilter: "SelectedFilter", ShowPredict: "ShowPredict" }, { waterwaysFiltered: "waterwaysFiltered", SelectedFilterChange: "SelectedFilterChange" }, []);
export { OperationTypeFilterNgFactory as OperationTypeFilterNgFactory };
