﻿import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataGroup, DataGroupCollectionType, DataItem } from 'vis';
import { OperationTypeHelper } from '../Util/OperationTypeHelper';
import { Milestone } from '../Models/Milestone';
import { Operation } from '../Models/Operation';
import { SurveyCampaign } from '../Models/SurveyCampaign';
import { PredictionResultTimestep } from '../Models/PredictionResultTimestep';
import { SurveyResult } from '../Models/SurveyResult';
import { OBPDataItem } from '../Models/OBPDataItem';
import { NGXLogger } from 'ngx-logger';
import { EmptyPointSet } from "../Models/EmptyPointSet";
import { IPointSetHolder } from "../Models/IPointSetHolder";

@Component({
  selector: 'operation-type-timeline',
  templateUrl: 'OperationTypeTimeline.html',
  styleUrls: ['OperationTypeTimeline.scss']
})

export class OperationTypeTimeline implements OnInit {
  public items: OBPDataItem[];
  public groups: DataGroupCollectionType;

  private milestones: Milestone[];
  private operations: Operation[];
  private predictionTimesteps: PredictionResultTimestep[];
  private surveys: SurveyResult[];

  private operationTypes: { OperationType: string, SvgUrl: string, ColorBaseClass: string }[];

  @Output()
  public SelectedTimestepChange = new EventEmitter<PredictionResultTimestep>();
  private selectedTimestep: PredictionResultTimestep;

  @Output()
  public SelectedSurveyChange = new EventEmitter<SurveyResult>();
  private selectedSurvey: SurveyResult;

  @Output()
  public SelectedEmptyPointSetChange = new EventEmitter<EmptyPointSet>();

  @Output()
  public MilestoneDoubleClick = new EventEmitter<Milestone>();

  @Output()
  public OperationDoubleClick = new EventEmitter<Operation>();

  @Output()
  public OperationTypeDoubleClick = new EventEmitter<string>();

  constructor(private logger: NGXLogger) {

  }

  get Milestones(): Milestone[] {
    return this.milestones;
  }

  @Input()
  set Milestones(value: Milestone[]) {
    this.milestones = value;
    this.CreateTimelineItemsFromMilestones();
  }

  get Operations(): Operation[] {
    return this.operations;
  }

  @Input()
  set Operations(value: Operation[]) {
    this.operations = value;
    this.CreateTimelineItemsFromMilestones();
  }

  get PredictionTimesteps(): PredictionResultTimestep[] {
    return this.predictionTimesteps;
  }

  @Input()
  set PredictionTimesteps(value: PredictionResultTimestep[]) {
    this.predictionTimesteps = value;
    this.CreateTimelineItemsFromMilestones();
  }


  get Surveys(): SurveyResult[] {
    return this.surveys;
  }

  @Input()
  set Surveys(value: SurveyResult[]) {
    this.surveys = value;
    this.CreateTimelineItemsFromMilestones();
  }


  get SelectedTimestep(): PredictionResultTimestep {
    return this.selectedTimestep;
  }

  set SelectedTimestep(value: PredictionResultTimestep) {
    this.selectedTimestep = value;
  }

  get SelectedSurvey(): SurveyResult {
    return this.selectedSurvey;
  }

  set SelectedSurvey(value: SurveyResult) {
    this.selectedSurvey = value;
  }

  ngOnInit() {
    this.operationTypes = OperationTypeHelper.GetOperationTypeSvgUrlPairs();
    this.groups = this.operationTypes.map((ot, i) => ({
      id: i,
      content: `
                <svg class="operation-type-icon ${ot.ColorBaseClass}-fill">
                    <use href="${ot.SvgUrl}" />
                </svg>
                `,
      order: i,
      className: `ot-${ot.OperationType} shadow-row`,
      subgroupOrder: 'subgroupOrder'
    }));
  }

  private CreateTimelineItemsFromMilestones() {
    if (!(this.milestones && this.operations && this.predictionTimesteps)) {
      return;
    }

    const operationTypeToGroup = oto => this.operationTypes.findIndex(ot => ot.OperationType === oto);
    let timelineItems: OBPDataItem[] = [];

    interface MilestoneOperation {
      milestone: Milestone;
      operations: Operation[];
      operationTypeCode: string;
      isFirst?: boolean;
    }

    const milestoneOperationCombination: MilestoneOperation[] = this.milestones
      .map(ms => ({milestone: ms, operations: ms.Operations, operationTypeCode: ms.OperationTypeCode}))
      .concat(
        this.operations.filter(o => o.Milestone == null)
          .map(o => ({milestone: null, operations: [o], operationTypeCode: o.OperationTypeCode}))
      );

    const milestoneOperationPerType: Map<string, MilestoneOperation[]> = milestoneOperationCombination.reduce((map, mo) => {
      if (!map.has(mo.operationTypeCode)) {
        map.set(mo.operationTypeCode, []);
      }
      map.get(mo.operationTypeCode).push(mo);
      return map;
    }, new Map<string, MilestoneOperation[]>());

    const now = new Date();

    Array.from(milestoneOperationPerType.entries())
      .map(a => ({key: a[0], value: a[1]})).forEach(e => {
      const sortedMilestoneAfterToday = e.value
        .filter(mo => mo.milestone != null)
        .filter(mo => mo.milestone.GetLastDateForOperation() > now)
        .sort((mo1, mo2) => mo1.milestone.Datetime.valueOf() - mo2.milestone.Datetime.valueOf());

      e.value.forEach(mo => mo.isFirst = false);

      if (sortedMilestoneAfterToday.length > 0) {
        sortedMilestoneAfterToday[0].isFirst = true;
      }

    });

    const surveyIdGetter: ((o: Operation) => string[]) = o => {
      const surveyCampaigns: SurveyCampaign[] = o.SurveyCampaigns;
      const surveyIds: number[] = surveyCampaigns.reduce((col, sc) => [].concat(col, sc.SurveyIds), []);
      return surveyIds
        .map(id => this.surveys.find(su => su.Id == id))
        .filter(su => su != null)
        .map(su => su.PointSet.Id)
        .map(spid => `su${spid}`);
    };

    const milestones: { first: boolean, ms: Milestone }[] = milestoneOperationCombination.filter(mo => mo.milestone != null).map(mo => ({
      first: mo.isFirst,
      ms: mo.milestone
    }));
    const operations: { withFirst: boolean, o: Operation }[] = milestoneOperationCombination
      .filter(mo => mo.operations != null && mo.operations.length > 0)
      .map(mo => mo.operations.map(o => ({withFirst: mo.isFirst, o: o})))
      .reduce((o1, o2) => o1.concat(o2), []);

    timelineItems = timelineItems.concat(
      milestones.map(ms => {
        const obj = OperationTypeHelper.GetObjectForOperationType(ms.ms.OperationTypeCode);
        const baseColor = ms.first ? obj.ColorBaseClass : 'grey';

        let surveyPointSetIds: number[] = [];
        if (this.surveys) {
          surveyPointSetIds = ms.ms.Operations.reduce((col, o) => [].concat(col, surveyIdGetter(o)), []);
        }
        return {
          id: 'ms' + ms.ms.Id,
          group: operationTypeToGroup(ms.ms.OperationTypeCode),
          start: ms.ms.Datetime,
          type: 'point',
          className: `milestone ${ms.ms.OperationTypeCode} ${baseColor}-bb-transparent`,
          subgroup: 'milestones',
          subgroupOrder: 2,
          content: undefined,
          linkedIds: [].concat(ms.ms.Operations.map(o => `o${o.Id}`), surveyPointSetIds)
        };
      })
    );
    if (this.predictionTimesteps) {
      timelineItems = timelineItems.concat(
        this.predictionTimesteps.map(ts => {
          return {
            id: 'ts' + ts.PointSet.Id,
            group: operationTypeToGroup('baggeren'),
            start: ts.PointSet.Datetime,
            type: 'point',
            className: `timestep square`,
            subgroup: 'pointsets',
            subgroupOrder: 2,
            content: undefined
          };
        })
      );
    }

    if (this.surveys) {
      timelineItems = timelineItems.concat(
        this.surveys.map(sur => {
          let linkedOperation = null;
          if (operations) {
            linkedOperation = operations.find(o => o.o.SurveyCampaigns.some(sc => sc.SurveyIds.includes(sur.Id)));
          }

          const linkedIds: string[] = [];
          if (linkedOperation) {
            linkedIds.push(`o${linkedOperation.o.Id}`);
            if (linkedOperation.o.Milestone) {
              linkedIds.push(`ms${linkedOperation.o.Milestone.Id}`);
            }
          }
          return {
            id: 'su' + sur.PointSet.Id,
            group: operationTypeToGroup('peilen'),
            start: sur.PointSet.Datetime,
            type: 'point',
            className: `survey square`,
            subgroup: 'pointsets',
            subgroupOrder: 2,
            content: undefined,
            linkedIds: linkedIds
          };
        })
      );
    }

    timelineItems = timelineItems.concat(
      operations.map(o => {
        const obj = OperationTypeHelper.GetObjectForOperationType(o.o.OperationTypeCode);
        const baseColor = o.withFirst ? obj.ColorBaseClass : 'grey';
        let linkedIds = [];
        if (o.o.Milestone != null) {
          linkedIds = [`ms${o.o.Milestone.Id}`];
        }

        if (this.surveys) {
          linkedIds = [].concat(linkedIds, surveyIdGetter(o.o));
        }

        return {
          id: 'o' + o.o.Id,
          group: operationTypeToGroup(o.o.OperationTypeCode),
          start: o.o.StartDatetime,
          end: o.o.EndDatetime || o.o.StartDatetime,
          className: `operation ${o.o.OperationTypeCode} ${baseColor}-bb-transparent`,
          subgroup: 'operations',
          subgroupOrder: 1,
          content: undefined,
          linkedIds: linkedIds
        };
      })
        .filter(o => !!o.start)
    );

    this.items = timelineItems;
  }

  public OnItemSelect(item: OBPDataItem) {
    let itemTypeIds = [item.id];
    if (item.linkedIds) {
      itemTypeIds = [].concat(itemTypeIds, item.linkedIds);
    }
    const itemIds = itemTypeIds.map(itemId => itemId.toString());

    let actions = itemIds.map(itemId => {
      if (itemId.startsWith('ts')) {
        const timestep = this.PredictionTimesteps.find(ts => ts.PointSet.Id === +itemId.replace('ts', ''));
        return () => this.OnTimestepSelect(timestep);
      } else if (itemId.startsWith('su')) {
        const survey = this.surveys.find(su => su.PointSet.Id === +itemId.replace('su', ''));
        return () => this.OnSurveySelect(survey);
      } else
        return null;
    }).filter(a => a != null);

    if (actions.length === 0)
      this.OnEmptySelect();
    else
      actions.forEach(a => a());
  }

  public OnTimestepSelect(timestep: PredictionResultTimestep) {
    this.selectedTimestep = timestep;
    this.SelectedTimestepChange.emit(timestep);
  }

  public OnSurveySelect(value: SurveyResult) {
    this.selectedSurvey = value;
    this.SelectedSurveyChange.emit(value);
  }

  public OnEmptySelect(){
    this.SelectedEmptyPointSetChange.emit(new EmptyPointSet());
  }

  public OnItemDoubleClick(item: DataItem) {
    this.logger.debug('double click');
    const itemId = item.id.toString();
    if (itemId.startsWith('ms')) {
      const milestone = this.milestones.find(ms => ms.Id === +itemId.replace('ms', ''));
      this.MilestoneDoubleClick.emit(milestone);
    } else if (itemId.startsWith('o')) {
      const operation = this.operations.find(o => o.Id === +itemId.replace('o', ''));
      this.OperationDoubleClick.emit(operation);
    }
  }

  public OnGroupDoubleClick(group: DataGroup) {
    this.OperationTypeDoubleClick.emit(this.operationTypes[+group.id].OperationType);
  }

  public RefreshTimelines() {
    this.CreateTimelineItemsFromMilestones();
  }
}
