﻿import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SurveyService} from '../Services/SurveyService';
import {SurveyResult} from '../Models/SurveyResult';
import {PointSetService} from '../Services/PointSetService';
import {Observable, of} from 'rxjs';
import {flatMap} from 'rxjs/operators';

@Component({
    selector: 'survey-view',
    templateUrl: 'SurveyView.html',
    styleUrls: ['SurveyView.scss'],
    providers: [SurveyService, PointSetService]
})
export class SurveyView {
    public survey: SurveyResult;
    private surveyId: number;
    private surveyObservable: Observable<SurveyResult>;
    private isDeleting: boolean;
    @Output()
    public onDelete: EventEmitter<number> = new EventEmitter<number>();

    constructor(private surveyService: SurveyService) {
        this.surveyObservable = of(null) as Observable<SurveyResult>;
    }

    @Input()
    AllowDelete: boolean;

    @Input()
    set SurveyId(value: number) {
        if (this.surveyId !== value) {
            this.surveyObservable = this.surveyObservable.pipe(
              flatMap(() => this.surveyService.GetSurveyById(value)));
            this.surveyObservable.subscribe(s => this.survey = s,
                err => console.error(err));
            this.surveyId = value;
        }
    }

    DeleteSurvey() {
        this.isDeleting = true;
        const surveyId = this.survey.Id;
        this.surveyService.DeleteSurvey(surveyId)
            .subscribe(() => {
                    this.onDelete.emit(surveyId);
                    this.survey = null;
                },
                err => console.error('SurveyDelete: Delete failed', err),
                () => this.isDeleting = false
        );
    }
}
