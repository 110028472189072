﻿import { Waterway } from './Waterway';
import * as moment from 'moment';
import { duration, Moment } from 'moment';
import { ContractualAreaPointLevelSet } from './ContractualAreaPointLevelSet'
import { Requirement } from "./Requirement";

export class ContractualArea {
  public FirstInvalidPointSet: ContractualAreaPointLevelSet;
  public Requirements: Requirement[];

  constructor(
    public Id: number,
    public Waterway: Waterway,
    public Code: string,
    public UseAnalysis: boolean,
    public MinSoilClass: string,
    public SoilTypeSummary: string,
    public MinConformArea: number,
    public TotalArea: number,
    public MinLevelMargin: number) {
  }

  ///Urgency: range for 0-* where 0 is the most urgent en the urgency decreases when the number decrease
  public get Urgency(): number {
    let now = moment();
    let maxDredingDate = this.MaxDredgingDate && moment(this.MaxDredgingDate);
    if (maxDredingDate != null) {
      let diff = duration(maxDredingDate.diff(now));
      let diffMonths = diff.asMonths();
      diffMonths = Math.max(diffMonths, 0);
      return Math.round(diffMonths);
    } else
      return Infinity;
  }

  public GetValidRequirement(date: Moment): Requirement | null {
    const candidate = this.Requirements
      .filter(r => r.ValidRange.contains(date));

    if (candidate.length > 1)
      // Find shortest period which contains date, diff gives duration between start and end.
      return candidate.reduce((acc, val) => val.ValidRange.diff() < acc.ValidRange.diff() ? val : acc);
    else if (candidate.length == 1)
      return candidate[0];
    else
      return null;
  }

  public get MaxDredgingDate(): Date {
    return this.FirstInvalidPointSet && this.FirstInvalidPointSet.PointLevelSet.Datetime;
  }
}
