/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./MapView.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../Pipes/SafePipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./MapView";
import * as i6 from "@angular/http";
import * as i7 from "../Services/ConfigService";
import * as i8 from "../Services/ExternalAuthService";
import * as i9 from "../Util/TileEventTracker";
import * as i10 from "ngx-logger";
var styles_MapView = [i0.styles];
var RenderType_MapView = i1.ɵcrt({ encapsulation: 0, styles: styles_MapView, data: {} });
export { RenderType_MapView as RenderType_MapView };
function View_MapView_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_MapView_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafePipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "btn-icn-fullscreen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fullscreen.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "btn-icn-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ZoomToPolyExtend("waterway") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapView_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "img", [["class", "legend"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(10, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.loading; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "assets/icons.svg#icn-fullscreen"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "assets/icons.svg#icn-recenter"; _ck(_v, 5, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.LegendUrl)); _ck(_v, 9, 0, currVal_3); }); }
export function View_MapView_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "map-view", [], null, null, null, View_MapView_0, RenderType_MapView)), i1.ɵdid(1, 114688, null, 0, i5.MapView, [i1.ElementRef, i6.Http, i7.ConfigService, i8.ExternalAuthService, i9.TileEventTracker, i10.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapViewNgFactory = i1.ɵccf("map-view", i5.MapView, View_MapView_Host_0, { SelectedFeature: "SelectedFeature", SelectedWaterway: "SelectedWaterway", SelectedContractualArea: "SelectedContractualArea", SelectedDate: "SelectedDate", RasterFactor: "RasterFactor" }, { fullscreen: "fullscreen" }, []);
export { MapViewNgFactory as MapViewNgFactory };
