import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { DummyComponent } from '../Components/DummyComponent';
import { NoAuthorizationPage } from '../Components/NoAuthorizationPage';
import { Dashboard } from '../Components/Dashboard';
import { WaterwayDetail } from '../Components/WaterwayDetail';
import { SurveyImport } from '../Components/SurveyImport';
import { WaterwayDetailResolve } from './WaterwayDetailResolve';
import { AuthenticationGuard } from '../Services/AuthenticationGuard';
import { OAuthCallbackHandler } from '../Services/OAuthCallbackHandler';
import { Login } from '../Components/Login';
import { AutomaticLoginGuard } from '../Services/AutomaticLoginGuard';
var ɵ0 = {
    permissions: {
        only: ['READ', 'WRITE'],
        redirectTo: 'no-authorization'
    }
}, ɵ1 = {
    permissions: {
        only: ['WRITE'],
        redirectTo: 'no-authorization'
    }
}, ɵ2 = {
    permissions: {
        only: ['READ', 'WRITE'],
        redirectTo: 'no-authorization'
    }
};
var appRoutes = [
    { path: 'login/aad/callback', component: DummyComponent, canActivate: [OAuthCallbackHandler] },
    { path: 'login', component: Login, canActivate: [AutomaticLoginGuard] },
    { path: 'no-authorization', component: NoAuthorizationPage, canActivate: [AuthenticationGuard] },
    {
        path: 'waterway/:id',
        component: WaterwayDetail,
        resolve: {
            waterway: WaterwayDetailResolve
        },
        canActivate: [AuthenticationGuard, NgxPermissionsGuard],
        data: ɵ0
    },
    {
        path: 'import',
        component: SurveyImport,
        canActivate: [AuthenticationGuard, NgxPermissionsGuard],
        data: ɵ1
    },
    {
        path: '',
        component: Dashboard,
        canActivate: [AuthenticationGuard, NgxPermissionsGuard],
        data: ɵ2
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
