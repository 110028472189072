<metromap
  [@metromapanimation] = "state"
  [Waterways]="waterways"
  (waterwaySelected)="WaterwaySelected($event)"
  [SelectedWaterway]="SelectedWaterway">
</metromap>
<svg class="btn-icn" (click)="ToggleAnimationState()">
  <use [attr.xlink:href]="icon"/>
</svg>
<operation-type-filter [Waterways]="waterways" (waterwaysFiltered)="FilteredWaterways = $event" ShowPredict="true"
                       [(SelectedFilter)]="selectedFilterService.SelectedFilter"></operation-type-filter>
<waterway-timeline
  [@timelineanimation] = "state"
  [SelectedWaterway]="SelectedWaterway"
  [Waterways]="FilteredWaterways"
  [ShowPriorityDate]="ContainsBaggeren()"
  (WaterwaySelected)="WaterwaySelected($event)">
</waterway-timeline>
