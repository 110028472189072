﻿import { Component, OnInit, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { Milestone } from '../Models/Milestone';

@Component({
    selector: 'milestone-view',
    templateUrl: 'MilestoneView.html',
    styleUrls: ['MilestoneView.scss']
})
export class MilestoneView implements OnInit {
    private milestone: Milestone;
    constructor() {
    }

    ngOnInit() {
    }

    get Milestone(): Milestone {
        return this.milestone;
    }
    
    @Input()
    set Milestone(value: Milestone) {
        this.milestone = value;
    }
}
