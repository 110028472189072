/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./Login.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./Login";
import * as i4 from "../Services/AdalService";
var styles_Login = [i0.styles];
var RenderType_Login = i1.ɵcrt({ encapsulation: 0, styles: styles_Login, data: {} });
export { RenderType_Login as RenderType_Login };
function View_Login_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"]))], null, null); }
function View_Login_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], null, null); }
export function View_Login_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Login_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Login_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoggedIn; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isLoggedIn; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_Login_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "login", [], null, null, null, View_Login_0, RenderType_Login)), i1.ɵdid(1, 114688, null, 0, i3.Login, [i4.AdalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginNgFactory = i1.ɵccf("login", i3.Login, View_Login_Host_0, {}, {}, []);
export { LoginNgFactory as LoginNgFactory };
