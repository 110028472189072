﻿import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Waterway } from '../Models/Waterway';
import { Milestone } from '../Models/Milestone';
import { Operation } from '../Models/Operation';
import { OperationTypeHelper } from '../Util/OperationTypeHelper';
import { OperationService } from '../Services/OperationService';
import { MilestoneService } from '../Services/MilestoneService';
import { forkJoin, from, Observable, of } from 'rxjs';
import { finalize, flatMap, map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'planning-view',
  templateUrl: 'PlanningView.html',
  styleUrls: ['PlanningView.scss']
})
export class PlanningView implements OnInit {
  private waterway: Waterway;
  private selectedOperationType: string;
  private selectedMilestone: Milestone;
  private selectedOperation: Operation;
  public isSaving: boolean;
  public errors: Error[] = [];

  public OperationTypeSvgUrlPairs: { OperationType: string, SvgUrl: string, ColorBaseClass: string }[];

  constructor(private operationService: OperationService, private milestoneService: MilestoneService, private logger: NGXLogger) {
    this.OperationTypeSvgUrlPairs = OperationTypeHelper.GetOperationTypeSvgUrlPairs();
    logger.debug('Planningview created');
  }

  ngOnInit() {
  }

  @Output()
  public SavingSuccessful = new EventEmitter();

  get Waterway(): Waterway {
    return this.waterway;
  }

  @Input()
  set Waterway(value: Waterway) {
    this.waterway = value;
  }

  get SelectedOperationType(): string {
    return this.selectedOperationType;
  }

  @Input()
  set SelectedOperationType(value: string) {
    this.selectedOperationType = value;
  }

  get SelectedMilestone(): Milestone {
    return this.selectedMilestone;
  }

  @Input()
  set SelectedMilestone(value: Milestone) {
    if (value == null) {
      value = this.milestoneService.CreateNew(this.waterway, this.selectedOperationType);
    }
    if (this.selectedMilestone !== value) {
      this.selectedMilestone = value;
      this.UpdateExternalLabel();
    }
  }

  get SelectedOperation(): Operation {
    return this.selectedOperation;
  }

  @Input()
  set SelectedOperation(value: Operation) {
    if (value == null) {
      value = this.operationService.CreateNew(this.waterway, this.selectedOperationType);
    }
    if (this.selectedOperation !== value) {
      this.selectedOperation = value;
      this.UpdateExternalLabel();
    }
  }

  get ExternalItem(): boolean {
    const isMilestoneExternal = this.selectedMilestone && this.selectedMilestone.labelCodes.includes('external');
    const isOperationExternal = this.selectedOperation && this.selectedOperation.labelCodes.includes('external');

    return isMilestoneExternal || isOperationExternal;
  }

  @Input()
  set ExternalItem(value: boolean) {
    const indexMilestoneExternal = this.selectedMilestone && this.selectedMilestone.labelCodes.indexOf('external');
    const indexOperationExternal = this.selectedOperation && this.selectedOperation.labelCodes.indexOf('external');
    if (value) {
      if ((indexMilestoneExternal == null || indexMilestoneExternal) < 0 && this.selectedMilestone) {
        this.selectedMilestone.labelCodes.push('external');
      }
      if ((indexOperationExternal == null || indexOperationExternal) < 0 && this.selectedOperation) {
        this.selectedOperation.labelCodes.push('external');
      }
    } else {
      if (indexMilestoneExternal >= 0 && this.selectedMilestone) {
        this.selectedMilestone.labelCodes.splice(indexMilestoneExternal, 1);
      }
      if (indexOperationExternal >= 0 && this.selectedOperation) {
        this.selectedOperation.labelCodes.splice(indexOperationExternal, 1);
      }
    }
  }

  private UpdateExternalLabel() {
    this.ExternalItem = this.ExternalItem;
  }

  public Save() {
    this.isSaving = true;
    const milestoneToSave = this.selectedMilestone.Datetime != null ? this.selectedMilestone : null;
    const operationToSave = this.selectedOperation.StartDatetime == null && this.selectedOperation.EndDatetime == null ? null : this.selectedOperation;

    // when milestone exists and (is new or operation is new)
    if (milestoneToSave && (milestoneToSave.Id == null || operationToSave && operationToSave.Id == null)) {
      if (operationToSave != null) {
        milestoneToSave.Operations.push(operationToSave);
        operationToSave.Milestone = milestoneToSave;
      }
    }

    // when both already exist but one or both are deleted
    if (this.selectedMilestone.Id != null && this.selectedOperation.Id != null && (milestoneToSave == null || operationToSave == null)) {
      if (milestoneToSave != null) {
        milestoneToSave.Operations.splice(milestoneToSave.Operations.indexOf(this.selectedOperation), 1);
        this.selectedOperation.Milestone = null;
      }

      if (operationToSave != null) {
        this.selectedMilestone.Operations.splice(this.selectedMilestone.Operations.indexOf(operationToSave), 1);
        operationToSave.Milestone = null;
      }
    }

    const observablesToComplete: Observable<void>[] = [];

    let saveObservable: Observable<void> = of(null);

    if (milestoneToSave != null) {
      this.logger.debug(`saving milestone ${milestoneToSave}`);
      saveObservable = saveObservable.pipe(
        flatMap(() => this.milestoneService.Save(milestoneToSave)),
        map(() => null)
      );
    } else if (this.selectedMilestone.Id != null) {
      observablesToComplete.push(
        this.milestoneService.Delete(this.selectedMilestone).pipe(
          map(() => null)));
      this.logger.debug(`saving changed milestone ${this.selectedMilestone}`);
    }

    if (operationToSave != null) {
      this.logger.debug(`saving operation ${operationToSave}`);
      saveObservable = saveObservable.pipe(
        flatMap(() => this.operationService.Save(operationToSave)),
        map(() => null));

    } else if (this.selectedOperation.Id != null) {
      this.logger.debug(`'saving changed operation ${this.selectedOperation}`);
      observablesToComplete.push(
        from([this.selectedOperation]).pipe(
          tap(o => {
            if (o.SurveyCampaigns.some(sc => sc.SurveyIds.length > 0)) {
              throw Error('Kan operatie niet verwijderen omdat er nog peiling aangekoppeld zijn');
            }
          }),
          flatMap(
            o => this.operationService.Delete(o).pipe(map(() => null))
          ))
      );
    }

    observablesToComplete.push(saveObservable);
    forkJoin(observablesToComplete)
      .pipe(
        tap(v => {
            this.logger.info('finalizing');
            return v;
          }
          , error => {
            // rollback
            this.logger.error('rolling back save');
            if (milestoneToSave != null) {
              milestoneToSave.Operations.push(this.selectedOperation);
              this.selectedOperation.Milestone = milestoneToSave;
            }

            if (operationToSave != null) {
              this.selectedMilestone.Operations.push(operationToSave);
              operationToSave.Milestone = this.selectedMilestone;
            }
          }),
        finalize(() => {
          this.isSaving = false;
          this.logger.debug('Finalizing save');
        }))
      .subscribe(
        val => {
          this.SavingSuccessful.emit();
        },
        error => {
          this.errors.push(error as Error);
          this.logger.error(error);
        });
  }
}
