﻿import { EventEmitter, Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { AdalService } from './AdalService';
import { Headers, Http, Request, RequestMethod, RequestOptions, RequestOptionsArgs, Response } from '@angular/http';
import { finalize } from 'rxjs/operators';

export enum Action { QueryStart, QueryStop }

// can be replace with HttpClient in angular 4
// based on: https://gist.github.com/chandermani/9166abe6e6608a31f471
@Injectable()
export class HttpAuthService {
  process: EventEmitter<any> = new EventEmitter<any>();
  authFailed: EventEmitter<any> = new EventEmitter<any>();

  public constructor(private http: Http, private authenticationService: AdalService) {
  }

  private _buildAuthHeader(): string {
    const authToken = this.authenticationService && this.authenticationService.accessToken;
    return authToken && `Bearer ${authToken}`;
  }

  public get(url: string, options?: RequestOptionsArgs): Rx.Observable<Response> {
    return this._request(RequestMethod.Get, url, null, options);
  }

  public post(url: string, body: any, options?: RequestOptionsArgs): Rx.Observable<Response> {
    return this._request(RequestMethod.Post, url, body, options);
  }

  public put(url: string, body: any, options?: RequestOptionsArgs): Rx.Observable<Response> {
    return this._request(RequestMethod.Put, url, body, options);
  }

  public delete(url: string, options?: RequestOptionsArgs): Rx.Observable<Response> {
    return this._request(RequestMethod.Delete, url, null, options);
  }

  public patch(url: string, body: any, options?: RequestOptionsArgs): Rx.Observable<Response> {
    return this._request(RequestMethod.Patch, url, body, options);
  }

  public head(url: string, options?: RequestOptionsArgs): Rx.Observable<Response> {
    return this._request(RequestMethod.Head, url, null, options);
  }

  private _request(method: RequestMethod, url: string, body?: any, options?: RequestOptionsArgs): Rx.Observable<Response> {
    const requestOptions = new RequestOptions((<any>Object).assign({
      method: method,
      url: `${url}`,
      body: body
    }, options));

    if (!requestOptions.headers) {
      requestOptions.headers = new Headers();
    }

    const authHeader = this._buildAuthHeader();
    if (authHeader) {
      requestOptions.headers.set('Authorization', authHeader);
    }

    return Rx.Observable.create((observer) => {
      this.process.next(Action.QueryStart);
      this.http.request(new Request(requestOptions))
        .pipe(
          finalize(() => {
            this.process.next(Action.QueryStop);
          }))
        .subscribe(
          (res) => {
            observer.next(res);
            observer.complete();
          },
          (err) => {
            switch (err.status) {
              case 401:
                // intercept 401
                this.authFailed.next(err);
                observer.error(err);
                break;
              default:
                observer.error(err);
                break;
            }
          });
    });
  }
}
