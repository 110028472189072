﻿import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Waterway } from '../Models/Waterway';
import { WaterwayService } from '../Services/WaterwayService';
import { MilestoneService } from '../Services/MilestoneService';
import { OperationService } from '../Services/OperationService';
import { OperationTypeFilter } from './OperationTypeFilter';
import { SelectedFilterService } from '../Services/SelectedFilterService';
import { SoilInvestigationCampaignService } from '../Services/SoilInvestigationCampaignService';
import { DredgingOperationService } from '../Services/DredgingOperationService';
import { SurveyCampaignService } from '../Services/SurveyCampaignService';
import { NGXLogger } from 'ngx-logger';
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
  selector: 'dashboard',
  templateUrl: 'Dashboard.html',
  providers: [
    WaterwayService,
    MilestoneService,
    OperationService,
    SurveyCampaignService,
    DredgingOperationService,
    SoilInvestigationCampaignService
  ],
  styleUrls: ['Dashboard.scss'],
  animations: [
    trigger('metromapanimation', [
      state('in', style({ marginTop: 0 })),
      state('out', style({ marginTop: '-30vh' })),
      transition('in => out', [
        animate('500ms ease-in')
      ]),
      transition('out => in', [
        animate('500ms ease-out')
      ])
    ]),
    trigger('timelineanimation', [
      state('in', style({ height: '*' })),
      state('out', style({ height: '89vh' })),
      transition('in => out', [
        animate('500ms ease-in')
      ]),
      transition('out => in', [
        animate('500ms ease-out')
      ])
    ]),
  ]
})
export class Dashboard implements OnInit {
  public waterways: Waterway[];
  private filteredWaterways: Waterway[];
  private selectedWaterway: Waterway;
  public state = 'in';
  public icon = 'assets/icons.svg#icn-arrow_up';
  public cd = false;

  @ViewChild(OperationTypeFilter)
  private operationTypeFilter: OperationTypeFilter;

  constructor(private waterwayService: WaterwayService, public selectedFilterService: SelectedFilterService, private logger: NGXLogger) {
    logger.debug('started Dashboard');
  }

  ngOnInit() {
    const me = this;
    this.waterwayService.GetWaterways().subscribe(function (waterways) {
      me.waterways = waterways;
      me.filteredWaterways = waterways;
    });

    if (this.selectedFilterService.SelectedFilter == null || this.selectedFilterService.SelectedFilter == '') {
      this.selectedFilterService.SelectedFilter = 'predict-dredge';
    }
  }

  WaterwaySelected(waterway: Waterway) {
    this.logger.debug(`selected waterway: ${waterway}`);
    this.selectedWaterway = waterway;
  }

  get FilteredWaterways(): Waterway[] {
    return this.filteredWaterways;
  }

  @Input()
  set FilteredWaterways(value: Waterway[]) {
    this.filteredWaterways = value;
  }

  get SelectedWaterway(): Waterway {
    return this.selectedWaterway;
  }

  @Input()
  set SelectedWaterway(value: Waterway) {
    this.selectedWaterway = value;
  }

  public ToggleAnimationState() {
    this.state = (this.state === 'in' ? 'out' : 'in');
    this.icon = (this.state === 'in' ? 'assets/icons.svg#icn-arrow_up' : 'assets/icons.svg#icn-arrow_down');
  }

  public ContainsBaggeren(): boolean {
    return this.operationTypeFilter.ContainsOperationType('baggeren');
  }
}
