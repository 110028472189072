﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PointSet } from '../Models/PointSet';
import { IPointSet } from '../DTO/IPointSet';

@Injectable()
export class PointSetService {

    ConvertJsonToPointSet(jsonPointSet: IPointSet, factor: number): PointSet {
        return new PointSet(jsonPointSet.id, jsonPointSet.datetime && new Date(jsonPointSet.datetime), factor);
    }
}
