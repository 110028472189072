﻿import { PointSet } from "./PointSet";
import { Duration } from "moment";
import { IPointSetHolder } from "./IPointSetHolder";

export class SurveyResult implements IPointSetHolder {
    public PointSet: PointSet;
    public Type: string = "survey";

    constructor(public Id: number) {
    }
}