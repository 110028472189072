/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./Dashboard.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./MetroMap.ngfactory";
import * as i3 from "./MetroMap";
import * as i4 from "ngx-logger";
import * as i5 from "./OperationTypeFilter.ngfactory";
import * as i6 from "./OperationTypeFilter";
import * as i7 from "./WaterwayTimeline.ngfactory";
import * as i8 from "./WaterwayTimeline";
import * as i9 from "../Services/SurveyCampaignService";
import * as i10 from "../Services/DredgingOperationService";
import * as i11 from "../Services/SoilInvestigationCampaignService";
import * as i12 from "../Services/OperationService";
import * as i13 from "../Services/HttpAuthService";
import * as i14 from "../Services/MilestoneService";
import * as i15 from "../Services/WaterwayService";
import * as i16 from "ngx-permissions";
import * as i17 from "./Dashboard";
import * as i18 from "../Services/SelectedFilterService";
var styles_Dashboard = [i0.styles];
var RenderType_Dashboard = i1.ɵcrt({ encapsulation: 0, styles: styles_Dashboard, data: { "animation": [{ type: 7, name: "metromapanimation", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { marginTop: 0 }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { marginTop: "-30vh" }, offset: null }, options: undefined }, { type: 1, expr: "in => out", animation: [{ type: 4, styles: null, timings: "500ms ease-in" }], options: null }, { type: 1, expr: "out => in", animation: [{ type: 4, styles: null, timings: "500ms ease-out" }], options: null }], options: {} }, { type: 7, name: "timelineanimation", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { height: "89vh" }, offset: null }, options: undefined }, { type: 1, expr: "in => out", animation: [{ type: 4, styles: null, timings: "500ms ease-in" }], options: null }, { type: 1, expr: "out => in", animation: [{ type: 4, styles: null, timings: "500ms ease-out" }], options: null }], options: {} }] } });
export { RenderType_Dashboard as RenderType_Dashboard };
export function View_Dashboard_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { operationTypeFilter: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "metromap", [], [[24, "@metromapanimation", 0]], [[null, "waterwaySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("waterwaySelected" === en)) {
        var pd_0 = (_co.WaterwaySelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MetroMap_0, i2.RenderType_MetroMap)), i1.ɵdid(2, 114688, null, 0, i3.MetroMap, [i1.ElementRef, i4.NGXLogger], { Waterways: [0, "Waterways"], SelectedWaterway: [1, "SelectedWaterway"] }, { waterwaySelected: "waterwaySelected" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["class", "btn-icn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ToggleAnimationState() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "operation-type-filter", [["ShowPredict", "true"]], null, [[null, "waterwaysFiltered"], [null, "SelectedFilterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("waterwaysFiltered" === en)) {
        var pd_0 = ((_co.FilteredWaterways = $event) !== false);
        ad = (pd_0 && ad);
    } if (("SelectedFilterChange" === en)) {
        var pd_1 = ((_co.selectedFilterService.SelectedFilter = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_OperationTypeFilter_0, i5.RenderType_OperationTypeFilter)), i1.ɵdid(6, 49152, [[1, 4]], 0, i6.OperationTypeFilter, [], { Waterways: [0, "Waterways"], SelectedFilter: [1, "SelectedFilter"], ShowPredict: [2, "ShowPredict"] }, { waterwaysFiltered: "waterwaysFiltered", SelectedFilterChange: "SelectedFilterChange" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "waterway-timeline", [], [[24, "@timelineanimation", 0]], [[null, "WaterwaySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("WaterwaySelected" === en)) {
        var pd_0 = (_co.WaterwaySelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_WaterwayTimeline_0, i7.RenderType_WaterwayTimeline)), i1.ɵdid(8, 114688, null, 0, i8.WaterwayTimeline, [], { Waterways: [0, "Waterways"], SelectedWaterway: [1, "SelectedWaterway"], ShowPriorityDate: [2, "ShowPriorityDate"] }, { WaterwaySelected: "WaterwaySelected" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.waterways; var currVal_2 = _co.SelectedWaterway; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _co.waterways; var currVal_5 = _co.selectedFilterService.SelectedFilter; var currVal_6 = "true"; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.FilteredWaterways; var currVal_9 = _co.SelectedWaterway; var currVal_10 = _co.ContainsBaggeren(); _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.icon; _ck(_v, 4, 0, currVal_3); var currVal_7 = _co.state; _ck(_v, 7, 0, currVal_7); }); }
export function View_Dashboard_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "dashboard", [], null, null, null, View_Dashboard_0, RenderType_Dashboard)), i1.ɵprd(512, null, i9.SurveyCampaignService, i9.SurveyCampaignService, []), i1.ɵprd(512, null, i10.DredgingOperationService, i10.DredgingOperationService, []), i1.ɵprd(512, null, i11.SoilInvestigationCampaignService, i11.SoilInvestigationCampaignService, []), i1.ɵprd(512, null, i12.OperationService, i12.OperationService, [i13.HttpAuthService, i9.SurveyCampaignService, i10.DredgingOperationService, i11.SoilInvestigationCampaignService, i4.NGXLogger]), i1.ɵprd(512, null, i14.MilestoneService, i14.MilestoneService, [i13.HttpAuthService, i12.OperationService, i4.NGXLogger]), i1.ɵprd(512, null, i15.WaterwayService, i15.WaterwayService, [i13.HttpAuthService, i14.MilestoneService, i12.OperationService, i16.NgxRolesService, i4.NGXLogger]), i1.ɵdid(7, 114688, null, 0, i17.Dashboard, [i15.WaterwayService, i18.SelectedFilterService, i4.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, null); }
var DashboardNgFactory = i1.ɵccf("dashboard", i17.Dashboard, View_Dashboard_Host_0, { FilteredWaterways: "FilteredWaterways", SelectedWaterway: "SelectedWaterway" }, {}, []);
export { DashboardNgFactory as DashboardNgFactory };
