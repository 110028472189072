import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { Headers, Http, ResponseContentType, URLSearchParams } from '@angular/http';
import * as ol from 'openlayers';
import { Waterway } from '../Models/Waterway';
import { ContractualArea } from '../Models/ContractualArea';
import { MapBarHelper } from '../Util/MapBarHelper';
import { ExternalAuthService } from '../Services/ExternalAuthService';
import { first, flatMap, map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { TileEventTracker } from "../Util/TileEventTracker";
import { ConfigService } from "../Services/ConfigService";
var MapView = /** @class */ (function () {
    function MapView(element, http, configService, authService, tileEventTracker, logger) {
        var _this = this;
        this.authService = authService;
        this.tileEventTracker = tileEventTracker;
        this.logger = logger;
        this.loading = true;
        this.fullscreen = new EventEmitter();
        this.element = element;
        this.http = http;
        this.MapBarSvgUrlPairs = MapBarHelper.GetMapBarSvgUrlPairs();
        this.geoserverBaseUrl = configService.getCachedConfig().geoServerUrl;
        this.bearerTokenObservable = this.authService.IdToken.pipe(map(function (idToken) { return "Bearer " + idToken; }));
        this.tileEventTracker.OnLoadStart.subscribe(function () { return _this.loading = true; });
        this.tileEventTracker.OnLoadEnd.subscribe(function () { return _this.loading = false; });
        logger.debug('created mapview');
    }
    MapView.prototype.ngOnInit = function () {
        var _this = this;
        // OpenStreetMaps Layer
        var osmLayer = new ol.layer.Tile({
            source: new ol.source.OSM()
        });
        var firstRun = true;
        // Source retrieving WFS data in GeoJSON format using a HTTP Req
        this.vectorSource = new ol.source.Vector({
            format: new ol.format.GeoJSON(),
            strategy: ol.loadingstrategy.tile(ol.tilegrid.createXYZ({
                maxZoom: 19
            }))
        });
        var params = {
            service: ['WFS'],
            request: ['GetFeature'],
            version: ['1.1.0'],
            outputFormat: ['application/json'],
            typename: ['deme:contractual_area'],
            srsname: ['EPSG:900913']
        };
        var paramsMap = new URLSearchParams();
        paramsMap.paramsMap = new Map(Object.entries(params));
        // load all contractual areas at once
        var url = this.geoserverBaseUrl + "/deme/wfs";
        this.bearerTokenObservable.pipe(first(), flatMap(function (bearer) { return _this.http.get(url, { search: paramsMap, headers: _this.CreateAuthHeaders(bearer) }); }), map(function (data) { return data.json(); }), tap(function (json) {
            var features = new ol.format.GeoJSON().readFeatures(json);
            features.forEach(function (f) {
                if (f.getProperties()['waterway_id'] === _this.selectedWaterway.Id) {
                    f.setStyle(new ol.style.Style({
                        stroke: new ol.style.Stroke({
                            color: 'black'
                        })
                    }));
                }
            });
            _this.vectorSource.addFeatures(features);
            if (firstRun) {
                _this.ZoomToExtent();
                firstRun = false;
            }
        }))
            .subscribe();
        // hacks
        var format = ol.format;
        // Vector layer
        var vectorLayer = new ol.layer.Vector({
            source: this.vectorSource,
            style: function (f) { return new ol.style.Style({
                stroke: new ol.style.Stroke({
                    color: f.getProperties()['waterway_id'] === _this.selectedWaterway.Id ? 'black' : 'grey'
                })
            }); }
        });
        this.pointLevelRasterSource = new ol.source.TileWMS({
            url: this.geoserverBaseUrl + "/wms",
            params: {
                'TILED': true,
                'STYLES': 'deme:raster_difference',
                'INTERPOLATIONS': 'bilinear'
            },
            tileLoadFunction: function (tile, src) {
                var imageTile = tile;
                _this.bearerTokenObservable
                    .pipe(first(), flatMap(function (bearer) { return _this.http.get(src, {
                    headers: _this.CreateAuthHeaders(bearer),
                    responseType: ResponseContentType.Blob
                }); }), tap(function (response) {
                    var data = URL.createObjectURL(response.blob());
                    imageTile.getImage().src = data;
                }))
                    .subscribe();
            },
            serverType: 'geoserver',
            projection: undefined
        });
        var legendUrl = this.geoserverBaseUrl + "/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=deme:point_level_raster&STYLE=deme:raster_difference";
        this.bearerTokenObservable.pipe(flatMap(function (bearer) { return _this.http.get(legendUrl, {
            headers: _this.CreateAuthHeaders(bearer),
            responseType: ResponseContentType.Blob
        }); }), tap(function (response) {
            var data = URL.createObjectURL(response.blob());
            _this.legendUrl = data;
        })).subscribe();
        this.tileEventTracker.RegisterEventsOf(this.pointLevelRasterSource);
        this.UpdatePointLevelSource();
        var plrLayer = new ol.layer.Tile({
            source: this.pointLevelRasterSource
        });
        // Map
        this.map = new ol.Map({
            controls: ol.control.defaults({
                zoom: false,
                attribution: false,
                rotate: false
            }),
            target: this.element.nativeElement.getElementsByClassName('map')[0],
            renderer: 'canvas',
            // order should be osm, soilclass, rasterdata, other polygonlayers according to WNZ-142
            layers: [osmLayer, plrLayer, vectorLayer],
            view: new ol.View({
                center: ol.proj.transform([4.777663, 51.814530], 'EPSG:4326', 'EPSG:3857'),
                maxZoom: 19,
                zoom: 10
            })
        });
    };
    MapView.prototype.CreateAuthHeaders = function (bearerToken) {
        var headers = new Headers();
        headers.append('Authorization', bearerToken);
        return headers;
    };
    MapView.prototype.SetFeatureSelection = function (feature, selected) {
        if (feature !== null && feature !== undefined) {
            if (selected) {
                feature.setStyle(new ol.style.Style({
                    stroke: new ol.style.Stroke({
                        color: 'green',
                        width: 2
                    })
                }));
            }
            else {
                feature.setStyle(null);
            }
        }
    };
    MapView.prototype.FindFeatureById = function (id) {
        for (var _i = 0, _a = this.vectorSource.getFeatures(); _i < _a.length; _i++) {
            var feature = _a[_i];
            var properties = feature.getProperties();
            if (properties['id'] === +id) {
                return feature;
            }
        }
        return null;
    };
    MapView.prototype.ZoomToExtent = function () {
        this.ZoomToPolyExtend('mostDetailed');
    };
    MapView.prototype.ZoomToPolyExtend = function (type) {
        var _this = this;
        if (!this.vectorSource) {
            return;
        }
        var extent = null;
        this.vectorSource.getFeatures()
            .filter(function (f) { return f.getProperties()['waterway_id'] === _this.selectedWaterway.Id; })
            .filter(function (f) { return type === 'waterway' || !_this.selectedContractualArea || f.getProperties()['id'] === _this.selectedContractualArea.Id; })
            .forEach(function (f) {
            if (extent != null) {
                extent = ol.extent.extend(extent, f.getGeometry().getExtent());
            }
            else {
                extent = f.getGeometry().getExtent().map(function (v) { return v; });
            }
            return extent;
        });
        if (extent) {
            this.map.getView().fit(extent, { size: this.map.getSize() });
        }
    };
    MapView.prototype.UpdateMap = function () {
        this.map.updateSize();
    };
    Object.defineProperty(MapView.prototype, "SelectedFeature", {
        get: function () {
            return this.selectedFeature;
        },
        set: function (id) {
            if (!this.vectorSource) {
                return;
            }
            this.SetFeatureSelection(this.FindFeatureById(this.selectedFeature), false);
            this.selectedFeature = id;
            this.SetFeatureSelection(this.FindFeatureById(this.selectedFeature), true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapView.prototype, "SelectedWaterway", {
        get: function () {
            return this.selectedWaterway;
        },
        set: function (value) {
            this.selectedWaterway = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapView.prototype, "SelectedContractualArea", {
        get: function () {
            return this.selectedContractualArea;
        },
        set: function (value) {
            this.selectedContractualArea = value;
            this.ZoomToExtent();
            this.SelectedFeature = this.selectedContractualArea && this.selectedContractualArea.Id.toString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapView.prototype, "SelectedDate", {
        get: function () {
            return this.selectedDate;
        },
        set: function (value) {
            this.selectedDate = value;
            this.UpdatePointLevelSource();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapView.prototype, "RasterFactor", {
        get: function () {
            return this.rasterFactor;
        },
        set: function (value) {
            this.rasterFactor = value;
            this.UpdatePointLevelSource();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapView.prototype, "LegendUrl", {
        get: function () {
            return this.legendUrl;
        },
        enumerable: true,
        configurable: true
    });
    MapView.prototype.UpdatePointLevelSource = function () {
        if (this.pointLevelRasterSource) {
            var rasterFactorPostFix = this.rasterFactor !== 1 && this.rasterFactor != null ? '_x' + this.rasterFactor : '';
            var time = this.selectedDate ? this.selectedDate.toISOString() : null;
            var params = { 'LAYERS': 'deme:point_level_raster' + rasterFactorPostFix, 'TIME': time };
            this.pointLevelRasterSource.updateParams(params);
        }
    };
    return MapView;
}());
export { MapView };
