import { Injectable } from '@angular/core';
import { IDredgingOperation } from '../DTO/IDredgingOperation';
import { DredgingOperation } from '../Models/DredgingOperation';

@Injectable()
export class DredgingOperationService {

    public ConvertJsonToDredgingOperations(iDredgingOperations: IDredgingOperation[]): DredgingOperation[] {
        return iDredgingOperations.map(ido =>
            new DredgingOperation(ido.id, ido.contractor));
    }

    public ConvertDredgingOperationsToIDredgingOperations(dredgingOperations: DredgingOperation[]): IDredgingOperation[] {
        return dredgingOperations.map(dos => {
            const ido: IDredgingOperation = {id: dos.Id, contractor: dos.Contractor };
            return ido;
        });
    }
}
