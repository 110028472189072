import { EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { OperationTypeHelper } from '../Util/OperationTypeHelper';
var OperationTypeFilter = /** @class */ (function () {
    function OperationTypeFilter() {
        this.waterwaysFiltered = new EventEmitter();
        this.SelectedFilterChange = new EventEmitter();
        this.OperationTypeSvgUrlPairs = [{
                OperationType: 'all',
                SvgUrl: 'assets/icons.svg#icn-filter_all'
            }].concat(OperationTypeHelper.GetOperationTypeSvgUrlPairs());
    }
    Object.defineProperty(OperationTypeFilter.prototype, "Waterways", {
        get: function () {
            return this.waterways;
        },
        set: function (value) {
            this.waterways = value;
            this.SelectFilterWaterways(this.selectedFilter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationTypeFilter.prototype, "SelectedFilter", {
        get: function () {
            return this.selectedFilter;
        },
        set: function (value) {
            this.selectedFilter = value;
            this.SelectFilterWaterways(this.selectedFilter);
            this.SelectedFilterChange.emit(this.SelectedFilter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationTypeFilter.prototype, "ShowPredict", {
        get: function () {
            return this.showPredict;
        },
        set: function (value) {
            if (this.showPredict != value) {
                if (value) {
                    this.OperationTypeSvgUrlPairs.splice(1, 0, {
                        OperationType: 'predict-dredge',
                        SvgUrl: 'assets/icons.svg#icn-predictDredge'
                    });
                }
                else {
                    this.OperationTypeSvgUrlPairs.splice(1, 1);
                }
            }
            this.showPredict = value;
        },
        enumerable: true,
        configurable: true
    });
    OperationTypeFilter.prototype.FilterWaterways = function (filter) {
        var waterwaysCopy = this.waterways.map(function (ww) { return ww.Clone(); });
        var now = moment().toDate();
        var milestonePredicate = function (ms) { return ms.GetLastDateForOperation() > now; };
        var compare = function (ww1, ww2) {
            var ms1 = ww1.GetFirstMilestoneMeetingCondition(milestonePredicate);
            var ms2 = ww2.GetFirstMilestoneMeetingCondition(milestonePredicate);
            if (ms1 != null && ms2 != null) {
                return ms1.Datetime.valueOf() - ms2.Datetime.valueOf();
            }
            else {
                var result = (ms1 != null ? -1 : 0) - (ms2 != null ? -1 : 0);
                if (result === 0)
                    result = ww1.Code.localeCompare(ww2.Code);
                return result;
            }
        };
        var waterwaysUnfiltered = waterwaysCopy.map(function (ww) { ww.Milestones = ww.Milestones.filter(function (ms) { return ms.OperationTypeCode === filter; }); return ww; }).sort(compare);
        var waterwaysFiltered = waterwaysUnfiltered.filter(function (ww) { return ww.Milestones.length > 0; });
        return waterwaysFiltered;
    };
    OperationTypeFilter.prototype.FilterWaterwaysOnPredictionDate = function () {
        var waterwaysCopy = this.waterways.map(function (ww) { return ww.Clone(); });
        var now = moment().toDate();
        var compare = function (ww1, ww2) {
            if (ww1.FirstInvalidPointSetDate && ww2.FirstInvalidPointSetDate) {
                return ww1.FirstInvalidPointSetDate.valueOf() - ww2.FirstInvalidPointSetDate.valueOf();
            }
            else {
                var result = (ww1.FirstInvalidPointSetDate != null ? -1 : 0) - (ww2.FirstInvalidPointSetDate != null ? -1 : 0);
                if (result === 0)
                    return ww2.Milestones.length - ww1.Milestones.length;
                return result;
            }
        };
        var waterwaysUnfiltered = waterwaysCopy.map(function (ww) { ww.Milestones = ww.Milestones.filter(function (ms) { return ms.OperationTypeCode === "baggeren"; }); return ww; }).sort(compare);
        var waterwaysFiltered = waterwaysUnfiltered.filter(function (ww) { return ww.FirstInvalidPointSetDate; });
        return waterwaysFiltered;
    };
    OperationTypeFilter.prototype.SelectFilterWaterways = function (filter) {
        if (!filter || !this.waterways)
            this.waterwaysFiltered.emit([]);
        else if (filter === 'all') {
            this.waterwaysFiltered.emit(this.waterways);
        }
        else if (filter === 'predict-dredge') {
            this.waterwaysFiltered.emit(this.FilterWaterwaysOnPredictionDate());
        }
        else {
            this.waterwaysFiltered.emit(this.FilterWaterways(filter));
        }
    };
    OperationTypeFilter.prototype.ContainsOperationType = function (operationType) {
        if (this.selectedFilter === "all")
            return true;
        else if (this.selectedFilter === "predict-dredge")
            return operationType === "baggeren";
        else
            return this.selectedFilter === operationType;
    };
    OperationTypeFilter.prototype.navigateToMilieuPage = function () {
        window.open('https://sharepoint-deme-group.com/WNLZ/_layouts/15/WopiFrame.aspx?sourcedoc=/WNLZ/Gedeelde%20%20documenten/A.3%20Beschrijving%20kwaliteit%20Areaal/A%203.1%20-%20Toestand%20areaal/A3.1.1%20bodemonderzoeken/Overzichtstabel%20KBC/5264-WNZ-TAB-001D-BLJO%20ACTUEEL%20OVERZICHT%20KBC%20-%20do%20no%20(re)move.xlsx&action=default');
    };
    return OperationTypeFilter;
}());
export { OperationTypeFilter };
