﻿import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Waterway } from '../Models/Waterway';
import { WaterwayService } from '../Services/WaterwayService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WaterwayDetailResolve implements Resolve<Waterway | boolean> {
  constructor(private waterwayService: WaterwayService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Waterway | boolean> {
    const id = route.params['id'];
    return this.waterwayService.GetWaterwayById(id)
      .pipe(
        map(ww => {
          if (ww) {
            return ww;
          } else {
            // id not found
            this.router.navigate(['/']);
            return false;
          }
        })
      );
  }
}
