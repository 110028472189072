﻿import { Directive, Input, ElementRef } from '@angular/core';
import { DataGroup } from 'vis';

@Directive({
    selector: '[obp-timeline-group]'
})
export class OBPTimelineGroup {
    @Input('obp-timeline-group')
    public ObpTimelineGroup: DataGroup;

    @Input()
    public OBPTimelineGroupTarget: "timeline" | "group-content";

    public constructor(public element: ElementRef) {

    }
}