﻿import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
import { AdalService } from './AdalService';

@Injectable()
export class AutomaticLoginGuard implements CanActivate {
    constructor(private router: Router, private adalService: AdalService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        let automatic = true;

        if (route.params['automatic'] != null) {
            automatic = route.params['automatic'] === true;
        }

        if (automatic) {
            this.adalService.login();
        }
        return true;
    }
}
