import { ElementRef, EventEmitter } from '@angular/core';
import { Network } from 'vis';
import { Waterway } from '../Models/Waterway';
import { NGXLogger } from 'ngx-logger';
var MetroMap = /** @class */ (function () {
    function MetroMap(element, logger) {
        this.logger = logger;
        this.xScale = 2.5;
        this.yScale = 1;
        this.nodes = [];
        this.edges = [];
        this.waterwaySelected = new EventEmitter();
        this.element = element;
        this.graph = {
            nodes: [{
                    Id: 0,
                    X: 300 * this.xScale,
                    Y: 100 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 1,
                    X: 400 * this.xScale,
                    Y: 100 * this.yScale,
                    Type: 'harbour',
                    Name: 'ZDJ'
                },
                {
                    Id: 2,
                    X: 500 * this.xScale,
                    Y: 100 * this.yScale,
                    Type: 'harbour',
                    Name: 'BKK'
                },
                {
                    Id: 3,
                    X: 600 * this.xScale,
                    Y: 100 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 4,
                    X: 500 * this.xScale,
                    Y: 0 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 5,
                    X: 500 * this.xScale,
                    Y: 200 * this.yScale,
                    Type: 'harbour',
                    Name: 'ReS'
                },
                {
                    Id: 6,
                    X: 400 * this.xScale,
                    Y: 200 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 7,
                    X: 300 * this.xScale,
                    Y: 200 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 8,
                    X: 600 * this.xScale,
                    Y: 200 * this.yScale,
                    Type: 'harbour',
                    Name: 'vhHELs'
                },
                {
                    Id: 9,
                    X: 700 * this.xScale,
                    Y: 200 * this.yScale,
                    Type: 'harbour',
                    Name: 'vhPLNs'
                },
                {
                    Id: 10,
                    X: 800 * this.xScale,
                    Y: 200 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 11,
                    X: 900 * this.xScale,
                    Y: 200 * this.yScale,
                    Type: 'harbour',
                    Name: 'AVL'
                },
                {
                    Id: 12,
                    X: 1000 * this.xScale,
                    Y: 200 * this.yScale,
                    Type: 'harbour',
                    Name: 'vhGOR'
                },
                {
                    Id: 13,
                    X: 1000 * this.xScale,
                    Y: 300 * this.yScale,
                    Type: 'harbour',
                    Name: 'vhSLW'
                },
                {
                    Id: 14,
                    X: 400 * this.xScale,
                    Y: 300 * this.yScale,
                    Type: 'harbour',
                    Name: 'vhGDL'
                },
                {
                    Id: 15,
                    X: 400 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'harbour',
                    Name: 'HDP-b'
                },
                {
                    Id: 16,
                    X: 500 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'harbour',
                    Name: 'HDP-a'
                },
                {
                    Id: 17,
                    X: 300 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 18,
                    X: 200 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'harbour',
                    Name: 'VKAs'
                },
                {
                    Id: 19,
                    X: 100 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'harbour',
                    Name: 'bhSLD'
                },
                {
                    Id: 20,
                    X: 0 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'harbour',
                    Name: 'GRSs'
                },
                {
                    Id: 21,
                    X: 700 * this.xScale,
                    Y: 300 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 22,
                    X: 600 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 23,
                    X: 700 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                },
                {
                    Id: 24,
                    X: 800 * this.xScale,
                    Y: 400 * this.yScale,
                    Type: 'endpoint',
                    Name: undefined
                }],
            edges: [{
                    Id: 0,
                    From: 0,
                    To: 1,
                    Code: 'NMS',
                    Straight: true
                },
                {
                    Id: 1,
                    From: 1,
                    To: 2,
                    Code: 'NMS',
                    Straight: true
                },
                {
                    Id: 2,
                    From: 1,
                    To: 4,
                    Code: 'HYS',
                    Straight: false
                },
                {
                    Id: 3,
                    From: 2,
                    To: 3,
                    Code: 'LEK',
                    Straight: true
                },
                {
                    Id: 4,
                    From: 2,
                    To: 5,
                    Code: 'NOR',
                    Straight: true
                },
                {
                    Id: 5,
                    From: 5,
                    To: 6,
                    Code: 'OMS-B',
                    Straight: true
                },
                {
                    Id: 6,
                    From: 6,
                    To: 7,
                    Code: 'OMS-Z',
                    Straight: true
                },
                {
                    Id: 7,
                    From: 5,
                    To: 8,
                    Code: 'BEM',
                    Straight: true
                },
                {
                    Id: 8,
                    From: 8,
                    To: 9,
                    Code: 'BEM',
                    Straight: true
                },
                {
                    Id: 9,
                    From: 9,
                    To: 10,
                    Code: 'BEM',
                    Straight: true
                },
                {
                    Id: 10,
                    From: 10,
                    To: 11,
                    Code: 'BOM',
                    Straight: true
                },
                {
                    Id: 11,
                    From: 11,
                    To: 12,
                    Code: 'BOM',
                    Straight: true
                },
                {
                    Id: 12,
                    From: 6,
                    To: 14,
                    Code: 'DKL',
                    Straight: true
                },
                {
                    Id: 13,
                    From: 14,
                    To: 17,
                    Code: 'DKL',
                    Straight: false
                },
                {
                    Id: 14,
                    From: 17,
                    To: 18,
                    Code: 'HDP',
                    Straight: true
                },
                {
                    Id: 15,
                    From: 18,
                    To: 19,
                    Code: undefined,
                    Straight: true
                },
                {
                    Id: 16,
                    From: 19,
                    To: 20,
                    Code: undefined,
                    Straight: true
                },
                {
                    Id: 17,
                    From: 10,
                    To: 21,
                    Code: 'NME',
                    Straight: false
                },
                {
                    Id: 18,
                    From: 21,
                    To: 22,
                    Code: 'NME',
                    Straight: false
                },
                {
                    Id: 19,
                    From: 22,
                    To: 23,
                    Code: 'AMR',
                    Straight: true
                },
                {
                    Id: 20,
                    From: 23,
                    To: 24,
                    Code: 'BMS',
                    Straight: true
                }]
        };
        logger.debug('Loaded nodes.');
    }
    MetroMap.prototype.ngOnInit = function () {
        this.SetNodes();
        this.SetEdges();
        this.SetNetwork();
    };
    Object.defineProperty(MetroMap.prototype, "Waterways", {
        get: function () {
            return this.waterways;
        },
        set: function (value) {
            this.waterways = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetroMap.prototype, "SelectedWaterway", {
        get: function () {
            return this.selectedWaterway;
        },
        set: function (value) {
            var waterwayFound = false;
            if (value) {
                var edge = this.graph.edges.filter(function (e) { return e.Code === value.Code; });
                var node = this.graph.nodes.find(function (e) { return e.Name === value.Code; });
                if (edge) {
                    this.network && this.network.selectEdges(edge.map(function (e) { return e.Id.toString(); }));
                    waterwayFound = true;
                }
                else if (node) {
                    this.network && this.network.selectNodes([node.Id.toString()], false);
                    waterwayFound = true;
                }
            }
            if (!waterwayFound) {
                this.logger.debug('could not find waterway');
                if (this.network) {
                    this.network.selectEdges([]);
                    this.network.selectNodes([], false);
                }
            }
            this.selectedWaterway = value;
            this.logger.debug("Selected waterway: " + value);
        },
        enumerable: true,
        configurable: true
    });
    MetroMap.prototype.SetNodes = function () {
        for (var _i = 0, _a = this.graph.nodes; _i < _a.length; _i++) {
            var node = _a[_i];
            this.nodes.push({
                id: node.Id.toString(),
                x: node.X,
                y: node.Y,
                shape: node.Type === 'harbour' ? 'square' : 'dot',
                size: 14,
                fixed: true,
                color: {
                    background: '#969DA1',
                    border: '#FFFFFF',
                    highlight: {
                        background: 'rgba(0, 0, 0, 0.87)',
                        border: '#FFFFFF'
                    },
                    hover: {
                        background: node.Type === 'harbour' ? 'rgba(0, 0, 0, 0.87)' : '#969DA1',
                        border: '#FFFFFF'
                    }
                },
                labelHighlightBold: true,
                borderWidth: 5,
                borderWidthSelected: node.Type === 'harbour' ? 1 : 5,
                label: node.Type === 'harbour' ? node.Name : undefined,
                font: {
                    background: '#FFFFFF',
                    size: 16
                }
            });
        }
    };
    MetroMap.prototype.SetEdges = function () {
        for (var _i = 0, _a = this.graph.edges; _i < _a.length; _i++) {
            var edge = _a[_i];
            this.edges.push({
                id: edge.Id.toString(),
                from: edge.From,
                to: edge.To,
                label: edge.Code,
                width: 8,
                color: {
                    highlight: 'rgba(0, 0, 0, 0.87)',
                    color: '#969DA1',
                    hover: 'rgba(0, 0, 0, 0.87)'
                },
                font: {
                    color: '#FFFFFF',
                    align: 'horizontal',
                    face: 'Segoe UI',
                    size: 16,
                    strokeWidth: 5,
                    strokeColor: '#969DA1'
                },
                labelHighlightBold: false,
                smooth: {
                    enabled: !edge.Straight,
                    type: 'cubicBezier',
                    forceDirection: 'horizontal',
                    roundness: 0.55
                },
                selectionWidth: 6
            });
        }
    };
    MetroMap.prototype.SetNetwork = function () {
        var data = {
            nodes: this.nodes,
            edges: this.edges
        };
        var options = {
            interaction: {
                hover: true,
                hoverConnectedEdges: false,
                selectConnectedEdges: false,
                dragNodes: false,
                dragView: false,
                zoomView: false
            }
        };
        this.network = new Network(this.element.nativeElement, data, options);
        var me = this;
        this.network.on('click', function (params) {
            if (params.edges.length > 0) {
                me.SelectWaterway(me.waterways.find(function (e) { return e.Code === me.FindEdgeById(params.edges[0]).Code; }));
            }
            if (params.nodes.length > 0) {
                me.SelectWaterway(me.waterways.find(function (e) { return e.Code === me.FindNodeById(params.nodes[0]).Name; }));
            }
        });
    };
    MetroMap.prototype.FindEdgeById = function (id) {
        return this.graph.edges.find(function (e) { return e.Id.toString() === id; });
    };
    MetroMap.prototype.FindNodeById = function (id) {
        return this.graph.nodes.find(function (e) { return e.Id.toString() === id; });
    };
    MetroMap.prototype.FindWaterWayById = function (id) {
        for (var _i = 0, _a = this.waterways; _i < _a.length; _i++) {
            var waterway = _a[_i];
            if (waterway.Id === id) {
                return waterway;
            }
        }
    };
    MetroMap.prototype.SelectWaterway = function (waterway) {
        this.selectedWaterway = waterway;
        this.waterwaySelected.emit(waterway);
    };
    return MetroMap;
}());
export { MetroMap };
