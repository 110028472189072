var ContractualAreaPointLevelSet = /** @class */ (function () {
    function ContractualAreaPointLevelSet(ContractualArea, PointLevelSet, VolumeAboveBottomLevel, AreaAboveBottomLevel, BottomLevelExceedance) {
        this.ContractualArea = ContractualArea;
        this.PointLevelSet = PointLevelSet;
        this.VolumeAboveBottomLevel = VolumeAboveBottomLevel;
        this.AreaAboveBottomLevel = AreaAboveBottomLevel;
        this.BottomLevelExceedance = BottomLevelExceedance;
    }
    Object.defineProperty(ContractualAreaPointLevelSet.prototype, "ConformArea", {
        get: function () {
            return this.ContractualArea.TotalArea - this.AreaAboveBottomLevel;
        },
        enumerable: true,
        configurable: true
    });
    return ContractualAreaPointLevelSet;
}());
export { ContractualAreaPointLevelSet };
