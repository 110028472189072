import { HttpClient } from "@angular/common/http";
import { publishReplay, refCount } from "rxjs/operators";
var ConfigService = /** @class */ (function () {
    function ConfigService(http) {
        this.http = http;
        this.baseUrl = ConfigService.toUrlBase(document.getElementsByTagName('base')[0].href);
        this.config$ = this.CreateConfig$();
    }
    Object.defineProperty(ConfigService.prototype, "AdalConfig", {
        get: function () {
            var configDto = this.getCachedConfig();
            return {
                tenant: configDto.tenantId,
                clientId: configDto.clientId,
                redirectUri: window.location.origin + '/login/aad/callback',
                postLogoutRedirectUri: window.location.origin + '/',
                navigateToLoginRequestUrl: false
            };
        },
        enumerable: true,
        configurable: true
    });
    ConfigService.toUrlBase = function (url) {
        // Get base address, without any trailing slashes or paths
        var base = /^(.*?:\/\/)?([\w\-]*\.?)+(\.\w{2,})?(:?\d{1,5})?/.exec(url);
        if (base != null) {
            return base[0];
        }
        else {
            console.error('Could not match address!');
            throw new Error('Could not match address!');
        }
    };
    // Is used by APP_INITIALIZER to initialize the config cache, *before* the rest of the app runs
    ConfigService.prototype.loadCache = function () {
        var _this = this;
        return this.config$
            .toPromise()
            .then(function (c) { return (_this.cachedConfig = c); });
    };
    ConfigService.prototype.getConfig$ = function () {
        return this.config$;
    };
    // Is safe to use through whole app
    ConfigService.prototype.getCachedConfig = function () {
        return this.cachedConfig;
    };
    ConfigService.prototype.CreateConfig$ = function () {
        return this.http.get(this.baseUrl + "/api/config").pipe(publishReplay(1), refCount());
    };
    return ConfigService;
}());
export { ConfigService };
