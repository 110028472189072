﻿import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Waterway } from '../Models/Waterway';
import { ContractualArea } from '../Models/ContractualArea';
import { ContractualAreaPointLevelSet } from '../Models/ContractualAreaPointLevelSet';
import { Moment } from "moment";
import { SelectedTimeService } from "../Services/SelectedTimeService";
import { NGXLogger } from "ngx-logger";
import { Requirement } from "../Models/Requirement";
import * as moment from "moment";
import {Globals} from "../Util/Globals";

@Component({
  selector: 'waterway-info',
  templateUrl: 'WaterwayInfo.html',
  styleUrls: ['WaterwayInfo.scss']
})

export class WaterwayInfo {
  private waterway: Waterway;
  private contractualAreas: ContractualArea[];
  private selectedContractualArea: ContractualArea;
  private arrow: string = "assets/icons.svg#icn-arrow_up";

  private selectedPointSetType: string;
  private selectedContractualAreaPointLevelSet: ContractualAreaPointLevelSet;

  public currentRequirement: Requirement;
  public currentTime: Moment = moment();
  public currentRequirmentStartDate:string = '';
  public currentRequirmentEndDate:string = '';

  @Output()
  public SelectedContractualAreaChange: EventEmitter<ContractualArea> = new EventEmitter<ContractualArea>();

  constructor(private selectedTimeService: SelectedTimeService, private logger: NGXLogger) {
    this.selectedTimeService.SelectedTimeChanged.subscribe(c => this.UpdateRequirement(c));
  }

  get Waterway(): Waterway {
    return this.waterway;
  }

  @Input()
  set Waterway(value: Waterway) {
    this.waterway = value;
  }

  get ContractualAreas(): ContractualArea[] {
    return this.contractualAreas;
  }

  @Input()
  set ContractualAreas(value: ContractualArea[]) {
    this.contractualAreas = value && value.sort((a, b) => a.Urgency - b.Urgency);
  }

  get SelectedContractualArea(): ContractualArea {
    return this.selectedContractualArea;
  }

  @Input()
  set SelectedContractualArea(value: ContractualArea) {
    this.selectedContractualArea = value;
    this.UpdateRequirement(this.currentTime);
  }

  private UpdateRequirement(newDate: Moment){
    this.currentTime = newDate;
    if (this.SelectedContractualArea) {
      this.currentRequirement = this.SelectedContractualArea.GetValidRequirement(newDate);
      if (this.currentRequirement) {
        this.currentRequirmentStartDate = this.GetValidStartDateString(this.currentRequirement);
        this.currentRequirmentEndDate = this.GetValidEndDateString(this.currentRequirement);
      }
    }
  }

  get SelectedPointSetType(): string {
    return this.selectedPointSetType;
  }

  @Input()
  set SelectedPointSetType(value: string) {
    this.selectedPointSetType = value;
  }

  get SelectedContractualAreaPointLevelSet(): ContractualAreaPointLevelSet {
    return !(this.SelectedPointSetType == 'prediction' && !this.selectedContractualArea.UseAnalysis) ? this.selectedContractualAreaPointLevelSet : null;
  }

  @Input()
  set SelectedContractualAreaPointLevelSet(value: ContractualAreaPointLevelSet) {
    this.selectedContractualAreaPointLevelSet = value;
  }

  private OnSelectedContractualAreaChanged(value: ContractualArea) {
    this.SelectedContractualArea = value;
    this.SelectedContractualAreaChange.emit(this.SelectedContractualArea);
  }

  private GetCurrentDate(): Date {
    return new Date();
  }

  private GetValidStartDateString(req: Requirement): string{
    if (!req.ValidRange.start.isSame(Globals.minTime, 'D')) {
      return req.ValidRange.start.format('DD-MM-YYYY');
    } else {
      return '-';
    }
  }

  private GetValidEndDateString(req: Requirement): string{
    if (!req.ValidRange.end.isSame(Globals.maxTime, 'D')) {
      return req.ValidRange.end.format('DD-MM-YYYY');
    } else {
      return '-';
    }
  }


  private Home() {

  }
}
